<template>
  <div v-if="isMobile">
    <MobileMainPage :isLandscape="isLandscape"/>
  </div>  
  <div v-else>
    <DesktopMainPage />
  </div>
</template>

<script>
import DesktopMainPage from './DesktopHome.vue';
import MobileMainPage from './MobileHome.vue';
import MobileMixin from '@/mixins/MobileMixin'

export default {
  name: 'FullScreenHome',
  components: {
    DesktopMainPage,
    MobileMainPage
  },
  mixins: [MobileMixin],
};

</script>
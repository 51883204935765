<template>
  <g>
    <path
      d="M22.4001 19.2L16.0001 12.8L9.6001 19.2"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUp'
})
</script>

<template>
  <g>
    <path d="M14.1666 15.3229H1.83337C0.822021 15.3229 0 14.501 0 13.4896V2.48962C0 1.47827 0.822021 0.65625 1.83337 0.65625H14.1666C15.178 0.65625 16 1.47827 16 2.48962V13.4896C16 14.501 15.178 15.3229 14.1666 15.3229ZM1.83337 1.65625C1.37402 1.65625 1 2.03027 1 2.48962V13.4896C1 13.949 1.37402 14.3229 1.83337 14.3229H14.1666C14.626 14.3229 15 13.949 15 13.4896V2.48962C15 2.03027 14.626 1.65625 14.1666 1.65625H1.83337Z" fill="strokeCurrent"/>
    <path d="M15.5 5.65625H0.5C0.223999 5.65625 0 5.43225 0 5.15625C0 4.88025 0.223999 4.65625 0.5 4.65625H15.5C15.776 4.65625 16 4.88025 16 5.15625C16 5.43225 15.776 5.65625 15.5 5.65625Z" fill="strokeCurrent"/>
    <path d="M15.5 8.98926H0.5C0.223999 8.98926 0 8.76526 0 8.48926C0 8.21326 0.223999 7.98926 0.5 7.98926H15.5C15.776 7.98926 16 8.21326 16 8.48926C16 8.76526 15.776 8.98926 15.5 8.98926Z" fill="strokeCurrent"/>
    <path d="M15.5 12.3232H0.5C0.223999 12.3232 0 12.0992 0 11.8232C0 11.5472 0.223999 11.3232 0.5 11.3232H15.5C15.776 11.3232 16 11.5472 16 11.8232C16 12.0992 15.776 12.3232 15.5 12.3232Z" fill="strokeCurrent"/>
    <path d="M4.16699 15.3229C3.89099 15.3229 3.66699 15.0989 3.66699 14.8229V5.15625C3.66699 4.88025 3.89099 4.65625 4.16699 4.65625C4.44299 4.65625 4.66699 4.88025 4.66699 5.15625V14.8229C4.66699 15.0989 4.44299 15.3229 4.16699 15.3229Z" fill="strokeCurrent"/>
    <path d="M8 15.3229C7.724 15.3229 7.5 15.0989 7.5 14.8229V5.15625C7.5 4.88025 7.724 4.65625 8 4.65625C8.276 4.65625 8.5 4.88025 8.5 5.15625V14.8229C8.5 15.0989 8.276 15.3229 8 15.3229Z" fill="strokeCurrent"/>
    <path d="M11.833 15.3229C11.557 15.3229 11.333 15.0989 11.333 14.8229V5.15625C11.333 4.88025 11.557 4.65625 11.833 4.65625C12.109 4.65625 12.333 4.88025 12.333 5.15625V14.8229C12.333 15.0989 12.109 15.3229 11.833 15.3229Z" fill="strokeCurrent"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconTable'
})
</script>

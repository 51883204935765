<template>
  <g>
    <path
      d="M12.8 22.4L19.2 16L12.8 9.60001"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRight'
})
</script>

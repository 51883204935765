<template>
  <g style="stroke-width: 0.1">
    <path
      d="M12.2668 10.2989C12.1272 10.2989 11.9875 10.2458 11.8814 10.1388C11.6683 9.92578 11.6683 9.58035 11.8814 9.36728L13.8195 7.42915C14.5228 6.73242 14.9098 5.80145 14.9098 4.80509C14.9098 2.7571 13.2436 1.09091 11.1956 1.09091C10.1992 1.09091 9.26827 1.4771 8.573 2.17889L6.63341 4.11861C6.42034 4.33168 6.07491 4.33168 5.86171 4.11861C5.64864 3.90541 5.64864 3.55997 5.86171 3.3469L7.79996 1.40878C8.69884 0.500311 9.90614 0 11.1948 0C13.8443 0 15.9999 2.15558 15.9999 4.80509C15.9999 6.09455 15.4988 7.30185 14.589 8.20286L12.6523 10.1388C12.5454 10.2458 12.4065 10.2989 12.2668 10.2989Z"
      fill="currentColor"
    />
    <path
      d="M4.80509 15.9998C2.15558 15.9998 0 13.8442 0 11.1947C0 9.90527 0.50111 8.69811 1.41091 7.79696L3.3477 5.86097C3.56077 5.6479 3.90621 5.6479 4.11927 5.86097C4.33234 6.07404 4.33234 6.41948 4.11927 6.63255L2.18115 8.5708C1.4771 9.26673 1.09091 10.1977 1.09091 11.1947C1.09091 13.2427 2.7571 14.9089 4.80509 14.9089C5.80145 14.9089 6.73242 14.5227 7.42769 13.8209L9.36728 11.8813C9.58034 11.6683 9.92578 11.6683 10.1388 11.8813C10.352 12.0944 10.352 12.4399 10.1388 12.6529L8.20073 14.5912C7.30105 15.4995 6.09388 15.9998 4.80509 15.9998Z"
      fill="currentColor"
    />
    <path
      d="M5.09084 11.4546C4.95128 11.4546 4.81159 11.4015 4.70546 11.2947C4.49239 11.0815 4.49239 10.736 4.70546 10.5229L10.5235 4.70472C10.7368 4.49165 11.0822 4.49165 11.2953 4.70472C11.5083 4.91792 11.5083 5.26336 11.2953 5.47643L5.47703 11.2947C5.3701 11.4015 5.23054 11.4546 5.09084 11.4546Z"
      fill="currentColor"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLink2'
})
</script>

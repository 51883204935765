<template>
  <div class="symbol-list" v-click-outside="closeMenu">
    <button class="btn truncate" @click.prevent="toggleMenu" @mouseenter="toggleMenu">
      <div class="dropdown-toggle pair-selector flex flex-row" type="button">
        <p>{{ selectedSymbol }}</p>
        <legacy-icon name="down2" size="20px" viewbox="16" class="text-gray-400" />
      </div>

    </button>

    <transition name="menu">
      <div v-if="showPopupMenu" class="symbol-list-options" @mouseleave="closeMenu">
        <div class="symbol-list-popup">
          <search-bar v-model="searchText" placeholder="Search..."></search-bar>
        </div>
        <div v-for="symbol in filteredList" :key="symbol.code" class="symbol-list-item"
          @click.prevent.stop="onSelect(symbol.label)">
          {{ symbol.label }}
        </div>
      </div>
    </transition>

  </div>
</template>

  
<script>
import axios from 'axios';
//import Avatar from 'vue-avatar'
import SearchBar from './Searchbar';
import vClickOutside from 'v-click-outside'
//import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'SymbolSelector',
  components: {
    //Avatar,
    SearchBar
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
  },
  data() {
    return {
      showPopupMenu: false,
      searchText: '',
      selectedSymbol: 'BTCUSD', // Default selected symbol
      symbols: [
        {
          code: 'btc',
          label: 'BTCUSD',
        },
        {
          code: 'eth',
          label: 'ETHUSD',
        },
        {
          code: 'ada',
          label: 'ADAUSD',
        },
      ]
    };
  },
  computed: {
    filteredList() {
      return this.symbols.filter(symbol => {
        return symbol.label.toLowerCase().includes(this.searchText.toLowerCase())
      })
    },
    selectedSymbolInStore() {
      return this.$store.state.chart.selectedSymbol
    },
  },
  methods: {
    toggleMenu() {
      this.showPopupMenu = !this.showPopupMenu;
    },
    closeMenu(event) {
      console.log('closeMenu', event);
      this.showPopupMenu = false;
    },
    async onSelect(label) {
      this.selectedSymbol = label;
      console.log(label);

      this.showPopupMenu = false;
      localStorage.setItem('selectedSymbol', this.selectedSymbol);
      await this.$store.dispatch('summary/updateSummary', this.selectedSymbol);
      await this.$store.dispatch('chart/updateChartUponSymbolChange', this.selectedSymbol);

      this.$emit('symbolChanged', this.selectedSymbol);
    }
  },
  async created() {
    let localSymbol = localStorage.getItem('selectedSymbol')
    if (localSymbol) {
      this.selectedSymbol = localSymbol;
    }
    await this.$store.dispatch('summary/updateSummary', this.selectedSymbol);
    await this.$store.dispatch('chart/updateChartUponSymbolChange', this.selectedSymbol);

    axios.get('symbols').then(res => {
      console.log(res.data);
      const symbolObjects = res.data.map((symbol) => {
        return { label: symbol };
      });
      this.symbols = symbolObjects
    });
  },
  watch: {
    selectedSymbolInStore: function (newSymbol, oldSymbol) {
      console.log('selectedSymbolInStore', newSymbol, oldSymbol);
      if (newSymbol === oldSymbol) {
        return;
      }
      this.selectedSymbol = newSymbol;
    }
  }
};
</script>
  
<style lang="postcss" scoped>
.dropdown-toggle.opened::after {
  transform: rotate(180deg);
}

.pair-selector {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  color: #020202;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.symbol-list {
  position: relative;
}

.symbol-list .btn {
  background: transparent;
  box-shadow: none;
}

.symbol-list-avatar {
  @apply flex flex-row items-center;
  @apply truncate;

  &:hover {
    @apply bg-primary-default;

    background: inherit;
  }
}

.symbol-list-options {

  @apply py-2;
  @apply bg-white-default;

  @apply rounded;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 10%;
  width: 140px;
  height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);



  .symbol-list-options-lists {
    @apply px-2 pb-2;
    max-height: 70vh;
  }
}

.symbol-list-option {
  @apply flex flex-row justify-between items-center;

  @apply pb-3 pr-2 pl-4 pt-2;

  @apply cursor-pointer;

  .symbol-list-option-icon {
    svg {
      display: none;
    }
  }

  &.is-active {
    .symbol-list-option-icon {
      background-color: #444754;
      border-color: #444754;

      svg {
        display: inherit;
      }
    }
  }

  &:hover {
    background: #F6F6F6;
    border-radius: 3px;
  }
}

.symbol-list-option-content {
  @apply flex flex-row flex-1 items-center;

  @apply truncate;
}

.symbol-list-option-logo {
  @apply flex flex-none;
}

.symbol-list-option-label {
  @apply flex flex-col;

  @apply ml-2;

  @apply truncate;

  font-size: 16px;
  line-height: 19px;

  .members-count {
    @apply mt-1;

    font-size: 12px;
    line-height: 14px;
    color: #444754;
  }
}

.symbol-list-option-icon {
  @apply flex justify-center items-center;

  @apply border rounded-full border-gray-100 bg-white-default;
  width: 20px;
  height: 20px;
}

.symbol-list-popup {
  @apply flex flex-row items-center;

  @apply w-full px-4 py-2 my-2;

  @apply outline-none;

  color: #2C2B35;

  &:hover {
    @apply text-primary-default;

    background: inherit;

    .icon {
      @apply text-primary-default;
    }
  }

  .icon {
    @apply text-gray-400 mr-2;
  }
}

.symbol-list-item {
  @apply flex flex-col;

  @apply w-full px-6 py-1 my-1;

  @apply outline-none;

  color: #2C2B35;

  &:hover {
    @apply text-primary-default;

    background: inherit;

    .icon {
      @apply text-primary-default;
    }
  }

  .icon {
    @apply text-gray-400 mr-2;
  }
}

.symbol-list-logout {
  @apply flex flex-row items-center;

  @apply w-full px-4 py-2 my-2;

  @apply outline-none;

  color: #2C2B35;

  &:hover {
    @apply text-primary-default;

    background: inherit;

    .icon {
      @apply text-primary-default;
    }
  }

  .icon {
    @apply text-gray-400 mr-2;
  }
}


.divider {
  @apply border-b border-gray-100;
}

button {
  @apply flex items-center;

  border: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: #F8F9FD;
  }

  .SelectSpace-header {
    @apply flex items-center;

    padding-left: 8px;
    transition: 300ms;

    .title {
      margin-left: 8px;
    }
  }

}
</style>
  
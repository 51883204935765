<template>
  <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24L24 12L36 24" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 36L24 24L36 36" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconDoubleUp'
})
</script>

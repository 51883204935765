<template>
  <g>
    <path
      d="M28.8001 28.8L23.0001 23M26.1334 15.4667C26.1334 21.3577 21.3577 26.1333 15.4667 26.1333C9.57567 26.1333 4.80005 21.3577 4.80005 15.4667C4.80005 9.57564 9.57567 4.80002 15.4667 4.80002C21.3577 4.80002 26.1334 9.57564 26.1334 15.4667Z"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconSearch'
})
</script>

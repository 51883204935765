<template>
  <g>
    <path d="M10.0801 11.2H45.9201C48.3841 11.2 50.4001 13.216 50.4001 15.68V42.5599C50.4001 45.0239 48.3841 47.0399 45.9201 47.0399H10.0801C7.6161 47.0399 5.6001 45.0239 5.6001 42.5599V15.68C5.6001 13.216 7.6161 11.2 10.0801 11.2Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.4001 15.6799L28.0001 31.3599L5.6001 15.6799" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconEmailSuccess'
})
</script>

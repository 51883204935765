<template>
  <g>
    <path d="M6.00033 9.33334L2.66699 6L6.00033 2.66667" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3337 13.3333V8.66667C13.3337 7.95942 13.0527 7.28115 12.5526 6.78105C12.0525 6.28095 11.3742 6 10.667 6H2.66699" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRestore'
})
</script>

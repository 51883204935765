// import 'bootstrap/dist/css/bootstrap.css';
// import 'tippy.js/themes/light.css';
// import 'tippy.js/themes/light-border.css';
//import 'tippy.js/themes/google.css';
// import 'tippy.js/themes/translucent.css';
//import 'vue-toastification/dist/index.css';
import './assets/css/index.css';

// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
import { ColorIcon, MonoIcon } from '@/components/icon';
import LegacyIcon from '@/components/legacy/icon/Icon.vue';
import Portal from 'vue2-portal';

import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import axios from 'axios'

import Vuelidate from "vuelidate"
import vClickOutside from 'v-click-outside'
import VueGtag from 'vue-gtag'

// import store from './store'
// import info from './plugins/info'
// import ticker from './plugins/ticker'
// import arithmetic from './plugins/arithmetic'
// import visibility from './plugins/visibility'
import i18n from './i18n'
import store from './store'
import VueMeta from 'vue-meta';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;  // the FastAPI backend

let isProduct = true

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyCxdZevC7K-KRKLrk72FicCbQeZ4GtKU0Y",
  authDomain: "trim-attic-281018.firebaseapp.com",
  projectId: "trim-attic-281018",
  storageBucket: "trim-attic-281018.appspot.com",
  messagingSenderId: "363055007866",
  appId: "1:363055007866:web:9d4eb7ed72f45ef66913c3",
  measurementId: "G-QQB7BM8JNP"
};

if (isProduct) {
  firebaseConfig = {
    apiKey: "AIzaSyCfRQEOwZQKE-kROnbpm8pSf2kb_bg0dWc",
    authDomain: "firm-source-404402.firebaseapp.com",
    projectId: "firm-source-404402",
    storageBucket: "firm-source-404402.appspot.com",
    messagingSenderId: "731488315424",
    appId: "1:731488315424:web:b46087a0ebc6fc9b4bb445",
    measurementId: "G-W0NC5NZ9PF"
  };
}

// Initialize Firebase
initializeApp(firebaseConfig);

Vue.use(VueGtag, {
  config: {
      id: 'G-W0NC5NZ9PF',
      params: {
          send_page_view: false
      }
  }
}, router);

Vue.config.productionTip = false;
Vue.config.devtools = true;
// Vue.use(arithmetic)
// Vue.use(info)
// Vue.use(ticker)
// Vue.use(visibility)
Vue.use(Vuelidate);
Vue.use(Portal);
Vue.use(VueMeta);
Vue.directive('click-outside', vClickOutside)
Vue.component('legacy-icon', LegacyIcon);
Vue.component('color-icon', ColorIcon);
Vue.component('mono-icon', MonoIcon);
const app = new Vue({
  router,
  i18n,
  store,
  axios,
  render: h => h(App)
})

// const firebase_app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebase_app);
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    console.log('User is signed in: ', uid);

    const isNewUser = user.metadata.createdAt === user.metadata.lastLoginAt ? true : false;
    store.dispatch('user/logIn', {"user": user, "isNewUser": isNewUser});
    // ...
  } else {
    // User is signed out
    store.dispatch('user/logOut', user);
    router.push('/');
  }

  app.$mount('#app')
});


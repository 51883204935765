<template>
  <g>
    <path
      d="M28.8002 8.95999C28.8002 7.55199 27.6482 6.39999 26.2402 6.39999H5.7602C4.3522 6.39999 3.2002 7.55199 3.2002 8.95999M28.8002 8.95999V24.32C28.8002 25.728 27.6482 26.88 26.2402 26.88H5.7602C4.3522 26.88 3.2002 25.728 3.2002 24.32V8.95999M28.8002 8.95999L16.0002 17.92L3.2002 8.95999"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconEmail'
})
</script>

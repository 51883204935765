<template>
  <g>
    <path d="M11.2311 2.88812C11.3857 2.73349 11.5693 2.61083 11.7713 2.52715C11.9734 2.44346 12.1899 2.40039 12.4086 2.40039C12.6273 2.40039 12.8438 2.44346 13.0459 2.52715C13.2479 2.61083 13.4315 2.73349 13.5861 2.88812C13.7407 3.04276 13.8634 3.22633 13.9471 3.42836C14.0308 3.6304 14.0738 3.84694 14.0738 4.06562C14.0738 4.2843 14.0308 4.50084 13.9471 4.70287C13.8634 4.90491 13.7407 5.08848 13.5861 5.24311L5.63801 13.1912L2.3999 14.0743L3.28302 10.8362L11.2311 2.88812Z" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLinkEdit'
})
</script>

<template>
    <div >
        <!--
        <transition name="small-menu">
            <div v-if="modalVisible"  v-click-outside="closeMenu">
                <div class="svg-container pop-up-menu" @click.prevent.stop="clickHome">
                <svg width="32" height="32" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlns:xlink="http://www.w3.org/1999/xlink" 
                    viewBox="0 0 32 32">
                    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="3,17 16,4 29,17 "/>
                    <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="6,14 6,27 13,27 13,17 19,17 19,27 26,27 
                        26,14 "/>
                </svg>
                </div>
            </div>
        </transition>

        <div class="svg-container svg1-margin" @click.prevent.stop="toggleMenu">
            <svg width="32" height="32" 
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                <g>
                    <rect width="4" height="4" x="10" y="3" rx="2"/>
                    <rect width="4" height="4" x="10" y="10" rx="2"/>
                    <rect width="4" height="4" x="10" y="17" rx="2"/>
                </g>
            </svg>
        </div>
        -->

        <div class="svg-container" @click.prevent.stop="clickHome">
            <svg width="32" height="32" 
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                viewBox="0 0 32 32">
                <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="3,17 16,4 29,17 "/>
                <polyline fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" points="6,14 6,27 13,27 13,17 19,17 19,27 26,27 
                    26,14 "/>
            </svg>
        </div>
        <div v-if="isMobile" class="svg-container mt-2" @mousedown.prevent.stop="openPanel" @touchstart.prevent.stop="openPanel">
            <svg width="28" height="28"
                viewBox="0 0 24 24" fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M21,17 C21.5522847,17 22,17.4477153 22,18 C22,18.5522847 21.5522847,19 21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 L21,17 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L21,11 Z M21,5 C21.5522847,5 22,5.44771525 22,6 C22,6.55228475 21.5522847,7 21,7 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L21,5 Z"/>
            </svg>
        </div>
        <div class="svg-container mt-2" @mousedown.prevent.stop="decreaseCount" @touchstart.prevent.stop="decreaseCount">
            <svg width="32" height="32"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path 
                fill-rule="evenodd" 
                d="M10.1025513,7.22165154 C13.549533,10.6902823 15.8466074,12.9862104 16.9937744,14.109436 C17.1906416,14.2940129 17.5317688,14.6698914 18.0392151,14.1790161 C18.3775126,13.851766 18.3644409,13.4939982 18,13.1057129 L10.556488,5.67610168 C10.432251,5.55870056 10.2805653,5.5 10.1014311,5.5 C9.92229687,5.5 9.76473264,5.55870056 9.6287384,5.67610168 L2.17555237,13.3108978 C1.93475791,13.6991321 1.94470649,14.025777 2.20539811,14.2908324 C2.46608974,14.5558878 2.78324382,14.5701396 3.15686035,14.3335876 L10.1025513,7.22165154 Z"/>
            </svg>
        </div>
        <div class="svg-container mt-2" @mousedown.prevent.stop="increaseCount" @touchstart.prevent.stop="increaseCount">
            <svg width="32" height="32" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg">
                <path 
                d="M10.1025513,12.7783485 L16.8106554,6.0794438 C17.0871744,5.80330401 17.5303978,5.80851813 17.8006227,6.09108986 C18.0708475,6.37366159 18.0657451,6.82658676 17.7892261,7.10272655 L10.5858152,14.2962587 C10.3114043,14.5702933 9.87226896,14.5675493 9.60115804,14.2901058 L2.2046872,6.72087106 C1.93149355,6.44129625 1.93181183,5.98834118 2.20539811,5.7091676 C2.47898439,5.42999401 2.92223711,5.43031926 3.19543076,5.70989407 L10.1025513,12.7783485 Z"/>
            </svg>
        </div>
    </div>

</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
    name: 'FullScreenNav',
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: ['strategyType', 'strategyId'],
    data() {
        return {
            modalVisible: false,
            clickCount: 0,
        };
    },
    computed: {
        isMobile() {
            return this.$store.state.appinfo.isMobile
        },
        chartSelectedSymbol() {
            return this.$store.state.chart.selectedSymbol
        },
        chartSelectedInterval() {
            return this.$store.state.chart.selectedInterval
        },
        queryStrategyCount() {
            return this.getQueryStrategyCount();
        }
    },
    methods: {
        toggleMenu() {
            this.modalVisible = !this.modalVisible;
        },
        closeMenu(event) {
            console.log('closeMenu', event);
            this.modalVisible = false;
            this.$emit('closeMenu');
        },
        clickHome() {
            this.modalVisible = false;
            this.$store.commit('appinfo/setFullScreenMode', false);
        },
        openPanel() {
            this.modalVisible = false;
            this.$emit('openPanel');
        },
        async updateChartWithNewStrategy(strategy) {
            if (this.chartSelectedSymbol !== strategy.symbol) {
                await this.$store.dispatch('summary/updateSummary', strategy.symbol);
                await this.$store.dispatch('chart/updateChartUponSymbolChange', strategy.symbol);
            }
            
            if (this.chartSelectedInterval !== strategy.interval) {
                this.$store.commit('chart/updateSelectedInterval', strategy.interval);
            }
            
            await this.$store.dispatch('chart/setActiveStrategy', strategy);
            this.$store.commit('chart/clearSafeLimiter');
        },
        async updateChartWithNewOpenposSafeguarder(openPosSafeGuard){

            if (this.chartSelectedSymbol !== openPosSafeGuard.symbol) {
                await this.$store.dispatch('summary/updateSummary', openPosSafeGuard.symbol);
                await this.$store.dispatch('chart/updateChartUponSymbolChange', openPosSafeGuard.symbol);
            }
            if (this.chartSelectedInterval !== openPosSafeGuard.interval) {
                this.$store.commit('chart/updateSelectedInterval', openPosSafeGuard.interval);
            }
            await this.$store.dispatch('chart/updateSafeLimiter', {openpos: openPosSafeGuard.openpos, useCachedData: true});
            //this.$store.commit('chart/clearStrategyResults');
            await this.$store.dispatch('chart/setActiveStrategy', null);
        },
        async updateChartUponCountChange() {
            let user = this.$store.state.user;
            if (user.user === null){
                return;
            }

            let registeredEntryStrategies = user.registeredEntryStrategies;
            let registeredOrderStrategies = user.registeredOrderStrategies;
            let registeredOpenposSafeGuards = user.registeredOpenposSafeGuards;

            let nEntryStrategies = registeredEntryStrategies.length;
            let nOrderStrategies = registeredOrderStrategies.length;
            let nOpenposSafeGuards = registeredOpenposSafeGuards.length;

            if (this.clickCount === nEntryStrategies + nOrderStrategies + nOpenposSafeGuards){
                this.clickCount = 0;
            } 
            if (this.clickCount === -1){
                this.clickCount = nEntryStrategies + nOrderStrategies + nOpenposSafeGuards - 1;
            }

            if (this.clickCount < nEntryStrategies){
                await this.updateChartWithNewStrategy(registeredEntryStrategies[this.clickCount])
            } else if (this.clickCount < nEntryStrategies + nOrderStrategies){
                await this.updateChartWithNewStrategy(registeredOrderStrategies[this.clickCount - nEntryStrategies])
            } else if (this.clickCount < nEntryStrategies + nOrderStrategies + nOpenposSafeGuards){
                await this.updateChartWithNewOpenposSafeguarder(
                    registeredOpenposSafeGuards[this.clickCount - nEntryStrategies - nOrderStrategies])
            } else {
                this.clickCount = 0;
            }
        },
        getQueryStrategyCount() {
            let user = this.$store.state.user;
            if (user.user === null){
                return -1;
            }

            //let query = this.$route.query; // {strategyType: 'st_entry', strategyId: 'xxx'}
            let query = this.$store.state.chart.queryStrategy; // {strategyType: 'st_entry', strategyId: 'xxx'}
            if (query === null){
                return -1;
            }

            //let strategy = null;
            let count = -1;

            let registeredEntryStrategies = user.registeredEntryStrategies;
            let registeredOrderStrategies = user.registeredOrderStrategies;
            let registeredOpenposSafeGuards = user.registeredOpenposSafeGuards;

            let nEntryStrategies = registeredEntryStrategies.length;
            let nOrderStrategies = registeredOrderStrategies.length;
            let nOpenposSafeGuards = registeredOpenposSafeGuards.length;

            if (query.strategyType === 'st_entry'){
                for (let i = 0; i < nEntryStrategies; i++){
                    if (registeredEntryStrategies[i].id === query.strategyId){
                        //strategy = registeredEntryStrategies[i];
                        count = i;
                        break;
                    }
                }   
            } else if (query.strategyType === 'st_order'){
                for (let i = 0; i < nOrderStrategies; i++){
                    if (registeredOrderStrategies[i].id === query.strategyId){
                        //strategy = registeredOrderStrategies[i];
                        count = i + nEntryStrategies;
                        break;
                    }
                }
            } else if (query.strategyType === 'openpos_safeguard'){
                for (let i = 0; i < nOpenposSafeGuards; i++){
                    if (registeredOpenposSafeGuards[i].id === query.strategyId){
                        //strategy = registeredOpenposSafeGuards[i];
                        count = i + nEntryStrategies + nOrderStrategies;
                        break;
                    }
                }
            } else {
                console.log('unknown strategyType', query.strategyType);
            }

            return count;
        },
        async increaseCount() {
            this.clickCount += 1;
            await this.updateChartUponCountChange();
        },
        async decreaseCount() {
            this.clickCount -= 1;
            await this.updateChartUponCountChange();
        },
    },
    watch: {
        async queryStrategyCount(newVal, oldVal) {
            // Only update chart when queryStrategyCount changes from -1 to a valid number
            if (newVal !== -1 && oldVal === -1){
                this.clickCount = newVal;
                await this.updateChartUponCountChange();
            }
        }
    },
    async created() {
        await this.updateChartUponCountChange();
    },
}

</script>

<style lang="postcss" scoped>
    .pop-up-menu {
        @apply absolute;
        @apply my-2;
        @apply bg-white-default;
    
        @apply rounded;
        border-radius: 50%;
        aspect-ratio: 1;

        box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
    }
    .svg1-margin {
        margin-left: -2px;
    }
    .svg-container {
        position: relative;  
        z-index: 1000;
        justify-content: center;
        align-items: center;
        svg {
            g {
                fill: rgba(theme('colors.success.default'), 0.5);
            }
            path {
                fill: rgba(theme('colors.success.default'), 0.5);
                stroke: rgba(theme('colors.success.default'), 0.5);
            }
            polyline {
                stroke: rgba(theme('colors.success.default'), 0.5);
            }
        }
    }
    .svg-container:hover svg {

        g {
            fill: rgba(theme('colors.success.default'));
            stroke: rgba(theme('colors.success.default'));
        }
        path {
            fill: rgba(theme('colors.success.default'));
            stroke: rgba(theme('colors.success.default'));
        }
        polyline {
            fill: rgba(theme('colors.success.default'));
            stroke: rgba(theme('colors.success.default'));
        }
    }

</style>
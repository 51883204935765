<template>
  <g>
    <path
      d="M28.7999 28.8V25.7882C28.7999 24.1907 28.1257 22.6586 26.9254 21.529C25.7252 20.3993 24.0973 19.7647 22.4 19.7647H9.59995C7.90257 19.7647 6.2747 20.3993 5.07447 21.529C3.87423 22.6586 3.19995 24.1907 3.19995 25.7882V28.8M21.5999 9.22354C21.5999 12.5502 18.9136 15.2471 15.5999 15.2471C12.2862 15.2471 9.59986 12.5502 9.59986 9.22354C9.59986 5.89684 12.2862 3.20001 15.5999 3.20001C18.9136 3.20001 21.5999 5.89684 21.5999 9.22354Z"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUser'
})
</script>

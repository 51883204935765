<template>
  <g>
    <path d="M7.9602 1.6001H4.4802C4.14072 1.6001 3.81515 1.73495 3.5751 1.975C3.33505 2.21505 3.2002 2.54062 3.2002 2.8801V13.1201C3.2002 13.4596 3.33505 13.7851 3.5751 14.0252C3.81515 14.2652 4.14072 14.4001 4.4802 14.4001H12.1602C12.4997 14.4001 12.8252 14.2652 13.0653 14.0252C13.3053 13.7851 13.4402 13.4596 13.4402 13.1201V7.0801L7.9602 1.6001Z" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.95996 1.6001V7.0801H12.44" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFileEmpty'
})
</script>

<template>
  <g>
    <circle cx="9.5" cy="5.5" r="1.5" fill="currentFill" stroke="none"/>
    <circle cx="9.5" cy="10.5" r="1.5" fill="currentFill" stroke="none"/>
    <circle cx="9.5" cy="15.5" r="1.5" fill="currentFill" stroke="none"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconVerticalEllipsis'
})
</script>

<template>
  <g>
    <path d="M10.5918 12.7047C10.8941 13.1093 11.2798 13.444 11.7228 13.6863C12.1657 13.9285 12.6555 14.0725 13.1589 14.1086C13.6624 14.1447 14.1677 14.072 14.6406 13.8954C15.1135 13.7189 15.5429 13.4425 15.8997 13.0852L18.0117 10.9712C18.6528 10.3066 19.0076 9.41662 18.9996 8.49279C18.9916 7.56896 18.6214 6.68525 17.9688 6.03198C17.3162 5.37871 16.4333 5.00816 15.5105 5.00013C14.5876 4.99211 13.6984 5.34725 13.0346 5.98906L11.8237 7.19407" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.4079 11.2953C13.1056 10.8907 12.7199 10.5559 12.277 10.3137C11.834 10.0715 11.3442 9.92742 10.8408 9.89133C10.3373 9.85523 9.83204 9.92795 9.35914 10.1045C8.88624 10.2811 8.45681 10.5574 8.09998 10.9148L5.98807 13.0288C5.3469 13.6933 4.99211 14.5833 5.00013 15.5072C5.00815 16.431 5.37833 17.3147 6.03094 17.968C6.68355 18.6212 7.56638 18.9918 8.48927 18.9998C9.41217 19.0079 10.3013 18.6527 10.9651 18.0109L12.1689 16.8059" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMenuLink'
})
</script>

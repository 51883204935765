<template>
  <main class="home-root flex flex-col min-h-screen">
    <HeaderBlock v-if="showUpgrade" />
    <v-alert v-model="alert"></v-alert>
    <v-alert v-model="alertEmail" @actionCB="resendEmail"></v-alert>
    <v-alert v-model="alertTelegram" @actionCB="setupTelegram"></v-alert>
    <Summary @symbolChanged="symbolChanged" />
    <div class="flex flex-wrap">
      <div class="chart" @dblclick="refreshChart">
        <trading-vue ref="tradingVue" @grid-mousedown="clickChartToSelect" :data="ohlcv_computed" :key="resetkey"
          :titleTxt="'TTM'" :width="this.width" :height="this.height" :toolbar="false"
          :chart-config="{ TB_ICON_BRI: 1.25, DEFAULT_LEN: this.length }" :color-back="colors.colorBack"
          :color-grid="colors.colorGrid" :color-text="colors.colorText" :overlays="overlays_cal">
        </trading-vue>
        <div class="chart-intervals">
          <div v-for="interval in intervals" :key="interval">
            <button class="interval" :class="{ 'selected': selectedInterval === interval }"
              @click.prevent="clickInterval(interval)">{{ interval }}
            </button>
          </div>
        </div>

        <div class="fscreen-control-pannel"
          :class="{ 'visible': fullScreen && !this.showPanel, 'invisible': !fullScreen || this.showPanel }">
          <full-screen-nav @openPanel="togglePanel"></full-screen-nav>
        </div>

        <div v-if="!fullScreen" class="fscreen-control-pannel" >
          <full-screen-expand  @enableFullScreen="enableFullScreen"></full-screen-expand>
        </div>

        <div v-if="fullScreen && showPanel">
          <div
            :class="{ 'visible': !isPanelFold, 'invisible': isPanelFold, 'popup-panel': !isLandscape, 'popup-panel-landscape': isLandscape }">
            <div class="absolute top-2 right-2" @mousedown.prevent.stop="closePanel"
              @touchstart.prevent.stop="closePanel">
              <mono-icon name="close"></mono-icon>
            </div>
            <div class="icon absolute top-8 left-2" @mousedown.prevent.stop="prevSubPanel"
              @touchstart.prevent.stop="prevSubPanel">
              <legacy-icon name="left" size="2em"></legacy-icon>
            </div>
            <div class="icon absolute top-8 right-2" @mousedown.prevent.stop="nextSubPanel"
              @touchstart.prevent.stop="nextSubPanel">
              <legacy-icon name="right" size="2em"></legacy-icon>
            </div>
            <div v-if="isLandscape" class="absolute right-1 top-1/2" @click.prevent.stop="foldPanel">
              <legacy-icon name="double-left" size="1.5em"></legacy-icon>
            </div>
            <div v-else class="flex justify-center" @click.prevent.stop="foldPanel">
              <legacy-icon name="double-down" size="1.5em"></legacy-icon>
            </div>

            <div class="h-full overflow-auto">
              <div v-if="selectedSubPanelIndex === 0">
                <entry-strategy id="entry_strategy" :isExpanded="expanded_id === 'entry_strategy'"
                  @toggleExpand="toggleExpand('entry_strategy')"></entry-strategy>
              </div>
              <div v-if="selectedSubPanelIndex === 1">
                <order-monitor id="order_monitor" :isExpanded="expanded_id === 'order_monitor'"
                  @toggleExpand="toggleExpand('order_monitor')"></order-monitor>
              </div>
              <div v-if="selectedSubPanelIndex === 2">
                <open-pos-safeguarder id="open_pos_safeguarder" :isExpanded="expanded_id === 'open_pos_safeguarder'"
                  @toggleExpand="toggleExpand('open_pos_safeguarder')"></open-pos-safeguarder>
              </div>
              <div v-if="selectedSubPanelIndex === 3">
                <market-news id="market_news" :isExpanded="expanded_id === 'market_news'"
                  @toggleExpand="toggleExpand('market_news')"></market-news>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isPanelFold">
          <div v-if="isLandscape">
            <div class="absolute left-0 top-1/2 unfold" @click.prevent.stop="expandPanel">
              <legacy-icon class="p-1" name="double-right" size="2.0em"></legacy-icon>
            </div>
          </div>
          <div v-else class="absolute bottom-1 left-1/2 unfold" @click.prevent.stop="expandPanel">
              <legacy-icon class="p-1" name="double-up" size="2.0em"></legacy-icon>
          </div>
        </div>
      </div>
      <div v-if="!fullScreen" class="w-full mx-2 mt-6">
        <entry-strategy id="entry_strategy" :isExpanded="expanded_id === 'entry_strategy'"
          @toggleExpand="toggleExpand('entry_strategy')"></entry-strategy>
        <br>
        <order-monitor id="order_monitor" :isExpanded="expanded_id === 'order_monitor'"
          @toggleExpand="toggleExpand('order_monitor')"></order-monitor>
        <br>
        <open-pos-safeguarder id="open_pos_safeguarder" :isExpanded="expanded_id === 'open_pos_safeguarder'"
          @toggleExpand="toggleExpand('open_pos_safeguarder')"></open-pos-safeguarder>
      </div>
    </div>
    <div v-if="loading" class="flex flex-col opacity-50">
      <div class=" fixed top-1/2 self-center">
        <legacy-icon class="icon-loading" name="loading" size="3em" viewbox="100"></legacy-icon>
        <p>Loading...</p>
      </div>
    </div>
    <div>{{symbolInQuery}}</div>
    <!--v-loading :loading="loading" >
      <p>Loading...</p>
    </!--v-loading -->
  </main>
</template>

<script>
import { TradingVue } from 'trading-vue-js'
//import VLoading from '@/components/Loading'
import HeaderBlock from '@/components/Header.vue'
import Summary from '@/components/Summary'
import EntryStrategy from '@/components/EntryStrategy'
import OrderMonitor from '@/components/OrderMonitor'
import OpenPosSafeguarder from '@/components/OpenPosSafeguarder'
import MarketNews from '@/components/MarketNews'
import FullScreenNav from '@/components/FullScreenNav'
import FullScreenExpand from '@/components/FullScreenExpand'
import VAlert from '@/components/AlertWithAction.vue'

import ChartMixin from '@/mixins/ChartMixin'
import AlertMixin from '@/mixins/AlertMixin'

export default {
  name: 'MobileMainPage',
  metaInfo: {
    title: 'Real-time alerts on cryptos based on optimized strategies', // Title of your page
    meta: [
      {
        name: 'description',
        content: 'Gain a competitive edge in cryptocurrency trading by monitoring top assets like Bitcoin (BTC) and Ethereum (ETH) with our platform. Utilize advanced technical indicators such as EMA, RSI, and MACD to tailor your alerts and trading strategy. Receive real-time alerts directly to your preferred device, ensuring you never miss out on key market movements. With comprehensive analytics and historical data at your fingertips, capitalize on opportunities and stay ahead of the curve in the dynamic world of cryptocurrency trading.' // Description of your page
      },
      {
        name: 'keywords',
        content: 'real-time alerts on cryptos, crypto price alerts, cryptocurrency alerting, track cryptocurrency, bitcoin price alert, etherum price alert, crypto tracker, real-time crypto tracker' // Keywords relevant to your page
      },
      // You can add more meta tags as needed
    ]
  },
  components: {
    //VLoading,
    HeaderBlock,
    VAlert,
    TradingVue,
    Summary,
    EntryStrategy,
    OrderMonitor,
    OpenPosSafeguarder,
    MarketNews,
    FullScreenNav,
    FullScreenExpand
  },
  mixins: [ChartMixin, AlertMixin],
  props: {
    isLandscape: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPanel: true,
      isPanelFold: false,
      selectedSubPanelIndex: 0,
    }
  },
  computed: {
    loading() {
      return this.$store.state.chart.isLoading
    },
    showUpgrade() {
      return this.$store.state.appinfo.showUpgrade
    },
  },
  methods: {
    togglePanel() {
      this.showPanel = !this.showPanel
    },
    openPanel() {
      this.showPanel = true
    },
    closePanel() {
      this.showPanel = false
      this.resetActiveStrategyResults()

      if (this.$store.state.user.user===null) {
          this.$store.commit('appinfo/setFullScreenMode', false);
      }
    },
    foldPanel() {
      this.isPanelFold = true
    },
    expandPanel() {
      this.isPanelFold = false
    },
    nextSubPanel() {
      this.selectedSubPanelIndex += 1
      if (this.selectedSubPanelIndex >= this.numSubPanels) {
        this.selectedSubPanelIndex = 0
      }
      this.expanded_id = this.subPanelIds[this.selectedSubPanelIndex]
      //this.resetActiveStrategyResults()
    },
    prevSubPanel() {
      this.selectedSubPanelIndex -= 1
      if (this.selectedSubPanelIndex < 0) {
        this.selectedSubPanelIndex = this.numSubPanels - 1
      }
      this.expanded_id = this.subPanelIds[this.selectedSubPanelIndex]
      //this.resetActiveStrategyResults()
    },
    resetActiveStrategyResults() {
      this.$store.commit('chart/clearActiveStrategy');
      this.$store.commit('chart/clearStrategyResults');
      this.$store.commit('chart/clearSafeLimiter');
    },
  },
  watch: {
    isLandscape: function (val) {
      if (val) {
        this.length = 150
        this.resetkey += 1;
      } else {
        this.length = 75
        this.resetkey += 1;
      }
    }
  },
}
</script>

<style lang='postcss' scoped>
.home-root {
  position: relative;
  width: 100%;
  background-color: #fff;

  .chart {
    @apply relative mt-2;
    border-top: 1px solid #eee;

    .chart-intervals {
      @apply absolute flex flex-row;
      top: 10px;
      right: 80px;
    }

    .interval {
      @apply w-8 h-5 shadow bg-gray-100;
      border: 1px solid #eee;
      border-radius: 1px;
      cursor: pointer;
      text-align: center;
      font: 11px;

      &:hover {
        @apply bg-gray-400;
      }
    }

    .fscreen-control-pannel {
      @apply absolute flex flex-col;
      bottom: 25px;
      left: 3%;
    }

    .popup-panel {
      @apply bg-white-default opacity-90 w-full px-8;
      @apply absolute inset-x-0 bottom-0;
      @apply transition ease-in-out duration-150;
      opacity: 86%;
      box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
      
      z-index: 500;

      height: 42%;

      .popup-panel-collapsed {
        @apply opacity-40;
        @apply overflow-hidden;
        height: 15%;
      }
    }

    .popup-panel-landscape {
      @apply bg-white-default opacity-90 h-full px-6;
      @apply absolute bottom-0 left-0;
      opacity: 86%;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
      z-index: 500;

      width: 42%;

    }

    .popup-panel-collapsed {
      @apply opacity-50;
      @apply overflow-hidden;
      height: 12%;
    }

    .navigator {
      @apply w-8 h-5 shadow bg-gray-100;
      border: 1px solid #eee;
      border-radius: 1px;
      cursor: pointer;
      text-align: center;
      font: 11px;

      &:hover {
        @apply bg-gray-400;
      }
    }

    .selected {
      @apply bg-gray-400;
    }

    .loading-block {
      left: 45%;
      z-index: 1000;
    }

    .unfold {
      @apply rounded-full z-50 shadow-md bg-white-default;
      @apply transition ease-in-out duration-150;
    }
  }


}</style>

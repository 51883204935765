<template>
  <g>
    <path
      d="M9.91856 14.7236V9.60485C9.91696 8.01811 10.505 6.48738 11.5685 5.30982C12.6321 4.13226 14.0952 3.39189 15.6739 3.23242C17.2526 3.07296 18.8343 3.50578 20.1118 4.44687C21.3893 5.38795 22.2716 6.77016 22.5874 8.32517M7.35916 14.7235H25.2747C26.6881 14.7235 27.834 15.8694 27.834 17.2829V26.2406C27.834 27.6541 26.6881 28.8 25.2747 28.8H7.35916C5.94567 28.8 4.7998 27.6541 4.7998 26.2406V17.2829C4.7998 15.8694 5.94567 14.7235 7.35916 14.7235Z"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUnlock'
})
</script>

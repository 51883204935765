<template>
  <g fill="none">
    <path d="M16.6663 5L7.49967 14.1667L3.33301 10"
    fill="none" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconCheckmark2'
})
</script>

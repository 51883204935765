<template>
  <g>
    <path d="M14 11.8889C14 12.1836 13.8736 12.4662 13.6485 12.6746C13.4235 12.8829 13.1183 13 12.8 13H3.2C2.88174 13 2.57652 12.8829 2.35147 12.6746C2.12643 12.4662 2 12.1836 2 11.8889V4.11111C2 3.81643 2.12643 3.53381 2.35147 3.32544C2.57652 3.11706 2.88174 3 3.2 3H6.2L7.4 4.66667H12.8C13.1183 4.66667 13.4235 4.78373 13.6485 4.9921C13.8736 5.20048 14 5.48309 14 5.77778V11.8889Z" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFolderClosed'
})
</script>

<template>
  <div class="icon icon--color" :title="iconName">
    <component
      :is="iconName"
      width="1em"
      height="1em"
    />
  </div>
</template>

<script>
  import manifest from './manifest';
  import { kebabCase } from 'lodash'

  export default {
    name: 'ColorIcon',
    components: manifest.color,
    props: {
      name: {
        type: String,
        required: true
      }
    },
    computed: {
      iconName() {
        return kebabCase(`${this.name}-icon`)
      }
    }
  };
</script>

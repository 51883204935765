<template>
  <g>
    <path d="M10.2311 1.88773C10.3857 1.7331 10.5693 1.61044 10.7713 1.52675C10.9734 1.44307 11.1899 1.39999 11.4086 1.39999C11.6273 1.39999 11.8438 1.44307 12.0459 1.52675C12.2479 1.61044 12.4315 1.7331 12.5861 1.88773C12.7407 2.04236 12.8634 2.22593 12.9471 2.42797C13.0308 2.63 13.0738 2.84654 13.0738 3.06522C13.0738 3.2839 13.0308 3.50044 12.9471 3.70248C12.8634 3.90451 12.7407 4.08809 12.5861 4.24272L4.63801 12.1908L1.3999 13.0739L2.28302 9.83581L10.2311 1.88773Z" stroke="#444754" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconPencil'
})
</script>


import { sendEmailVerification } from 'firebase/auth'

export default {

  computed: {
    alert: {
      get() {
        const isTokenExpired = this.$store.state.user.isTokenExpired;
        if (isTokenExpired) {
          return {
            type: 'danger',
            message: this.$t('Your session has expired. Please sign in again.'),
            action: 'Sign In'
          };
        } else {
          return null;
        }
      },
      set(value) {
        console.log(value);
      },
    },
    alertEmail: {
      get() {
        if (!this.isHome()) {
          return null;
        }
        var user = this.$store.state.user.user;
        if (user && !user.emailVerified) {
          return {
            type: 'danger',
            message: this.$t('An email has been sent to your email address. Please verify your email. If you did not receive the email, please check your spam folder or click resend'),
            action: 'Resend'
          };
        } else {
          return null;
        }
      },
      set(value) {
        console.log(value);
      },
    },
    alertTelegram: {
      get() {
        if (!this.isHome()) {
          return null;
        }
        var user = this.$store.state.user.user;
        if (user === null) {
          return null;
        }
        var isConnectedToTelegram = this.$store.state.user.telegramInfo !== null;
        if (user && !isConnectedToTelegram) {
          return {
            type: 'danger',
            message: this.$t('Please setup your Telegram account'),
            action: 'Setup'
          };
        } else {
          return null;
        }
      },
      set(value) {
        console.log(value);
      },
    }
  },
  methods: {
    isHome: function () {
      return this.$router.currentRoute.name === 'Home';
    },
    resendEmail(action) {
      console.log(action);

      var actionCodeSettings = {
        url: process.env.VUE_APP_FRONT_END_URL,
        handleCodeInApp: true
      };

      var user = this.$store.state.user.user;
      sendEmailVerification(user, actionCodeSettings).then(function () {
        console.log('email sent');
      }).catch(function (error) {
        console.log(error);
      });
    },
    setupTelegram(action) {
      console.log(action);

      this.$router.push('/setup-telegram'); 
    },
    async checkTokenExpiration() {
      const user = this.$store.state.user.user;
      const isTokenExpired = this.$store.state.user.isTokenExpired;
      if (user && !isTokenExpired) {
        try {
          const tokenResult = await user.getIdTokenResult();
          const expirationTimeStr = tokenResult.expirationTime;
          const expirationTime = new Date(expirationTimeStr);

          if (expirationTime < Date.now()) {
            this.$store.commit('user/setIsTokenExpired', true);
          }
        } catch (error) {
          console.log(error);
          if (error.code === 'auth/user-token-expired') {
            this.$store.commit('user/setIsTokenExpired', true);
          }
        }
      }
    },
  },
  mounted() {
    setInterval(this.checkTokenExpiration, 60000 * 30);
  }
};

<template>
  <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43 5L29.7 43L22.1 25.9L5 18.3L43 5Z" stroke="#000000" stroke-width="1" stroke-linejoin="round"/>
    <path d="M43.0001 5L22.1001 25.9" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script lang='ts'>
import Vue from 'vue'

export default Vue.extend({
  name: 'IconSend'
})
</script>

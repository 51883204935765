<template>
  <g>
    <path
      d="M16.0001 9.60001V22.4M9.6001 16H22.4001"
      fill="none"
      stroke-width="currentStroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconPlus'
})
</script>

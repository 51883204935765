<template>
  <g>
    <path
      opacity="0.15"
      d="M27.2001 24.16C27.2001 24.7541 26.9641 25.3238 26.544 25.7439C26.1239 26.164 25.5541 26.4 24.9601 26.4H7.04005C6.44596 26.4 5.87621 26.164 5.45613 25.7439C5.03605 25.3238 4.80005 24.7541 4.80005 24.16V8.47999C4.80005 7.88591 5.03605 7.31615 5.45613 6.89607C5.87621 6.47599 6.44596 6.23999 7.04005 6.23999H12.64L14.88 9.59999H24.9601C25.5541 9.59999 26.1239 9.83599 26.544 10.2561C26.9641 10.6762 27.2001 11.2459 27.2001 11.84V24.16Z"
    />
    <path
      d="M27.2001 24.16C27.2001 24.7541 26.9641 25.3238 26.544 25.7439C26.1239 26.164 25.5541 26.4 24.96 26.4H7.04005C6.44596 26.4 5.87621 26.164 5.45613 25.7439C5.03605 25.3238 4.80005 24.7541 4.80005 24.16V8.47999C4.80005 7.88591 5.03605 7.31615 5.45613 6.89607C5.87621 6.47599 6.44596 6.23999 7.04005 6.23999H12.64L14.88 9.59999H24.96C25.5541 9.59999 26.1239 9.83599 26.544 10.2561C26.9641 10.6762 27.2001 11.2459 27.2001 11.84V24.16Z"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFolder'
})
</script>

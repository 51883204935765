<template>
  <g>
    <rect x="6" y="1" width="4" height="14" fill="#AAB1C5"/>
    <path d="M15 4V3C15 1.89543 14.1046 1 13 1H3C1.89543 1 1 1.89543 1 3V8V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V12" stroke="strokeCurrent"/>
    <path d="M12.6667 8H17.3334" stroke="strokeCurrent" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconColumnRemove'
})
</script>

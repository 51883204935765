import axios from 'axios';

var SummaryModule = {
  namespaced: true,
  state: function () {
    return {
      data: {
        pair: 'BTCUSD',
        current_price: 0,
        market_cap: '0B',
        market_cap_rank: 0,
        ath_change_percentage: '0.0%'
      }
    }
  },

  getters: {
    infoSummary: state => state.data,
  },

  actions: {
    async updateSummary({commit}, symbol) {
      console.log(symbol);

      let url = '/summary/' + symbol;
      axios.get(url)
      .then( response => {
        let data = response.data;
        commit('updateSummaryData', data);
      }) 
      .catch (error => {
        console.log(error);
        throw error;
      })
    },
    async clearSummary({commit}) {
      let data = null;
      commit('updateSummaryData', data);
    }
  },
  mutations: {
    updateSummaryData(state, data) {
      state.data = data;
    },
  }
};

export default SummaryModule;
<template>
  <g>
    <path d="M13.2998 10C14.1282 10 14.7998 9.32843 14.7998 8.5C14.7998 7.67157 14.1282 7 13.2998 7C12.4714 7 11.7998 7.67157 11.7998 8.5C11.7998 9.32843 12.4714 10 13.2998 10Z" />
    <path d="M18.2997 10C19.1281 10 19.7997 9.32843 19.7997 8.5C19.7997 7.67157 19.1281 7 18.2997 7C17.4713 7 16.7997 7.67157 16.7997 8.5C16.7997 9.32843 17.4713 10 18.2997 10Z" />
    <path d="M14.7998 13.5C14.7998 14.3284 14.1282 15 13.2998 15C12.4714 15 11.7998 14.3284 11.7998 13.5C11.7998 12.6716 12.4714 12 13.2998 12C14.1282 12 14.7998 12.6716 14.7998 13.5Z" />
    <path d="M18.2997 15C19.1281 15 19.7997 14.3284 19.7997 13.5C19.7997 12.6716 19.1281 12 18.2997 12C17.4713 12 16.7997 12.6716 16.7997 13.5C16.7997 14.3284 17.4713 15 18.2997 15Z" />
    <path d="M14.7998 18.5C14.7998 19.3284 14.1282 20 13.2998 20C12.4714 20 11.7998 19.3284 11.7998 18.5C11.7998 17.6716 12.4714 17 13.2998 17C14.1282 17 14.7998 17.6716 14.7998 18.5Z" />
    <path d="M18.2997 20C19.1281 20 19.7997 19.3284 19.7997 18.5C19.7997 17.6716 19.1281 17 18.2997 17C17.4713 17 16.7997 17.6716 16.7997 18.5C16.7997 19.3284 17.4713 20 18.2997 20Z" />
    <path d="M14.7998 23.5C14.7998 24.3284 14.1282 25 13.2998 25C12.4714 25 11.7998 24.3284 11.7998 23.5C11.7998 22.6716 12.4714 22 13.2998 22C14.1282 22 14.7998 22.6716 14.7998 23.5Z" />
    <path d="M18.2997 25C19.1281 25 19.7997 24.3284 19.7997 23.5C19.7997 22.6716 19.1281 22 18.2997 22C17.4713 22 16.7997 22.6716 16.7997 23.5C16.7997 24.3284 17.4713 25 18.2997 25Z" />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconDots'
})
</script>

<template>
  <g>
    <path d="M8 3.33301V12.6663" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.33331 8H12.6666" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconPlus2'
})
</script>

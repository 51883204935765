<template>
  <g>
    <path
      d="M21.3335 24L29.3335 16L21.3335 8"
      fill="none"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6665 8L2.6665 16L10.6665 24"
      fill="none"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
export default {
  name: 'IconEmbed',
} 
</script>

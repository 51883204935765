<template>
  <g fill="transparent">
    <path d="M14 5.33301V13.9997H2V5.33301" stroke="currentStroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.3334 2H0.666687V5.33333H15.3334V2Z" stroke="currentStroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66669 8H9.33335" stroke="currentStroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconArchive'
})
</script>

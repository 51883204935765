<template>
  <form class="flex flex-row flex-1" @submit.prevent="submit">
    <legacy-icon name="search" size="1.5em" class="text-gray-400" />
    <input type="text" :placeholder="placeholder" class="w-full ml-2 outline-none bg-transparent" v-model="payload">
  </form>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      payload: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.payload = newValue;
    },
    payload(value) {
      this.$emit('input', value);
    },
  },
  methods: {
    submit() {
      this.$emit('input', this.payload);
    },
  },
};
</script>

<template>
  <g>
    <path d="M17.1583 11.1751L11.1833 17.1501C11.0285 17.305 10.8447 17.428 10.6424 17.5118C10.44 17.5957 10.2232 17.6389 10.0041 17.6389C9.7851 17.6389 9.56822 17.5957 9.36589 17.5118C9.16356 17.428 8.97975 17.305 8.82496 17.1501L1.66663 10.0001V1.66675H9.99996L17.1583 8.82508C17.4687 9.13735 17.6429 9.55977 17.6429 10.0001C17.6429 10.4404 17.4687 10.8628 17.1583 11.1751V11.1751Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.83337 5.83325H5.84004" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconTag'
})
</script>

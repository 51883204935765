<template>
  <g>
    <path d="M15.3333 2.66699V6.66699H11.3333" stroke="currentColor" fill="transparent" stroke-linejoin="round"/>
    <path d="M13.66 9.99968C13.2266 11.2263 12.4064 12.2788 11.3228 12.9987C10.2392 13.7185 8.95097 14.0667 7.65228 13.9907C6.35359 13.9148 5.11477 13.4188 4.1225 12.5775C3.13024 11.7362 2.43828 10.5952 2.15091 9.32639C1.86354 8.05761 1.99632 6.72981 2.52924 5.54306C3.06217 4.35632 3.96636 3.37493 5.10557 2.74678C6.24478 2.11863 7.55729 1.87775 8.84531 2.06045C10.1333 2.24314 11.3271 2.83951 12.2467 3.75968L15.3333 6.66635" stroke="currentColor" fill="transparent" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRedo'
})
</script>

<template>
  <svg width="32" height="32" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36 12L24 24L12 12" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36 24L24 36L12 24" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconDoubleDown'
})
</script>

<template>
  <footer class="footer-basic">
    <div class="social-link-container">
      <a class="social-link" v-for="social in socials" :key="social.key" :href="social.url" target="_blank"
        rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" :class="'bi bi-' + social.key" width="1em" height="1em"
          fill="currentColor" :viewBox="social.viewBox">
          <path :d="social.path"></path>
        </svg>
      </a>
    </div>
    <ul class="list-pages">
      <li class="list-inline-item">
        <router-link class="nav-link" :to="{ path: '/' }" :title="$t('home')" exact>{{ $t('home') }}</router-link>
      </li>
      <li class="list-inline-item">
        <router-link class="nav-link" :to="{ path: '/articles' }" :title="$t('articles')" exact>{{ $t('Articles') }}</router-link>
      </li>
      <li class="list-inline-item">
        <router-link class="nav-link" :to="{ path: '/videos' }" :title="$t('videos')" exact>{{ $t('Videos') }}</router-link>
      </li>
      <li class="list-inline-item">
        <router-link class="nav-link" :to="{ path: '/contact-us' }" :title="$t('contact')" exact>{{ $t('Contact') }}</router-link>
        <!--a class="nav-link" href="https://github.com/adrianmanchev" target="_blank">{{ $t('Contact') }}</a-->
      </li>
    </ul>
    <p class="copyright">TTM © {{ year }}</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterBlock',
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  data: () => ({
    socials: [
      {
        key: 'youtube',
        url: 'https://www.youtube.com/@TTMars2023',
        path: 'M28.7948 10.0341C28.4891 8.97262 27.5867 8.12611 26.4367 7.84394C24.3552 7.33334 16.0146 7.33334 16.0146 7.33334C16.0146 7.33334 7.65946 7.33334 5.57795 7.84394C4.42803 8.13955 3.54011 8.97262 3.21988 10.0341C2.66675 11.969 2.66675 16 2.66675 16C2.66675 16 2.66675 20.031 3.21988 21.9659C3.52555 23.0408 4.42803 23.8739 5.57795 24.1561C7.65946 24.6667 16.0001 24.6667 16.0001 24.6667C16.0001 24.6667 24.3407 24.6667 26.4222 24.1561C27.5575 23.8739 28.4746 23.0408 28.7803 21.9659C29.3334 20.031 29.3334 16 29.3334 16C29.3334 16 29.3479 11.969 28.7948 10.0341ZM13.2781 19.6682V12.3452L20.2504 16L13.2781 19.6682Z',
        viewBox: '0 0 32 32'
      },
      {
        key: 'telegram',
        url: 'https://t.me/TTMars_Bot',
        path: 'M13.0288 25.4567L13.4016 19.8628L23.6282 10.7118C24.0809 10.302 23.5351 10.1036 22.9358 10.4606L10.3124 18.3819L4.85288 16.6627C3.68102 16.332 3.66775 15.5254 5.11913 14.9435L26.3846 6.79768C27.3567 6.36121 28.2888 7.0356 27.916 8.51671L24.294 25.4567C24.041 26.66 23.3086 26.951 22.2966 26.3956L16.7838 22.349L14.134 24.9013C13.8278 25.2053 13.5747 25.4567 13.0288 25.4567Z',
        viewBox: '0 0 32 32'
      },
      {
        key: 'twitter',
        url: 'https://twitter.com/TTMars2023',
        path: 'M13.4821 10.6218L20.0389 3H18.4852L12.7919 9.61788L8.24467 3H3L9.87627 13.0074L3 21H4.55384L10.5661 14.0113L15.3683 21H20.613L13.4817 10.6218H13.4821ZM11.3539 13.0956L10.6572 12.0991L5.11372 4.16971H7.50033L11.974 10.5689L12.6707 11.5655L18.4859 19.8835H16.0993L11.3539 13.096V13.0956Z',
        viewBox: '0 0 24 24'
      },
      {
        key: 'tiktok',
        url: 'https://www.tiktok.com/@ttmars2023',
        path: 'M27.9999 13.842C25.9684 13.847 23.9867 13.2032 22.3348 12.0017V20.3813C22.3342 21.9333 21.8674 23.4482 20.9966 24.7233C20.1259 25.9984 18.8928 26.973 17.4622 27.5168C16.0316 28.0606 14.4717 28.1477 12.9911 27.7664C11.5105 27.3851 10.1798 26.5536 9.17685 25.383C8.1739 24.2125 7.54655 22.7587 7.37869 21.2161C7.21083 19.6735 7.51046 18.1156 8.23751 16.7507C8.96456 15.3858 10.0844 14.279 11.4472 13.5782C12.8101 12.8775 14.351 12.6162 15.864 12.8293V17.0439C15.1716 16.8227 14.4282 16.8293 13.7398 17.063C13.0514 17.2966 12.4533 17.7453 12.0309 18.345C11.6084 18.9446 11.3833 19.6646 11.3876 20.402C11.3918 21.1395 11.6253 21.8567 12.0547 22.4512C12.484 23.0458 13.0873 23.4873 13.7784 23.7127C14.4694 23.9381 15.2129 23.9359 15.9026 23.7063C16.5923 23.4768 17.193 23.0316 17.6189 22.4345C18.0448 21.8374 18.2741 21.1188 18.2741 20.3813V4H22.3348C22.332 4.34844 22.3607 4.6964 22.4206 5.03948C22.5617 5.80537 22.8551 6.53396 23.2828 7.18068C23.7106 7.8274 24.2636 8.37867 24.9082 8.80075C25.8253 9.41688 26.9005 9.74528 27.9999 9.74505V13.842Z',
        viewBox: '0 0 32 32'
      }
    ]
  })
}
</script>

<style lang="postcss" scoped>
.footer-basic {
  text-align: center;

  .social-link-container {
    @apply flex flex-row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .social-link {
      margin: 0 10px;
      width: 32px;
      height: 32px;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #fff;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgba(theme('colors.success.default'), 0.5);
        color: #fff;
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .list-pages {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .list-page {
      margin: 0 10px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #fff;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

}
</style>
```

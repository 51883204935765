// MobileMixin.js
export default {
    data() {
      return {
        isMobile: false,
        isLandscape: false,
      };
    },
    created() {
      this.checkMobile();
      this.checkOrientation();
      window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      checkMobile() {
        this.isMobile = window.innerWidth <= 768; // You can adjust the threshold value based on your requirements
      },
      checkOrientation() {
        this.isLandscape = window.innerWidth > window.innerHeight;
      },
      onResize() {
        this.checkMobile();
        this.checkOrientation();
      },
    },
    watch: {
      isMobile: function (val) {
        if (val) {
          this.$store.commit('appinfo/setFullScreenMode', true);
        }
        this.$store.commit('appinfo/setIsMobile', val);
      }
    }
  };
  
<template>
    <header class="header" @click="toggleExpand">
        <div class="btn-header expand" :class="{ 'expanded': isExpanded }">
            <legacy-icon name="down" size="24px"></legacy-icon>
        </div>
        <h4 class="header-title">{{ title }}</h4>
    </header>
</template>


<script>

export default {
    name: 'PanelHeader',
    components: {
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        }
    },
    methods: {
        toggleExpand() {
            this.$emit('toggleExpand');
        }
    }
}

</script>   

<style lang="postcss" scoped>

.header {
    margin: 0px 2px 12px 0px;
    @apply flex items-center;

    &.with-shadow {
        box-shadow: 0 8px 12px -8px rgba(0, 0, 0, .15);
        z-index: 50;
    }

    &:hover {
        .drag {
            visibility: visible;
        }
    }
}

.header-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: theme("colors.gray.900");
    flex: 0 1 auto;

    &.disabled {
        cursor: not-allowed;
    }
}


.btn-header {
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    >* {
        display: flex;
    }
}

.expand {
    cursor: pointer;

    >svg {
        transition: transform .3s;
        transform: rotate(-90deg);
        stroke: theme('colors.gray.400');
    }

    &.expanded {
        >svg {
            transform: rotate(0deg);
        }
    }
}
</style>
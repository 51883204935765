<template>
  <g style="stroke-width: 0">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 2H2.5C2.224 2 2 2.224 2 2.5C2 2.776 2.224 3 2.5 3H13.5C13.776 3 14 2.776 14 2.5C14 2.224 13.776 2 13.5 2ZM13.5 13H2.5C2.224 13 2 13.224 2 13.5C2 13.776 2.224 14 2.5 14H13.5C13.776 14 14 13.776 14 13.5C14 13.224 13.776 13 13.5 13ZM6.5 4.75H13.5C13.776 4.75 14 4.974 14 5.25C14 5.526 13.776 5.75 13.5 5.75H6.5C6.224 5.75 6 5.526 6 5.25C6 4.974 6.224 4.75 6.5 4.75ZM13.5 7.5H2.5C2.224 7.5 2 7.724 2 8C2 8.276 2.224 8.5 2.5 8.5H13.5C13.776 8.5 14 8.276 14 8C14 7.724 13.776 7.5 13.5 7.5ZM6.5 10.25H13.5C13.776 10.25 14 10.474 14 10.75C14 11.026 13.776 11.25 13.5 11.25H6.5C6.224 11.25 6 11.026 6 10.75C6 10.474 6.224 10.25 6.5 10.25Z" fill="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconAlignRight'
})
</script>

<template>
  <g>
    <path d="M0.666748 2.66699V6.66699H4.66675" stroke="currentColor" fill="transparent" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.34008 9.99964C2.77234 11.2266 3.59164 12.2798 4.67452 13.0006C5.7574 13.7214 7.0452 14.0707 8.34389 13.996C9.64258 13.9212 10.8818 13.4264 11.8748 12.5861C12.8679 11.7459 13.5609 10.6056 13.8496 9.33722C14.1382 8.06881 14.0068 6.74094 13.4752 5.55371C12.9435 4.36647 12.0404 3.38417 10.902 2.75482C9.76351 2.12546 8.45135 1.88315 7.1632 2.06438C5.87505 2.24562 4.68069 2.84059 3.76008 3.75964L0.666748 6.66631" fill="transparent" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUndo'
})
</script>

<template>
  <g>
    <path d="M10 10.8334C10.4603 10.8334 10.8334 10.4603 10.8334 10.0001C10.8334 9.53984 10.4603 9.16675 10 9.16675C9.53978 9.16675 9.16669 9.53984 9.16669 10.0001C9.16669 10.4603 9.53978 10.8334 10 10.8334Z" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.16665 10.8334C4.62688 10.8334 4.99998 10.4603 4.99998 10.0001C4.99998 9.53984 4.62688 9.16675 4.16665 9.16675C3.70641 9.16675 3.33331 9.53984 3.33331 10.0001C3.33331 10.4603 3.70641 10.8334 4.16665 10.8334Z" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconEllipsis'
})
</script>

<template>
  <g>
    <path d="M12 4L4 12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 4L12 12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconClose3'
})
</script>

<template>
  <g transform="translate(5, 5)">
    <path d="M18.3333 2.5H1.66663L8.33329 10.3833V15.8333L11.6666 17.5V10.3833L18.3333 2.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFilter'
})
</script>

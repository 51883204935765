<template>
  <g>
    <path d="M5.03647 6.5547C4.59343 5.89014 5.06982 5 5.86852 5L10.1315 5C10.9302 5 11.4066 5.89015 10.9635 6.5547L8.83205 9.75192C8.43623 10.3457 7.56377 10.3457 7.16795 9.75192L5.03647 6.5547Z"
      stroke="none"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconDown2'
})
</script>

<template>
  <g>
    <path d="M18.3332 3.33301V2.91634C18.3332 1.76634 17.3999 0.833008 16.2499 0.833008C15.0999 0.833008 14.1666 1.76634 14.1666 2.91634V3.33301C13.7083 3.33301 13.3333 3.70801 13.3333 4.16634V7.49967C13.3333 7.95801 13.7083 8.33301 14.1666 8.33301H18.3332C18.7916 8.33301 19.1666 7.95801 19.1666 7.49967V4.16634C19.1666 3.70801 18.7916 3.33301 18.3332 3.33301ZM17.6666 3.33301H14.8332V2.91634C14.8332 2.13301 15.4666 1.49967 16.2499 1.49967C17.0332 1.49967 17.6666 2.13301 17.6666 2.91634V3.33301ZM15.7666 9.99967C15.7999 10.2747 15.8332 10.5497 15.8332 10.833C15.8332 12.5663 15.1666 14.1413 14.0832 15.3247C13.8666 14.6497 13.2499 14.1663 12.4999 14.1663H11.6666V11.6663C11.6666 11.208 11.2916 10.833 10.8333 10.833H5.83325V9.16634H7.49992C7.95825 9.16634 8.33325 8.79134 8.33325 8.33301V6.66634H9.99992C10.9166 6.66634 11.6666 5.91634 11.6666 4.99967V2.88301C10.8749 2.63301 10.0416 2.49967 9.16658 2.49967C4.56658 2.49967 0.833252 6.23301 0.833252 10.833C0.833252 15.433 4.56658 19.1663 9.16658 19.1663C13.7666 19.1663 17.4999 15.433 17.4999 10.833C17.4999 10.5497 17.4832 10.2747 17.4583 9.99967H15.7666ZM8.33325 17.4413C5.04158 17.033 2.49992 14.233 2.49992 10.833C2.49992 10.3163 2.56659 9.82467 2.67492 9.34134L6.66658 13.333V14.1663C6.66658 15.083 7.41658 15.833 8.33325 15.833V17.4413Z" fill="none"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconShareGlobe'
})
</script>

<template>
  <g>
    <path d="M3.00213 9.9383C3.00213 11.253 4.06786 12.3188 5.38257 12.3188C6.6973 12.3188 7.76303 11.253 7.76303 9.9383C7.76303 8.62357 6.6973 7.55786 5.38257 7.55786C5.11238 7.55786 4.85374 7.60496 4.61161 7.68797C5.14731 4.61557 7.54319 2.63423 5.32222 4.26492C2.8595 6.07316 2.99949 9.86555 3.00224 9.93505C3.00224 9.93614 3.00213 9.9371 3.00213 9.9383Z" fill="currentFill"/>
    <path d="M8.23919 9.9383C8.23919 11.253 9.30493 12.3188 10.6197 12.3188C11.9344 12.3188 13.0001 11.253 13.0001 9.9383C13.0001 8.62357 11.9344 7.55786 10.6196 7.55786C10.3494 7.55786 10.0908 7.60496 9.84868 7.68797C10.3844 4.61557 12.7803 2.63423 10.5593 4.26492C8.09657 6.07316 8.23653 9.86555 8.23931 9.93505C8.23931 9.93614 8.23919 9.9371 8.23919 9.9383Z" fill="currentFill"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconQuote'
})
</script>

<template>
  <g>
    <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 7H7V16H10V7Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 7H14V12H17V7Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMenuTask'
})
</script>

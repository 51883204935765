<template>
  <g>
    <path
      d="M4.7998 8.32002H7.28869M7.28869 8.32002H27.1998M7.28869 8.32002L7.28885 26.24C7.28885 26.919 7.55107 27.5701 8.01782 28.0502C8.48458 28.5303 9.11764 28.8 9.77773 28.8H22.2222C22.8823 28.8 23.5153 28.5303 23.9821 28.0502C24.4488 27.5701 24.7111 26.919 24.7111 26.24V8.32001L7.28869 8.32002ZM11.0222 8.32001V5.76001C11.0222 5.08106 11.2844 4.42991 11.7512 3.94982C12.2179 3.46973 12.851 3.20001 13.5111 3.20001H18.4888C19.1489 3.20001 19.782 3.46973 20.2488 3.94982C20.7155 4.42991 20.9777 5.08106 20.9777 5.76001V8.32001M13.5111 14.72V22.4M18.4888 14.72V22.4"
      fill="none"
      stroke-width="currentStrokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconTrash'
})
</script>

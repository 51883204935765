<template>
  <g>
    <path
      d="M22.4625 5.77546C22.7717 5.46619 23.1389 5.22087 23.5429 5.0535C23.947 4.88613 24.3801 4.79999 24.8174 4.79999C25.2548 4.79999 25.6879 4.88613 26.092 5.0535C26.496 5.22087 26.8632 5.46619 27.1724 5.77546C27.4817 6.08472 27.727 6.45186 27.8944 6.85593C28.0618 7.26 28.1479 7.69308 28.1479 8.13044C28.1479 8.56781 28.0618 9.00088 27.8944 9.40495C27.727 9.80902 27.4817 10.1762 27.1724 10.4854L11.2763 26.3816L4.80005 28.1478L6.56629 21.6716L22.4625 5.77546Z"
      fill="none"
      stroke-width="currentStrokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconEdit'
})
</script>

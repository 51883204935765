<template>
  <g>
    <path d="M5.63281 4.10936H13.6485C13.8426 4.10936 14 3.95195 14 3.7578C14 3.56365 13.8426 3.40625 13.6485 3.40625H5.63281C5.43866 3.40625 5.28125 3.56365 5.28125 3.7578C5.28125 3.95195 5.43866 4.10936 5.63281 4.10936Z" fill="currentFill"/>
    <path d="M13.6485 7.64844H5.63281C5.43866 7.64844 5.28125 7.80584 5.28125 8C5.28125 8.19415 5.43866 8.35156 5.63281 8.35156H13.6485C13.8426 8.35156 14 8.19415 14 8C14 7.80584 13.8426 7.64844 13.6485 7.64844Z" fill="currentFill"/>
    <path d="M13.6485 11.8906H5.63281C5.43866 11.8906 5.28125 12.048 5.28125 12.2422C5.28125 12.4363 5.43866 12.5937 5.63281 12.5937H13.6485C13.8426 12.5937 14 12.4363 14 12.2422C14 12.048 13.8426 11.8906 13.6485 11.8906Z" fill="currentFill"/>
    <path d="M2.60938 2.70409H2.80888V4.9306C2.80888 5.12476 2.96629 5.28216 3.16044 5.28216C3.3546 5.28216 3.51201 5.12476 3.51201 4.9306V2.35253C3.51201 2.15838 3.3546 2.00098 3.16044 2.00098H2.60938C2.41522 2.00098 2.25781 2.15838 2.25781 2.35253C2.25781 2.54668 2.41522 2.70409 2.60938 2.70409Z" fill="currentFill"/>
    <path d="M3.73609 8.9316C3.52874 8.9342 3.31042 8.9361 3.11551 8.93695C3.24814 8.75768 3.42034 8.5201 3.64 8.20628C3.8064 7.96856 3.91515 7.74276 3.96329 7.53516C3.96597 7.52365 3.96805 7.51203 3.96955 7.50031L3.98205 7.40251C3.98394 7.38774 3.98488 7.37286 3.98488 7.35795C3.98488 6.80733 3.53692 6.35938 2.9863 6.35938C2.51018 6.35938 2.09808 6.69811 2.00642 7.16479C1.96899 7.3553 2.09311 7.54008 2.28364 7.57749C2.47418 7.61487 2.65894 7.49079 2.69635 7.30027C2.7234 7.16249 2.84534 7.06248 2.98627 7.06248C3.14312 7.06248 3.27181 7.18534 3.28119 7.33986L3.2746 7.39149C3.25276 7.47532 3.1982 7.61126 3.06395 7.80311C2.66422 8.37425 2.42574 8.68648 2.29763 8.85424C2.13577 9.06615 2.04661 9.18289 2.1053 9.37374C2.14027 9.48739 2.22607 9.57448 2.3408 9.6127C2.38838 9.62857 2.42651 9.64127 2.91737 9.64127C3.11223 9.64127 3.37846 9.63928 3.74495 9.63466C3.93908 9.63223 4.0945 9.47286 4.09208 9.2787C4.0896 9.08458 3.92994 8.92945 3.73609 8.9316Z" fill="currentFill"/>
    <path d="M3.04615 10.7188C2.57121 10.7188 2.16016 11.0566 2.06875 11.5222C2.03132 11.7127 2.15545 11.8975 2.34597 11.9349C2.53652 11.9723 2.72128 11.8482 2.75869 11.6576C2.78552 11.521 2.90641 11.4219 3.04615 11.4219C3.2077 11.4219 3.33912 11.5533 3.33912 11.7148C3.33912 11.8764 3.2077 12.0078 3.04615 12.0078C2.85199 12.0078 2.69458 12.1652 2.69458 12.3593C2.69458 12.5535 2.85199 12.7109 3.04615 12.7109C3.2077 12.7109 3.33912 12.8423 3.33912 13.0039C3.33912 13.1654 3.2077 13.2968 3.04615 13.2968C2.90079 13.2968 2.77605 13.1886 2.75604 13.0452C2.75414 13.0316 2.75318 13.0177 2.75318 13.0039C2.75318 12.8097 2.59577 12.6523 2.40161 12.6523C2.20746 12.6523 2.05005 12.8097 2.05005 13.0039C2.05005 13.0501 2.05328 13.0966 2.05963 13.1423C2.12786 13.6312 2.55199 13.9999 3.04615 13.9999C3.59541 13.9999 4.04225 13.5531 4.04225 13.0039C4.04225 12.7582 3.95267 12.5332 3.80473 12.3593C3.95269 12.1855 4.04225 11.9605 4.04225 11.7148C4.04225 11.1656 3.59539 10.7188 3.04615 10.7188Z" fill="currentFill"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconOrderedList'
})
</script>

<template>
  <path d="M17.8667 9.20835L10.2084 16.8667C9.27017 17.8049 7.99769 18.332 6.67087 18.332C5.34405 18.332 4.07157 17.8049 3.13337 16.8667C2.19517 15.9285 1.66809 14.656 1.66809 13.3292C1.66809 12.0024 2.19517 10.7299 3.13337 9.79168L10.7917 2.13335C11.4172 1.50788 12.2655 1.15649 13.15 1.15649C14.0346 1.15649 14.8829 1.50788 15.5084 2.13335C16.1338 2.75882 16.4852 3.60713 16.4852 4.49168C16.4852 5.37623 16.1338 6.22455 15.5084 6.85002L7.8417 14.5083C7.52897 14.8211 7.10481 14.9968 6.66254 14.9968C6.22026 14.9968 5.79611 14.8211 5.48337 14.5083C5.17064 14.1956 4.99494 13.7715 4.99494 13.3292C4.99494 12.8869 5.17064 12.4627 5.48337 12.15L12.5584 5.08335"
  fill="none"
  stroke-width="1"
  stroke-linecap="round"
  stroke-linejoin="round"/>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconAttachment'
})
</script>

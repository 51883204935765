<template>
  <g>
    <path d="M71.6875 62.4381H39.3125C38.036 62.4381 37 61.4067 37 60.1256C37 58.8445 37 15.1521 37 13.8756C37 12.5991 38.036 11.5631 39.3125 11.5631H71.6875C72.964 11.5631 74 12.5991 74 13.8756V60.1256C74 61.4067 72.964 62.4381 71.6875 62.4381Z" fill="#ECEFF1"/>
    <path d="M62.4375 25.4381H39.3125C38.036 25.4381 37 24.4067 37 23.1256C37 21.8445 38.036 20.8131 39.3125 20.8131H62.4375C63.714 20.8131 64.75 21.8491 64.75 23.1256C64.75 24.4021 63.714 25.4381 62.4375 25.4381Z" fill="#1976D2"/>
    <path d="M62.4375 34.6881H39.3125C38.036 34.6881 37 33.6567 37 32.3756C37 31.0945 38.036 30.0631 39.3125 30.0631H62.4375C63.714 30.0631 64.75 31.0991 64.75 32.3756C64.75 33.6521 63.714 34.6881 62.4375 34.6881Z" fill="#1976D2"/>
    <path d="M62.4375 43.9381H39.3125C38.036 43.9381 37 42.9067 37 41.6256C37 40.3445 38.036 39.3131 39.3125 39.3131H62.4375C63.714 39.3131 64.75 40.3491 64.75 41.6256C64.75 42.9021 63.714 43.9381 62.4375 43.9381Z" fill="#1976D2"/>
    <path d="M62.4375 53.1881H39.3125C38.036 53.1881 37 52.1567 37 50.8756C37 49.5945 38.036 48.5631 39.3125 48.5631H62.4375C63.714 48.5631 64.75 49.5991 64.75 50.8756C64.75 52.1521 63.714 53.1881 62.4375 53.1881Z" fill="#1976D2"/>
    <path d="M40.7879 2.84959C40.2606 2.40559 39.553 2.22059 38.887 2.35471L1.887 9.29221C0.7955 9.49571 0 10.4531 0 11.5631V62.438C0 63.548 0.790875 64.5054 1.887 64.7089L38.887 71.6464C39.0304 71.6742 39.1691 71.688 39.3125 71.688C39.849 71.688 40.3716 71.503 40.7879 71.1562C41.3198 70.7168 41.625 70.0647 41.625 69.3755V4.62559C41.625 3.93646 41.3198 3.28896 40.7879 2.84959Z" fill="#1565C0"/>
    <path d="M30.0486 48.8221C29.9284 49.8812 29.0959 50.7276 28.0368 50.8571C27.9396 50.871 27.8471 50.8756 27.75 50.8756C26.7973 50.8756 25.9278 50.2836 25.5855 49.3771L20.8125 36.6491L16.0395 49.3771C15.6695 50.3668 14.6983 50.9727 13.6253 50.8617C12.5754 50.7507 11.7336 49.9367 11.5856 48.8868L9.27314 32.6993C9.09276 31.4413 9.97151 30.2666 11.2341 30.0862C12.5014 29.9012 13.6715 30.7846 13.8519 32.0472L14.8833 39.2807L18.6434 29.2491C19.3186 27.4453 22.2971 27.4453 22.977 29.2491L26.5198 38.6933L27.7639 27.4916C27.9073 26.2243 29.082 25.3132 30.3169 25.4473C31.5888 25.5953 32.4999 26.7377 32.3611 28.005L30.0486 48.8221Z" fill="#FAFAFA"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFileDocx'
})
</script>

<template>
  <g>
    <path
      d="M9.91977 14.72V9.60001C9.91977 7.90263 10.5941 6.27476 11.7943 5.07453C12.9945 3.8743 14.6224 3.20001 16.3198 3.20001C18.0172 3.20001 19.645 3.8743 20.8453 5.07453C22.0455 6.27476 22.7198 7.90263 22.7198 9.60001V14.72M7.3598 14.72H25.2798C26.6937 14.72 27.8398 15.8662 27.8398 17.28V26.24C27.8398 27.6539 26.6937 28.8 25.2798 28.8H7.3598C5.94596 28.8 4.7998 27.6539 4.7998 26.24V17.28C4.7998 15.8662 5.94596 14.72 7.3598 14.72Z"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLock'
})
</script>

<template>
    <div class="panel">
        <panel-header :is-expanded="isExpanded" title="Safeguard Open Position" @toggleExpand="toggleExpand" />
        <Collapsible :is-expanded="isExpanded">
            <main>
                <list-strategy ref="openPosSafeGuardList" :strategies="registeredOpenposSafeGuards" @clickStrategy="clickStrategy" @deleteStrategy="deleteStrategy" />
                <div class="divider" v-if="registeredOpenposSafeGuards.length > 0"></div>
                <v-field name="Time" hasIconLeft>
                    <input class="input" id="time" type="text" placeholder="" v-model.trim="currentUTC" :disabled="openpos.selectedTimeStatus===0 || selectedIndex!==-1"/>
                    <div class="icon is-left flex input-label" size="1.5em">
                        <select class="time-status" id="time-status" v-model.trim="openpos.selectedTimeStatus" :disabled="this.selectedIndex!==-1" @change="changeTimeStatus">
                            <option :value="0">Curr</option>
                            <option :value="1">Past</option>
                        </select>
                        <span v-if="!isMobile">Time </span>
                    </div>
                    <template #feedback v-if="!$v.openpos.time.required">
                        <p class="feedback is-danger">Time is required.</p>
                    </template>
                </v-field>
                <v-field name="Price" hasIconLeft>
                    <input class="input" id="price" type="number" placeholder="" v-model.trim="openpos.price" :disabled="selectedIndex!==-1"
                    @input="openpos.price = parseFloat(openpos.price)"/>
                    <div class="icon is-left input-label" size="1.5em"> Price(USD) </div>
                    <template #feedback v-if="!$v.openpos.price.required">
                        <p class="feedback is-danger">Price is required.</p>
                    </template>
                </v-field>
                <v-field name="Amount" hasIconLeft>
                    <input class="input" id="amount" type="number" placeholder="" v-model.trim="openpos.amount" :disabled="selectedIndex!==-1"
                    @input="openpos.amount = parseFloat(openpos.amount)" />
                    <p class="icon is-left input-label" size="1.5em"> Amount </p>
                    <template #feedback v-if="!$v.openpos.amount.required">
                        <p class="feedback is-danger">Amount is required.</p>
                    </template>
                </v-field>

                <p class="text-right">Total(USD): {{total}}</p>

                <div class="my-4 flex flex-row">
                    <div class="divider-half" />
                        <span class="separator-text btn-small mx-auto" @click.prevent.stop="updateSafeLimiter">
                        <svg fill="currentColor" viewBox="0 0 20 20" 
                        xmlns="http://www.w3.org/2000/svg" 
                        preserveAspectRatio="xMinYMin">
                        <path d='M17.83 4.194l.42-1.377a1 1 0 1 1 1.913.585l-1.17 3.825a1 1 0 0 1-1.248.664l-3.825-1.17a1 1 0 1 1 .585-1.912l1.672.511A7.381 7.381 0 0 0 3.185 6.584l-.26.633a1 1 0 1 1-1.85-.758l.26-.633A9.381 9.381 0 0 1 17.83 4.194zM2.308 14.807l-.327 1.311a1 1 0 1 1-1.94-.484l.967-3.88a1 1 0 0 1 1.265-.716l3.828.954a1 1 0 0 1-.484 1.941l-1.786-.445a7.384 7.384 0 0 0 13.216-1.792 1 1 0 1 1 1.906.608 9.381 9.381 0 0 1-5.38 5.831 9.386 9.386 0 0 1-11.265-3.328z' />
                        </svg>
                        </span>
                        <div class="divider-half" />
                    </div>
                
                <!--v-field name="Reference">
                    <select class="input input-role" id="reference" v-model.trim="openpos.reference">
                        <option v-for="reference, index in ref_options" :key="index" :value="reference">{{reference}}</option>
                    </select>
                    <p class="icon is-left input-label" size="1.5em"> Reference </p>
                    <template #feedback v-if="!$v.openpos.reference.required">
                        <p class="feedback is-danger">Reference is required.</p>
                    </template>
                </v-field -->

                <v-field name="Reference">
                    <div class="is-left flex flex-row w-full mt-2"> 
                        <span class="slider-label">Reference </span>
                        <select class="input input-role" id="reference" v-model.trim="openpos.reference">
                            <option v-for="reference, index in ref_options" :key="index" :value="reference">{{reference}}</option>
                        </select>
                    </div>
                </v-field>

                <v-field name="Factor">
                    <div class="is-left flex flex-row w-full mt-2"> 
                        <span class="slider-label">Factor </span>
                        <range-slider
                        class="slider"
                        :min="factor_range.min"
                        :max="factor_range.max"
                        :step="factor_range.step"
                        v-model="openpos.factor">
                        <template slot="knob">
                            <div class="knobby">
                                <div class="tooltip-inner">
                                    {{ factor_display }}
                                </div>
                            </div>
                        </template>
                        </range-slider>
                    </div>
                    
                </v-field>

                <v-field name="Tolerance">
                    <div class="is-left flex flex-row w-full mt-2"> 
                        <span class="slider-label">Tolerance(%) </span>
                        <range-slider
                        class="slider"
                        :min="0"
                        :max="100"
                        :step="1"
                        v-model.trim="openpos.tolerance">
                        <template slot="knob">
                            <div class="knobby">
                                <div class="tooltip-inner">
                                    {{ tolerance_display }}
                                </div>
                            </div>
                        </template>
                        </range-slider>
                    </div>
                    
                </v-field>

                <!--v-field name="Factor" label="Factor">
                    <input class="input" id="factor" type="range" :min="factor_range.min" :max="factor_range.max" :step="factor_range.step" 
                    placeholder="" v-model.trim="openpos.factor" 
                    @input="openpos.factor = parseFloat(openpos.factor)"/>
                    <p class="icon is-left input-label" size="1.5em"> Factor </p>
                    <template #feedback v-if="!$v.openpos.factor.required">
                        <p class="feedback is-danger">Factor is required.</p>
                    </template>
                </v-field -->

                <!--v-field name="Tolerance">
                    <input class="input" id="tolerance" type="number" placeholder="" v-model.trim="openpos.tolerance" 
                    @input="openpos.tolerance = parseFloat(openpos.tolerance)"/>
                    <p class="icon is-left input-label" size="1.5em"> Tolerance(%) </p>
                    <template #feedback v-if="!$v.openpos.tolerance.required">
                        <p class="feedback is-danger">Tolerance is required.</p>
                    </template>
                </v-field -->

                <button class="btn btn-success mt-3" :disabled="isButtonDisabled" @click.prevent.stop="registerStrategy"
                    type="submit">{{buttonLabel}}</button>
                <Alert v-model="alert" />
            </main>
        </Collapsible>
    </div>
</template>

  
<script>
import { required } from 'vuelidate/lib/validators';
import numeral from 'numeral';
import VField from '@/components/Field'
import Alert from '@/components/Alert'
import Collapsible from '@/components/Collapsible'
import PanelHeader from '@/components/panel/PanelHeader'
import ListStrategy from '@/components/panel/ListStrategy'

import RangeSlider from 'vue-range-slider'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'

import {INTERVAL_MAP, LENGTH_KLINE, SAFELIMITER_SAFERANGE} from "@/utils/constants";
import {setShowUpgrade} from '@/utils/app.js'


export default {
    name: 'OpenPosSafeGuard',
    components: {
        Alert,
        VField,
        Collapsible,
        ListStrategy,
        PanelHeader,
        RangeSlider
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        return {
            ref_options: ['EMA7', 'EMA25'], //this.$store.state.appinfo.openpos.ref_options
            factor_range: {
                min: -1.0,
                max: 1.0,
                step: 0.1
            }
        }  
    },
    data() {
        return {
            openpos: {
                selectedTimeStatus: 0, // 0: current, 1: past
                time: 0,
                price: 0,
                amount: 1.0,
                total: 0,
                factor: 0,
                reference: this.ref_options[0],
                tolerance: 25
            },
            selectedIndex: -1,
            doNotResetIndex: false,
            alert: null,
        };
    },
    validations: {
        openpos: {
            time: {
                required,
            },
            price: {
                required,
            },
            amount: {
                required,
            },
            total: {
                required,
            },
            factor: {
                required,
            },
            reference: {
                required,
            },
            tolerance: {
                required,
            },
        },

    },
    computed: {
        isMobile() {
            return this.$store.state.appinfo.isMobile
        },
        currentUTC() {
            var date = new Date(this.openpos.time);
            return date.toUTCString().replace(/:\d{2}\sGMT$/, '').replace(/^.*, /, '');
        },
        registeredOpenposSafeGuards() {
            return this.$store.state.user.registeredOpenposSafeGuards
        },
        chartSelectedSymbol() {
            return this.$store.state.chart.selectedSymbol
        },
        chartSelectedInterval() {
            return this.$store.state.chart.selectedInterval
        },
        factor_display() {
            return numeral(this.openpos.factor).format('0.0');
        },
        tolerance_display() {
            return numeral(this.openpos.tolerance).format('0');
        },
        total() {
            return numeral(this.openpos.price * this.openpos.amount).format('0,0.00');
        },
        buttonLabel() {
            return this.selectedIndex === -1 ? 'Add' : 'Update'
        },
        isButtonDisabled() {
            const isTokenExpired = this.$store.state.user.isTokenExpired;
            return this.chartSelectedSymbol === null || this.chartSelectedInterval === null || isTokenExpired
        }
    },
    methods: {
        toggleExpand() {
            this.$emit('toggleExpand')
        },
        async clickStrategy(index, openPosSafeGuard) {
            console.log('clickStrategy', index)
            this.selectedIndex = index

            if (index === -1) {
                this.$store.commit('chart/clearSafeLimiter');
                return
            }

            this.openpos.time = openPosSafeGuard.openpos.time
            this.openpos.price = openPosSafeGuard.openpos.price
            this.openpos.amount = openPosSafeGuard.openpos.amount
            this.openpos.total = openPosSafeGuard.openpos.total
            this.openpos.factor = openPosSafeGuard.openpos.factor
            this.openpos.reference = openPosSafeGuard.openpos.reference
            this.openpos.tolerance = openPosSafeGuard.openpos.tolerance
            this.openpos.selectedTimeStatus = 1

            if (this.chartSelectedSymbol !== openPosSafeGuard.symbol) {
                this.doNotResetIndex = true;
                await this.$store.dispatch('summary/updateSummary', openPosSafeGuard.symbol);
                await this.$store.dispatch('chart/updateChartUponSymbolChange', openPosSafeGuard.symbol);
            }
            if (this.chartSelectedInterval !== openPosSafeGuard.interval) {
                this.doNotResetIndex = true;
                this.$store.commit('chart/updateSelectedInterval', openPosSafeGuard.interval);
            }
            this.$store.dispatch('chart/updateSafeLimiter', {openpos: openPosSafeGuard.openpos, useCachedData: true});
        },
        async deleteStrategy(index, openposSafeGuard) {
            console.log(index)
            this.selectedIndex = -1
            try {
                await this.$store.dispatch('user/deleteOpenposSafeGuard', openposSafeGuard)
            } catch (e) {
                console.log(e)
                this.alert = {
                    type: 'danger',
                    message: 'Session maybe expired. Please refresh the page.',
                }
            }
            this.$store.commit('chart/clearSafeLimiter');
            this.$store.commit('chart/clearStrategyResults');
        },
        async registerStrategy() {
            if (!this.isSelectedTimeValid()) {
                this.alert = {
                    type: 'danger',
                    message: 'Please select a later time'
                }
                return
            }
            let user = this.$store.state.user
            if (user.user===null) {
                this.alert = {
                    type: 'danger',
                    message: 'Please sign in first'
                }

                this.$store.commit('appinfo/setFullScreenMode', false);
                return
            }

            if (user.userAccountType === 'basic') {
                this.alert = {
                    type: 'danger',
                    message: 'Please upgrade to Plus first'
                }
                setShowUpgrade();
                return
            }

            if (user.userAccountType === 'plus') {
                if (this.registeredOpenposSafeGuards.length >= 6) {
                    this.alert = {
                        type: 'danger',
                        message: 'You can only add up to 6 open positions',
                    }
                    return
                }
            }

            let selectedSymbol = this.$store.state.chart.selectedSymbol;
            let selectedInterval = this.$store.state.chart.selectedInterval;

            if (this.selectedIndex === -1 && this.isSymbolInList(selectedSymbol)) {
                this.alert = {
                    type: 'danger',
                    message: selectedSymbol +  ' is already in open postion list'
                }
                return
            }

            let openpos_tmp = {
                time: this.openpos.time,
                price: this.openpos.price,
                amount: this.openpos.amount,
                total: this.openpos.total,
                factor: this.openpos.factor,
                reference: this.openpos.reference,
                tolerance: this.openpos.tolerance,
                selectedTimeStatus: this.openpos.selectedTimeStatus,
            }

            let newOpenPosSafeGuard = {
                id: selectedSymbol+'_'+selectedInterval+'_'+this.openpos.time,
                name: selectedSymbol+'-'+selectedInterval+'-'+this.openpos.reference,
                symbol: selectedSymbol,
                interval: selectedInterval,
                openpos: openpos_tmp,
                status: 'Active',
                type: 'openpos_safeguard',
                created_at: Math.floor(Date.now())
            }

            if (this.selectedIndex === -1) {
                try {
                    await this.$store.dispatch('user/registerOpenposSafeGuard', newOpenPosSafeGuard)
                    this.alert = {
                        type: 'success',
                        message: 'Successfully added'
                    }
                } catch (e) {
                    this.alert = {
                        type: 'danger',
                        message: e.message
                    }
                    return
                }
            } else {
                try {
                    await this.$store.dispatch('user/updateOpenposSafeGuard', newOpenPosSafeGuard)
                    this.$store.commit('chart/deleteCachedSafeLimiter', newOpenPosSafeGuard.symbol);
                    this.alert = {
                        type: 'success',
                        message: 'Successfully updated'
                    }
                } catch (e) {
                    this.alert = {
                        type: 'danger',
                        message: e.message
                    }
                    return
                }
            }
        },
        isSymbolInList(symbol) {
            let existing = false
            for (let i=0; i<this.registeredOpenposSafeGuards.length; i++) {
                if (this.registeredOpenposSafeGuards[i].symbol === symbol) {
                    existing = true
                    break
                }
            }
            return existing
        },
        updateSafeLimiter() {
            if (!this.isSelectedTimeValid()) {
                this.alert = {
                    type: 'danger',
                    message: 'Please select a later time'
                }
                return
            }
            this.$store.dispatch('chart/updateSafeLimiter', {openpos: this.openpos, useCachedData: false})
        },
        changeTimeStatus() {
            this.updateTime()
        },
        updateTime() {
            if (this.openpos.selectedTimeStatus === 0) {
                let currentTimeStamp = Date.now();  // Get current timestamp in milliseconds
                this.openpos.time = Math.floor(currentTimeStamp);  // Convert to UTC timestamp (in seconds)

                let klines = this.$store.state.chart.klines
                if (klines !== null) {
                    this.openpos.price = klines['1d'][klines['1d'].length - 1][4]
                }
            }
        },
        resetListStatus() {
            this.selectedIndex = -1
            if (this.$refs.openPosSafeGuardList !== undefined) {
                this.$refs.openPosSafeGuardList.resetClickedIndex()
            }
        },
        isSelectedTimeValid() {
            if (this.openpos.selectedTimeStatus === 0) {
                return true;
            }
            let curTimeUTC = Date.now();
            if ((curTimeUTC - this.openpos.time) > 1000 * INTERVAL_MAP[this.chartSelectedInterval] * (LENGTH_KLINE - SAFELIMITER_SAFERANGE)) {
                return false;
            } else {
                return true;
            }
        },
    },
    mounted() {
        this.updateTime()
        setInterval(this.updateTime, 60000);

        window.openpos = this.openpos
    },
    watch: {
        chartSelectedSymbol: {
            handler: function () {
                if (this.doNotResetIndex === false) {
                    this.resetListStatus()
                } else {
                    this.doNotResetIndex = false;
                }

                // When no safeguard is selected, update the price and time
                if (this.selectedIndex === -1) {
                    this.openpos.selectedTimeStatus = 0
                    // wait for data to be loaded and then update time and price
                    setTimeout(this.updateTime, 1000)
                }
            },
            immediate: true
        },
        chartSelectedInterval: {
            handler: function () {
                if (this.doNotResetIndex === false) {
                    this.resetListStatus() // Do not reset index when interval changes, so interval can be updated in safelimiter
                } else {
                    this.doNotResetIndex = false;
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="postcss" scoped>
.panel {
    @apply w-full;
    @apply border rounded border-gray-100;
    padding: 12px;
    transition: .4s;

    .input-label {
        align-items: center;
        margin-top: 4px;
    }

    .time-status {
        width: 55px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 2px;
        font-size: 1.0em;
        direction: rtl;
        text-align: left;
    }

    .input {
        @apply border rounded;
        border: 1px solid #E0E2E7;
        text-align: right;
        padding-left: 10px;
        padding-right: 20px;
    }

    .input::-webkit-outer-spin-button,
    .input::-webkit-inner-spin-button {
        display: none;
    }

    button[type="submit"] {
        width: 100%;
    }

    &.has-slot button[type="submit"] {
        width: 82px;
        float: right;
        padding-left: 0px;
        padding-right: 0px;
    }

    .input.input-role {
        position: relative;
        appearance: none;

        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.03647 6.5547C4.59343 5.89014 5.06982 5 5.86852 5H10.1315C10.9302 5 11.4066 5.89015 10.9635 6.5547L8.83205 9.75192C8.43623 10.3457 7.56377 10.3457 7.16795 9.75192L5.03647 6.5547Z' fill='%23444754'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: calc(100% - 5px) center;
    }
    .divider {
        @apply w-full;
        border-bottom: rgba(theme('colors.gray.400'), 0.5) solid 1px;
        margin: 0px 0 10px 0;
    }

    .divider-half {
        @apply w-2/5;
        @apply mb-2;
        border-bottom: rgba(theme('colors.gray.400'), 0.5) solid 1px;
    }

    .text-right {
        margin-top: 4px;
        margin-left: 2px;
        text-align: right;
        font-size: 0.8rem;
        font-weight: bold;
    }

    svg {
        width: 16px;
        height: 16px;
        fill: rgba(theme('colors.success.default'), 1.0);
        &:hover {
          fill: rgba(theme('colors.success.default'), 0.5)
        }
      }    
}
</style>


<style>
.slider-label {
    width: 35%;
    padding-left: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    color: #AAB1C5;
}
.slider {
    width: 65%;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.slider .range-slider-rail {
    height: 4px;
    background-color: #DEE2EE;
    border-radius: 5px;
}

.slider .range-slider-fill {
    height: 3px;
    margin: 0 4px;
    background-color: #AAB1C5;
}

.slider .range-slider-knob {
    height: 12px;
    width: 12px;    
    color: #fff;
    background-color: #AAB1C5;
    border: 0;
    box-shadow: 0 0 0 5px #DEE2EE;
}

.slider .knobby {
    position: relative;
}

.slider .knobby .tooltip-inner {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -28px;
    left: -3px;
    color: #000;
}

</style> 


  
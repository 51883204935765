<template>
  <g transform="translate(5, 5)">
    <path d="M2.5 10H17.5" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 5H17.5" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 15H17.5" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconList'
})
</script>

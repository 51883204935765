<template>
  <g>
    <path d="M14.5898 15H6.38672C6.16017 15 5.97656 14.8164 5.97656 14.5898V10.0234H1.41016C1.18361 10.0234 1 9.83983 1 9.61328V1.41016C1 1.18361 1.18361 1 1.41016 1H9.61328C9.83983 1 10.0234 1.18361 10.0234 1.41016V5.97656H14.5898C14.8164 5.97656 15 6.16017 15 6.38672V14.5898C15 14.8164 14.8164 15 14.5898 15ZM6.79688 14.1797H14.1797V6.79688H9.61328C9.38673 6.79688 9.20312 6.61327 9.20312 6.38672V1.82031H1.82031V9.20312H6.38672C6.61327 9.20312 6.79688 9.38673 6.79688 9.61328V14.1797Z" fill="#444754" stroke="transparent"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMerge'
})
</script>

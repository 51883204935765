
export const INTERVALS = ['1d', '1h', '5m'];
export const INTERVAL_MAP = {
    '1d': 86400,
    '1h': 3600,
    '5m': 300,
};

export const LENGTH_KLINE = 360;
export const SAFELIMITER_SAFERANGE = 30;
export const ORDER_STRAEGY_VALID_LENGTH = 120;

export function isMobile() {
    return window.innerWidth <= 768;
}

export function isLandscape() {
    return window.innerWidth > window.innerHeight;
}

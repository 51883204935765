<template>
  <g>
    <path d="M8.1246 0H42.6415L60.937 18.2244V60.9375C60.937 63.182 59.1171 65 56.8746 65H8.1246C5.88203 65 4.06213 63.182 4.06213 60.9375V4.06246C4.06213 1.81801 5.88224 0 8.1246 0Z" fill="#AAB1C5"/>
    <path d="M60.8786 18.2812H46.7187C44.4762 18.2812 42.6562 16.4613 42.6562 14.2187V0.0405884L60.8786 18.2812Z" fill="#444754"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFileDefault'
})
</script>

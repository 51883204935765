var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field",class:{
  'is-inline': _vm.inline,
  'is-bordered': _vm.border,
  'is-disabled': _vm.disabled,
  'has-addon': _vm.$slots.addon
}},[(_vm.label)?_c('label',{staticClass:"label",attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"control",class:{
    'has-icon-left': _vm.hasIconLeft,
    'has-icon-right': _vm.hasIconRight,
    'justify-start': _vm.align === 'left',
    'justify-end': _vm.align === 'right',
    'justify-center': _vm.align === 'center'
  }},[_vm._t("default")],2),(_vm.$slots.addon)?_c('div',{staticClass:"control-addon"},[_vm._t("addon")],2):_vm._e(),_vm._t("feedback")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="visible" class="alert shadow" :class="['alert-' + value.type]" @dblclick="close">
    <div class="w-full">
      <div class="flex justify-between">
        <div class="flex items-center">
          <span v-if="!value.noicon" class="mr-1">
            <legacy-icon name="warning" size="1.5em"></legacy-icon>
          </span>
          <p>{{ value.message }}.</p>
        </div>
        <button v-if="value.action" class="text-sm font-bold mx-2" @click="actionCB">
          {{ value.action  }}
        </button>
      </div>

      <ul v-if="value.fields">
        <li v-for="(field, key) in value.fields" :key="key">
          {{ field }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertWithAction',
  props: {
    value: {
      type: Object || null,
      default: null,
    },
  },
  computed: {
    visible: function () {
      return !!this.value;
    },
  },
  methods: {
    close: function () {
      this.$emit('input', null);
    },
    actionCB: function () {
      this.$emit('actionCB', this.value.action);
    },
  },
};
</script>

<style lang="postcss" scoped>
.alert {
  line-height: 1.5;
}

.alert .message {
  @apply: flex flex-row;
  justify-content: space-between;
}

.alert ul {
  list-style-type: disc;
  margin: 0 1rem 0.3rem 2.8rem;
}
</style>

<template>
  <g>
    <path d="M6.66667 2H2V6.66667H6.66667V2Z" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M14.0002 2H9.3335V6.66667H14.0002V2Z" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M14.0002 9.3335H9.3335V14.0002H14.0002V9.3335Z" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M6.66667 9.3335H2V14.0002H6.66667V9.3335Z" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconGrid'
})
</script>

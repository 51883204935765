import DefaultIcon from './DefaultIcon.vue';

const manifest = {
  mono: {},
  color: {}
};

const requireComponent = require.context('./', true, /[A-Z]\w+\.(vue)$/, 'lazy');
requireComponent.keys().forEach(filePath => {
  const [, type, filename] = filePath.split('/');
  if (!filename) return;

  const name = filename.replace(/\.\w+$/, '');

  manifest[type][name] = () => ({
    component: requireComponent(filePath),
    loading: DefaultIcon,
    error: DefaultIcon
  });
});

export default manifest;

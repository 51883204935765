<template>
  <g>
    <path d="M21.3332 21.3333L15.9998 16L10.6665 21.3333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 16V28" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.1866 24.52C28.4871 23.811 29.5144 22.6892 30.1064 21.3315C30.6985 19.9738 30.8216 18.4576 30.4562 17.0222C30.0909 15.5869 29.2579 14.314 28.0888 13.4046C26.9198 12.4952 25.4811 12.001 23.9999 12H22.3199C21.9164 10.439 21.1642 8.98977 20.1199 7.76131C19.0756 6.53286 17.7664 5.55712 16.2907 4.90747C14.8151 4.25781 13.2113 3.95113 11.6001 4.0105C9.98886 4.06986 8.41204 4.49372 6.98818 5.25021C5.56433 6.0067 4.3305 7.07613 3.37944 8.3781C2.42839 9.68007 1.78487 11.1807 1.49725 12.7672C1.20964 14.3537 1.28542 15.9847 1.7189 17.5377C2.15238 19.0906 2.93227 20.5251 3.99995 21.7333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.3332 21.3333L15.9998 16L10.6665 21.3333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUpload'
})
</script>

<template>
  <g stroke="none">
    <path
      d="M7.09187 19.338L5.978 23.4963L1.90681 23.5824C0.690125 21.3257 0 18.7438 0 16C0 13.3468 0.64525 10.8448 1.789 8.64175H1.78987L5.41437 9.30625L7.00212 12.909C6.66981 13.8778 6.48869 14.9178 6.48869 16C6.48881 17.1745 6.70156 18.2998 7.09187 19.338Z"
      fill="#FBBB00"
    />
    <path
      d="M31.7202 13.011C31.904 13.9788 31.9998 14.9784 31.9998 16C31.9998 17.1455 31.8794 18.2628 31.6499 19.3407C30.8711 23.0083 28.8359 26.211 26.0166 28.4773L26.0157 28.4765L21.4504 28.2435L20.8043 24.2101C22.6751 23.113 24.1371 21.396 24.9072 19.3407H16.3516V13.011H25.032H31.7202Z"
      fill="#518EF8"
    />
    <path
      d="M26.0157 28.4765L26.0166 28.4774C23.2747 30.6813 19.7915 32 15.9999 32C9.90674 32 4.60918 28.5943 1.90674 23.5824L7.0918 19.338C8.44299 22.9442 11.9217 25.5112 15.9999 25.5112C17.7529 25.5112 19.3951 25.0374 20.8043 24.2101L26.0157 28.4765Z"
      fill="#28B446"
    />
    <path
      d="M26.2128 3.6835L21.0295 7.927C19.5711 7.01537 17.8471 6.48875 16.0001 6.48875C11.8295 6.48875 8.28575 9.17356 7.00225 12.909L1.78994 8.64175H1.78906C4.45194 3.50769 9.81631 0 16.0001 0C19.8823 0 23.4418 1.38287 26.2128 3.6835Z"
      fill="#F14336"
    />
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconGoogle'
})
</script>

<template>
  <g>
    <path
      d='M15.9993 29.3326C23.3632 29.3326 29.3326 23.3632 29.3326 15.9993C29.3326 8.63556 23.3632 2.66602 15.9993 2.66602C8.63556 2.66602 2.66602 8.63556 2.66602 15.9993C2.66602 23.3632 8.63556 29.3326 15.9993 29.3326Z'
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M21.6568 16.0006H10.3431'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </g>
</template>

<script lang='ts'>
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRemoveCircle'
})
</script>

<template>
  <svg>
    <path d="M10 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H10" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66699 11.3332L10.0003 7.99984L6.66699 4.6665" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 8H2" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLogout'
})
</script>

<template>
  <g>
    <path d="M3 1.5H13C13.8284 1.5 14.5 2.17157 14.5 3V4V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H3C2.17157 14.5 1.5 13.8284 1.5 13V8V3C1.5 2.17157 2.17157 1.5 3 1.5Z" fill="#DEFFD9" stroke="#2C2B35"/>
    <path d="M8 3.91699V12.0837" stroke="#219653" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.91699 8H12.0837" stroke="#219653" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconAddGreen'
})
</script>

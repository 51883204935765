<template>
  <g fill="none">
    <path d="M19 14.9999V6.9999C18.9996 6.64918 18.9071 6.30471 18.7315 6.00106C18.556 5.69742 18.3037 5.44526 18 5.2699L11 1.2699C10.696 1.09437 10.3511 1.00195 10 1.00195C9.64893 1.00195 9.30404 1.09437 9 1.2699L2 5.2699C1.69626 5.44526 1.44398 5.69742 1.26846 6.00106C1.09294 6.30471 1.00036 6.64918 1 6.9999V14.9999C1.00036 15.3506 1.09294 15.6951 1.26846 15.9987C1.44398 16.3024 1.69626 16.5545 2 16.7299L9 20.7299C9.30404 20.9054 9.64893 20.9979 10 20.9979C10.3511 20.9979 10.696 20.9054 11 20.7299L18 16.7299C18.3037 16.5545 18.556 16.3024 18.7315 15.9987C18.9071 15.6951 18.9996 15.3506 19 14.9999Z" stroke="#AAB1C5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.27002 5.95996L10 11.01L18.73 5.95996" stroke="#AAB1C5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 21.08V11" stroke="#AAB1C5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconSpace'
})
</script>

<template>
  <g>
    <path
      opacity="0.15"
      d="M24 4.79999H7.20005C5.87457 4.79999 4.80005 5.8745 4.80005 7.19999V24C4.80005 25.3255 5.87457 26.4 7.20005 26.4H24C25.3255 26.4 26.4 25.3255 26.4 24V7.19999C26.4 5.8745 25.3255 4.79999 24 4.79999Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.6 10.8V15.6M15.6 15.6V20.4M15.6 15.6H10.8M15.6 15.6H20.4M7.20005 4.79999H24C25.3255 4.79999 26.4 5.8745 26.4 7.19999V24C26.4 25.3255 25.3255 26.4 24 26.4H7.20005C5.87457 26.4 4.80005 25.3255 4.80005 24V7.19999C4.80005 5.8745 5.87457 4.79999 7.20005 4.79999Z"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconAdd'
})
</script>

<template>
  <main class="home-root">
    <v-alert v-model="alert"></v-alert>
    <v-alert v-model="alertEmail" @actionCB="resendEmail"></v-alert>
    <v-alert v-model="alertTelegram" @actionCB="setupTelegram"></v-alert>
    <Summary @symbolChanged="symbolChanged" />
    <div class="flex flex-wrap">
      <div class="chart" @dblclick="refreshChart" @click="clickChartToSelect">
        <trading-vue ref="tradingVue" :data="ohlcv_computed" :key="resetkey" :titleTxt="'TTM'" :width="this.width"
          :height="this.height" :toolbar="false" :chart-config="{ TB_ICON_BRI: 1.25, DEFAULT_LEN: this.length }"
          :color-back="colors.colorBack" :color-grid="colors.colorGrid" :color-text="colors.colorText"
          :overlays="overlays_cal">
        </trading-vue>
        <div class="chart-intervals">
          <div v-for="interval in intervals" :key="interval">
            <button class="interval" :class="{ 'selected': selectedInterval === interval }"
              @click.prevent="clickInterval(interval)">{{ interval }}
            </button>
          </div>
        </div>

        <div v-if="fullScreen" class="fscreen-control-pannel">
          <full-screen-nav></full-screen-nav>
        </div>
        <div v-if="!fullScreen" class="fscreen-control-pannel">
          <full-screen-expand @enableFullScreen="enableFullScreen"></full-screen-expand>
        </div>
        <div v-if="loading" class="flex flex-col opacity-50">
          <div class=" fixed top-1/2 self-center">
            <legacy-icon class="icon-loading" name="loading" size="3em" viewbox="100"></legacy-icon>
            <p>Loading...</p>
          </div>
        </div>
      </div>
      <div v-if="!fullScreen" class="w-80 ml-12">
        <h6>Track your preferred cryptocurrencies with predefined strategies.</h6>
        <entry-strategy id="entry_strategy" :isExpanded="expanded_id === 'entry_strategy'"
          @toggleExpand="toggleExpand('entry_strategy')"></entry-strategy>
        <h6>Monitor the market for precise buy/sell orders.</h6>
        <order-monitor id="order_monitor" :isExpanded="expanded_id === 'order_monitor'"
          @toggleExpand="toggleExpand('order_monitor')"></order-monitor>
        <h6>Safeguard your positions and receive alerts.</h6>
        <open-pos-safeguarder id="open_pos_safeguarder" :isExpanded="expanded_id === 'open_pos_safeguarder'"
          @toggleExpand="toggleExpand('open_pos_safeguarder')"></open-pos-safeguarder>
      </div>
    </div>
  </main>
</template>

<script>
//import { ref } from 'vue';
import { TradingVue } from 'trading-vue-js'
import Summary from '@/components/Summary'
import EntryStrategy from '@/components/EntryStrategy'
import OrderMonitor from '@/components/OrderMonitor'
import OpenPosSafeguarder from '@/components/OpenPosSafeguarder'
import FullScreenNav from '@/components/FullScreenNav'
import FullScreenExpand from '@/components/FullScreenExpand'
import VAlert from '@/components/AlertWithAction.vue'

import ChartMixin from '@/mixins/ChartMixin'
import AlertMixin from '@/mixins/AlertMixin'
//import { meta } from 'vue-meta';
//import { id } from 'date-fns/locale'

export default {
  name: 'DesktopMainPage',
  metaInfo: {
    title: 'Real-time alerts on cryptos based on optimized strategies', // Title of your page
    meta: [
      {
        name: 'description',
        content: 'Gain a competitive edge in cryptocurrency trading by monitoring top assets like Bitcoin (BTC) and Ethereum (ETH) with our platform. Utilize advanced technical indicators such as EMA, RSI, and MACD to tailor your alerts and trading strategy. Receive real-time alerts directly to your preferred device, ensuring you never miss out on key market movements. With comprehensive analytics and historical data at your fingertips, capitalize on opportunities and stay ahead of the curve in the dynamic world of cryptocurrency trading.' // Description of your page
      },
      {
        name: 'keywords',
        content: 'real-time alerts on cryptos, crypto price alerts, cryptocurrency alerting, track cryptocurrency, bitcoin price alert, etherum price alert, crypto tracker, real-time crypto tracker' // Keywords relevant to your page
      },
      // You can add more meta tags as needed
    ]
  },
  components: {
    VAlert,
    TradingVue,
    Summary,
    EntryStrategy,
    OrderMonitor,
    OpenPosSafeguarder,
    FullScreenNav,
    FullScreenExpand,
  },
  mixins: [ChartMixin, AlertMixin],
  computed: {
    loading() {
      return this.$store.state.chart.isLoading
    },
  }
}
</script>

<style lang='postcss' scoped>
.home-root {
  width: 100%;
  background-color: #fff;

  .chart {
    @apply relative mt-2;
    border-top: 1px solid #eee;

    .chart-intervals {
      @apply absolute flex flex-row;
      top: 10px;
      right: 90px;
    }

    .interval {
      @apply w-8 h-5 shadow bg-gray-100;
      border: 1px solid #eee;
      border-radius: 1px;
      cursor: pointer;
      text-align: center;
      font: 11px;

      &:hover {
        @apply bg-gray-400;
      }
    }

    .fscreen-control-pannel {
      @apply absolute flex flex-col;
      bottom: 25px;
      left: 3%;
    }

    .navigator {
      @apply w-8 h-5 shadow bg-gray-100;
      border: 1px solid #eee;
      border-radius: 1px;
      cursor: pointer;
      text-align: center;
      font: 11px;

      &:hover {
        @apply bg-gray-400;
      }
    }

    .selected {
      @apply bg-gray-400;
    }
  }

  h6 {
    font-size: 11px;
    color: #ccc;
    margin: 5px 0 0 0;
  }
}
</style>

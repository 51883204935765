<template>
  <div v-if="visible" class="alert" :class="['alert-' + value.type]" @click="close">
    <div>
      <div class="message">
        <span v-if="!value.noicon" class="mr-1">
          <legacy-icon name="warning" size="1.5em"></legacy-icon>
        </span>
        <p>{{ value.message }}.</p>
      </div>

      <ul v-if="value.fields">
        <li v-for="(field, key) in value.fields" :key="key">
          {{ field }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertComponent',
  props: {
    value: {
      type: Object || null,
      default: null,
    },
  },
  computed: {
    visible: function () {
      return !!this.value;
    },
  },
  methods: {
    close: function () {
      this.$emit('input', null);
    },
  },
};
</script>

<style scoped>
.alert {
  line-height: 1.5;
}

.alert .message {
  display: flex;
  flex-direction: row;
}

.alert ul {
  list-style-type: disc;
  margin: 0 1rem 0.3rem 2.8rem;
}
</style>

<template>
  <g>
    <path d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 9.99999C16.6667 9.53975 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53975 15 9.99999C15 10.4602 15.3731 10.8333 15.8333 10.8333Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.16671 10.8333C4.62694 10.8333 5.00004 10.4602 5.00004 9.99999C5.00004 9.53975 4.62694 9.16666 4.16671 9.16666C3.70647 9.16666 3.33337 9.53975 3.33337 9.99999C3.33337 10.4602 3.70647 10.8333 4.16671 10.8333Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconContextMenu'
})
</script>

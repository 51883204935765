import axios from 'axios';

var BTChartModule = {
  namespaced: true,
  state: function () {
    return {
      selectedSymbol: 'BTCUSD',
      selectedInterval: '1d',
      lastClickedInfo: null,
      klines: null,
      cachedKlines: null, // for caching klines  {symbol: {klines: klines, lastUpdated: timestamp}}
      currentPrice: null,
      backtestedStrategies: [],
      activeStrategy: null,
      strategyResults: null,
      cachedStrategyResults: null, // for caching strategy results {strategy_id: {results: results, lastUpdated: timestamp}}
      safeLimiter: null,
      cachedSafeLimiter: null, // for caching safe limiter {symbol: {safeLimiter: safeLimiter, lastUpdated: timestamp}}
      isLoading: false,
    }
  },

  getters: {
    infoChart: state => state.data,
  },

  actions: {
    async getKlinesFromCache({state}, {symbol}) {

      if (state.cachedKlines === null) {
        return null;
      }
      if (state.cachedKlines[symbol] === undefined) {
        return null;
      }
      let curTimeUTC = new Date().getTime();

      // if the cached klines is older than 5 minutes, return null
      if (curTimeUTC - state.cachedKlines[symbol].lastUpdated > 1000 * 60 * 60 * 24) {
        return null;
      }
      return state.cachedKlines[symbol].klines;
    },
    async updateChartUponSymbolChange({commit, dispatch, state}, symbol) {
      console.log(symbol);
      if (symbol === null) {
        return;
      }
      if (state.selectedSymbol === symbol && state.klines !== null) {
        return;
      }
      commit('updateSelectedSymbol', symbol);

      // check if the klines is in cache
      let klines = await dispatch('getKlinesFromCache', {symbol: symbol})
      if (klines !== null) {
        commit('updateChartKlines', klines);
      } else {
        // if not in cache, get the klines from server
        state.isLoading = true;
        let url = '/backtest/klines/' + symbol;
        axios.get(url)
        .then( response => {
          let klines = response.data;
          commit('updateChartKlines', klines);
          commit('updateCachedKlines', {symbol: symbol, klines: klines});
          state.isLoading = false;
        }) 
        .catch (error => {
          console.log(error);
          throw error;
        })
      }

      //commit('updateActiveStrategy', null);
      //commit('clearStrategyResults', null);
      // if there is an selected strategy, get the results
      if (state.activeStrategy !== null) {
        await dispatch('getStrategyResults', state.activeStrategy);
      }
    },
    async getStrategyResultsFromCache({state}, strategy) {
      if (state.cachedStrategyResults === null) {
        return null;
      }
      if (strategy.id === undefined || state.cachedStrategyResults[strategy.id] === undefined) {
        return null;
      }
      // if the cached strategy results is older than 2.5 minutes, return null
      let curTimeUTC = new Date().getTime();
      if (curTimeUTC - state.cachedStrategyResults[strategy.id].lastUpdated > 1000 * 60 * 60 * 24) {
        return null;
      }
      return state.cachedStrategyResults[strategy.id].results;
    },
    async getStrategyResults({dispatch, commit, state}, strategy) {
      if (state.selectedSymbol === null 
        || state.selectedInterval === null || strategy === null) {
        commit('clearStrategyResults', null);
        return;
      }

      // check if the strategy results is in cache
      let results = await dispatch('getStrategyResultsFromCache', strategy);
      if (results !== null) {
        commit('updateStrategyResults', {results: results});
      } else {
        // if not in cache, get the strategy results from server
        state.isLoading = true;
        //const side = strategy.side === undefined ? '' : strategy.side;
        axios.get('/backtest/strategy/results', {
          params: {
            symbol: state.selectedSymbol,
            strategy_id: strategy.temp_id,
          }
        })
        .then( response => {
          let results = response.data;
          commit('updateStrategyResults', {results: results});
          if (strategy.id !== undefined) {
            commit('updateCachedStrategyResults', {strategy: strategy, results: results});
          }

          state.isLoading = false;
        }) 
        .catch (error => {
          console.log(error);
          throw error;
        })
      }
    },
    async getSafeLimiterFromCache({state}, symbol) {
      if (state.cachedSafeLimiter === null) {
        return null;
      }
      if (symbol === null || state.cachedSafeLimiter[symbol] === undefined) {
        return null;
      }
      // if the cached safe limiter is older than 2.5 minutes, return null
      let curTimeUTC = new Date().getTime();
      if (curTimeUTC - state.cachedSafeLimiter[symbol].lastUpdated > 1000 * 60 * 2.5) {
        return null;
      }
      return state.cachedSafeLimiter[symbol].safeLimiter;
    },
    async updateSafeLimiter({dispatch, commit, state}, {openpos, useCachedData}) {
      // useCachedData is a boolean value, if false, do not use the cached safe limiter (when the user clicks the refresh button);
      // if true, use the cached safe limiter (when the user changes the symbol or interval)
      if (state.selectedSymbol === null 
        || state.selectedInterval === null || openpos === null) {
        return;
      }
      // check if the safe limiter is in cache
      let safeLimiter = await dispatch('getSafeLimiterFromCache', state.selectedSymbol);
      if (useCachedData && safeLimiter !== null) {
        commit('updateSafeLimiter', safeLimiter);
      } else {
        // if not in cache, get the safe limiter from server
        state.isLoading = true;
        axios.post('/openpos/safelimiter', {
          symbol: state.selectedSymbol,
          interval: state.selectedInterval,
          time: openpos.time,
          price: openpos.price,
          factor: openpos.factor,
          reference: openpos.reference,
          tolerance: openpos.tolerance
        })
        .then( response => {
          let safeLimiter = response.data;
          commit('updateSafeLimiter', safeLimiter);
          if (useCachedData) {
            commit('updateCachedSafeLimiter', {symbol: state.selectedSymbol, safeLimiter: safeLimiter});
          }
          state.isLoading = false;
        }) 
        .catch (error => {
          console.log(error);
          throw error;
        })
      }
    },
    async getBacktestStrategyInfo({commit, state}) {

      if (state.selectedSymbol === null || state.selectedInterval === null) {
        return;
      }

      axios.get('/backtest/strategy/infos', {
        params: {
          symbol: state.selectedSymbol,
          interval: state.selectedInterval
        }
      })
      .then( response => {
        let infos = response.data;
        var btStrategies = [];
        for (let i = 0; i < infos.length; i++) {
          let info = infos[i];
          let btStrategy = {
            id: state.selectedSymbol+'_'+state.selectedInterval+'_'+info.id,
            name: info.name === undefined ? info.template_id : info.name,
            symbol: state.selectedSymbol,
            interval: state.selectedInterval,
            type: 'st_entry',
            temp_id: info.id,
            temp_name: info.template_id,
            config: info.config,
          };
          btStrategies.push(btStrategy);
        }
        btStrategies.sort((a, b) => (a.name > b.name) ? 1 : -1);
        commit('updateBacktestStrategies', btStrategies);
      })
      .catch (error => {
        console.log(error);
        throw error;
      })
    },

    async setSelectedInterval({commit}, interval) {
      commit('updateSelectedInterval', interval);
      commit('clearActiveStrategy');
      commit('clearStrategyResults');
    },
    async setLastClickedInfo({commit}, lastClickedInfo) {
      commit('updateLastClickedInfo', lastClickedInfo);
    },
    async setActiveStrategy({commit, dispatch}, strategy) {
      commit('updateActiveStrategy', strategy);
      await dispatch('getStrategyResults', strategy);
    },
    async clearChart({commit}) {
      let klines = null;
      commit('updateChartData', klines);
    }
  },
  mutations: {
    updateSelectedSymbol(state, symbol) {
      state.selectedSymbol = symbol;
    },
    updateSelectedInterval(state, interval) {
      state.selectedInterval = interval;
    },
    updateLastClickedInfo(state, lastClickedInfo) {
      state.lastClickedInfo = lastClickedInfo;
    },
    updateChartKlines(state, klines) {
      state.klines = klines;

      //if (state.klines !== null) {
        //let nKlines = state.klines[state.selectedInterval].length;
        //state.currentPrice = state.klines[state.selectedInterval][nKlines - 1][4];
      //}else{
        //state.currentPrice = null;
      //}
    },
    updateCachedKlines(state, {symbol, klines}) {
      if (symbol === null || klines === null) {
        return;
      }
      if (state.cachedKlines === null) {
        state.cachedKlines = {};
      }
      state.cachedKlines[symbol] = {
        klines: klines,
        lastUpdated: new Date().getTime()
      };
    },
    clearCachedKlines(state) {
      state.cachedKlines = null;
    },
    updateBacktestStrategies(state, info) {
      state.backtestedStrategies = info;
    },
    clearBacktestStrategyInfo(state) {
      state.backtestedStrategies = [];
    },
    updateActiveStrategy(state, strategy) {
      state.activeStrategy = strategy;
    },
    clearActiveStrategy(state) {
      state.activeStrategy = null;
    },
    updateStrategyResults(state, {results}) {
      state.strategyResults = results;
    },
    clearStrategyResults(state) {
      state.strategyResults = null;
    },
    updateCachedStrategyResults(state, {strategy, results}) {
      if (strategy === null || results === null) {
        return;
      }
      if (state.cachedStrategyResults === null) {
        state.cachedStrategyResults = {};
      }
      state.cachedStrategyResults[strategy.id] = {
        results: results,
        lastUpdated: new Date().getTime()
      };
    },
    clearCachedStrategyResults(state) {
      state.cachedStrategyResults = null;
    },
    updateSafeLimiter(state, safeLimiter) {
      state.safeLimiter = safeLimiter;
    },
    clearSafeLimiter(state) {
      state.safeLimiter = null;
    },
    updateCachedSafeLimiter(state, {symbol, safeLimiter}) {
      if (symbol === null || safeLimiter === null) {
        return;
      }
      if (state.cachedSafeLimiter === null) {
        state.cachedSafeLimiter = {};
      }
      state.cachedSafeLimiter[symbol] = {
        safeLimiter: safeLimiter,
        lastUpdated: new Date().getTime()
      };
    },
    deleteCachedSafeLimiter(state, symbol) {
      if (symbol === null) {
        return;
      }
      if (state.cachedSafeLimiter === null) {
        return;
      }
      if (state.cachedSafeLimiter[symbol] === undefined) {
        return;
      }
      delete state.cachedSafeLimiter[symbol];
    },
    clearCachedSafeLimiter(state) {
      state.cachedSafeLimiter = null;
    }

  }
};

export default BTChartModule;
<template>
  <g>
    <path
      d="M13.747 17.1275C14.2307 17.7748 14.8479 18.3104 15.5566 18.698C16.2653 19.0856 17.0489 19.3161 17.8544 19.3738C18.6599 19.4316 19.4684 19.3152 20.2251 19.0327C20.9817 18.7501 21.6688 18.308 22.2397 17.7363L25.6188 14.3538C26.6447 13.2906 27.2123 11.8666 27.1995 10.3885C27.1867 8.91033 26.5944 7.49639 25.5502 6.45116C24.506 5.40593 23.0935 4.81305 21.6169 4.8002C20.1402 4.78736 18.7176 5.35558 17.6555 6.38249L15.7181 8.3105M18.2525 14.8725C17.7688 14.2252 17.1516 13.6896 16.4429 13.302C15.7343 12.9144 14.9506 12.6839 14.1451 12.6262C13.3396 12.5684 12.5311 12.6848 11.7744 12.9673C11.0178 13.2498 10.3307 13.6919 9.75978 14.2636L6.38071 17.6461C5.35484 18.7094 4.78719 20.1334 4.80002 21.6115C4.81285 23.0896 5.40514 24.5036 6.44931 25.5488C7.49349 26.594 8.90601 27.1869 10.3826 27.1998C11.8593 27.2126 13.2819 26.6444 14.344 25.6175L16.2701 23.6895"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLink'
})
</script>

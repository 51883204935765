import { DataCube } from "trading-vue-js";
import { EMA, RSI, MACD, BollingerBands } from "technicalindicators";
//import XP from 'tvjs-xp'
//import Overlays from 'tvjs-overlays'
import MACD_ from "@/overlays/MACD";
import BB_ from "@/overlays/BB";
import ShapeCircle from "@/overlays/ShapeCircle";
import ShapeSquare from "@/overlays/ShapeSquare";

import { getAggregatedStrategyParams } from "@/utils/strategy";
import { LENGTH_KLINE } from "@/utils/constants";

export default {
  setup() {
    return {
      intervals: ["1d", "1h", "5m"],
      ema_colors: ["#ff0000", "#009900", "#0000ff"],
      tf: {
        "1d": 86400000,
        "2h": 7200000,
        "1h": 3600000,
        "15m": 900000,
        "5m": 300000,
      },
      subPanelIds: ["entry_strategy", "order_monitor", "open_pos_safeguarder", "market_news"],
    };
  },
  data() {
    return {
      width: window.innerWidth * 0.76,
      height: window.innerHeight * 0.85,
      length:
        window.innerWidth < 768
          ? window.innerHeight > window.innerWidth
            ? 80
            : 160
          : 300,

      expanded_id: "entry_strategy",
      selectedInterval: "1d",
      resetkey: 0,
      ema_periods: [7, 25, 99],
      ema_show: true,
      bb_params: [7, 2],
      rsi_bound: [30, 70],
      rsi_period: 14,
      macd_periods: [12, 26, 9],
    };
  },
  computed: {
    symbolInQuery() {
        return this.$route.query.symbol;
    },
    intervalInQuery() {
        return this.$route.query.interval;
    },
    strategyTypeInQuery() {
        return this.$route.query.strategy_type;
    },
    strategyIdInQuery() {
        return this.$route.query.strategy_id;
    },
    numSubPanels() {
      return (this.$store.state.user.user !== null && this.$store.state.user.subscriptionInfo.type === 'pro') ? 4 : 3
    },
    activeStrategy() {
        return this.$store.state.chart.activeStrategy;
    },
    strategyTemps() {
        return this.$store.state.appinfo.entryStrategyTemp; // TODO: change to strategyTemps to include orderStrategyTemp
    },
    colors() {
      return {
        colorBack: "#fff",
        colorGrid: "#eee",
        colorText: "#333",
      };
    },
    fullScreen: function () {
      return this.$store.state.appinfo.fullScreenMode;
    },

    selectedIntervalInStore() {
      return this.$store.state.chart.selectedInterval;
    },
    isExpanded() {
        return this.expanded_id === 'entry_strategy' || this.expanded_id === 'order_monitor' || this.expanded_id === 'open_pos_safeguarder'
    },
    ohlcv_computed() {
      let chart_state = this.$store.state.chart;
      if (chart_state.klines === null) {
        return { ohlcv: [] };
      }

      let ohlcv = [];
      let rsi14 = [];
      let macd = [];
      let macd_lower = 0.0;
      let macd_upper = 0.0;
      let ema_overlays = [];
      let bb_overlay = null;
      let strategy_results_overlay = [];
      let safe_limiter_overlay = null;

      ohlcv = chart_state.klines[this.selectedInterval];
      let data = ohlcv;
      const closeData = [];
      data.forEach((d) => closeData.push(d[4]));

      if (this.ema_show) {
        for (let i = 0; i < this.ema_periods.length; i++) {
          let period = this.ema_periods[i];
          let ema = this.calcEMAForChart(data, closeData, period);
          ema_overlays.push({
            name: "EMA " + period,
            type: "EMA",
            data: ema,
            settings: {
              lineWidth: 0.75,
              color: this.ema_colors[i],
            },
          });
        }
      }
      bb_overlay = {
        name: "BB " + this.bb_params[0] + "," + this.bb_params[1],
        type: "Channel",
        data: this.calcBBForChart(
          data,
          closeData,
          this.bb_params[0],
          this.bb_params[1]
        ),
        settings: {
          lineWidth: 0.6,
          color: "#2cc6c9ab",
          showMid: false,
          backColor: "#2cc6c90a",
        },
      };
      rsi14 = this.calcRSIForChart(data, closeData, this.rsi_period);
      [macd, macd_lower, macd_upper] = this.calcMACDForChart(
        data,
        closeData,
        this.macd_periods[0],
        this.macd_periods[1],
        this.macd_periods[2]
      );
      console.log(macd_lower, macd_upper);

      let strategy_results = chart_state.strategyResults;
      if (strategy_results !== null && chart_state.activeStrategy !== null) {
        let shapeType = chart_state.activeStrategy.type === "st_order" ? "ShapeSquare" : "ShapeCircle";
        strategy_results_overlay.push({
            name: chart_state.activeStrategy.temp_name,
            type: shapeType,
            data: this.calcEntryResultsForChart(
            strategy_results,
            data[0][0],
            data[data.length - 1][0]
            ),
            settings: {
            "z-index": 1,
            buyColor: "#00ff00",
            sellColor: "#FFFF00",
            markerSize: this.isMobile() ? 2.5 : 3,
            },
        });
        strategy_results_overlay.push({
            name: chart_state.activeStrategy.temp_name,
            type: shapeType,
            data: this.calcExitResultsForChart(
                strategy_results,
                data[0][0],
                data[data.length - 1][0]
            ),
            settings: {
                "z-index": 1,
                legend: false,
                buyColor: "#00ff00",
                sellColor: "#FFFF00",
                markerSize: this.isMobile() ? 2.5 : 3,
            },
        });
      }

      let safe_limiter = chart_state.safeLimiter;
      if (safe_limiter !== null) {
        safe_limiter_overlay = {
          name: "Safeguard",
          type: "Spline",
          data: safe_limiter,
          settings: {
            "z-index": 1,
            lineWidth: 2.0,
            color: "#FF8C00",
          },
        };

        strategy_results_overlay.push(safe_limiter_overlay);
      }
      console.log(safe_limiter_overlay);
      //console.log(ohlcv)
      //return { 'ohlcv': ohlcv }
      return new DataCube({
        chart: {
          type: "Candles",
          data: ohlcv,
          tf: this.tf[this.selectedInterval],
          settings: {
            upColor: "#00ff00",
            downColor: "#ff0000",
            wickColor: "#000000",
            borderVisible: false,
            borderColor: "#000000",
            wickVisible: true,
            borderUpColor: "#00ff00",
            borderDownColor: "#ff00",
          },
        },
        onchart: [...ema_overlays, bb_overlay, ...strategy_results_overlay],
        offchart: [
          {
            name: "RSI " + this.rsi_period,
            type: "RSI",
            data: rsi14,
            settings: {
              upper: this.rsi_bound[1],
              lower: this.rsi_bound[0],
              backColor: "#9b9ba316",
              bandColor: "#666",
            },
          },
          {
            name:
              "MACD " +
              this.macd_periods[0] +
              "," +
              this.macd_periods[1] +
              "," +
              this.macd_periods[2],
            type: "MACD",
            data: macd,
            settings: {
              upper: macd_upper * 1.1,
              lower: macd_lower * 1.1,
              display: true,
            },
          },
        ],
      });
    },
    overlays_cal() {
      return [MACD_, BB_, ShapeCircle, ShapeSquare];
      //return [Stoch, GRIN]
    },
  },
  methods: {
    isMobile() {
      return window.innerWidth < 768;
    },
    handleResize() {
      if (this.fullScreen || this.isMobile()) {
        this.width = window.innerWidth;
        this.height = window.innerHeight * 0.92;
      } else {
        this.width = window.innerWidth * 0.76;
        this.height = window.innerHeight * 0.85;
      }
    },
    enableFullScreen() {
        this.$store.commit("appinfo/setFullScreenMode", true);
        this.handleResize();
    },
    toggleExpand(id) {
      if (this.expanded_id === id) {
        this.expanded_id = "";
      } else {
        this.expanded_id = id;
      }
    },
    delay(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async clickInterval(interval) {
      this.selectedInterval = interval;
      this.$store.dispatch("chart/setSelectedInterval", interval);

      //this.$refs.tradingVue.resetChart();
      this.resetkey += 1;
    },
    clickChartToSelect() {
      if (window.openpos === undefined) {
        return;
      }
      let cursor = this.$refs.tradingVue.getCursor();
      if (
        cursor === null ||
        !(0 in cursor.values) ||
        cursor.values[0].ohlcv === undefined
      ) {
        return;
      }
      //console.log(cursor.values[0].ohlcv)
      this.$store.dispatch("chart/setLastClickedInfo", cursor.values[0].ohlcv);

      if (window.openpos.selectedTimeStatus === 1) {
        window.openpos.time = cursor.values[0].ohlcv[0];
        window.openpos.price = cursor.values[0].ohlcv[4];
      }
    },
    refreshChart() {
      this.$refs.tradingVue.resetChart();
    },
    async symbolChanged(symbol) {
      console.log("clickSymbol", symbol);
    },
    calcEMAForChart(data, close, period) {
      const emaData = new EMA.calculate({ period: period, values: close });
      let ema = [];
      data.forEach((d, i) => {
        const emai = i >= period - 1 ? emaData[i - period + 1] : NaN;
        ema.push([d[0], emai]);
      });
      return ema;
    },
    calcBBForChart(data, close, period, stdDev) {
      const bbData = new BollingerBands.calculate({
        period: period,
        stdDev: stdDev,
        values: close,
      });
      let bb = [];
      data.forEach((d, i) => {
        const bbu = i >= period - 1 ? bbData[i - period + 1].upper : NaN;
        const bbm = i >= period - 1 ? bbData[i - period + 1].middle : NaN;
        const bbl = i >= period - 1 ? bbData[i - period + 1].lower : NaN;
        bb.push([d[0], bbl, bbm, bbu]);
      });
      return bb;
    },
    calcRSIForChart(data, close, period) {
      const rsiData = new RSI.calculate({ period: period, values: close });
      let rsi = [];
      data.forEach((d, i) => {
        const rsi14 = i >= period ? rsiData[i - period] : NaN;
        rsi.push([d[0], rsi14]);
      });
      return rsi;
    },
    calcMACDForChart(data, close, fast = 12, slow = 26, smooth = 9) {
      let macdInput = {
        values: close,
        fastPeriod: fast,
        slowPeriod: slow,
        signalPeriod: smooth,
        SimpleMAOscillator: false,
        SimpleMASignal: false,
      };

      let macd = [];
      const macdData = new MACD.calculate(macdInput);
      const lastPart = macdData.slice(LENGTH_KLINE - this.length - slow);
      let macd_lower =  Math.min(...lastPart.map(x => Math.min(x.MACD, x.histogram)));
      let macd_upper = Math.max(...lastPart.map(x => Math.max(x.MACD, x.histogram)));
      let hist_pre = 0.0;
      data.forEach((d, i) => {
        const n = 25;
        if (i < n) {
          macd.push([d[0], NaN, NaN, NaN, NaN]);
        } else {
          let macdi =
            typeof macdData[i - n].MACD == "undefined"
              ? NaN
              : macdData[i - n].MACD;
          let signal =
            typeof macdData[i - n].signal == "undefined"
              ? NaN
              : macdData[i - n].signal;
          let hist =
            typeof macdData[i - n].histogram == "undefined"
              ? NaN
              : macdData[i - n].histogram;

          if (hist >= 0) {
            var color = 0;
            if (hist < hist_pre) color = 1;
          } else {
            color = 2;
            if (hist > hist_pre) color = 3;
          }
          hist_pre = hist;
          macd.push([d[0], hist, macdi, signal, color]);
        }
      });

      return [macd, macd_lower, macd_upper];
    },
    calcStrategyResultsForChart(strategy_results, tp, tq) {
      var combined_results = [];
      if ("entry" in strategy_results) {
        strategy_results.entry.time.forEach((t, i) => {
          if (t >= tp && t <= tq) {
            combined_results.push([t, 1, strategy_results.entry.price[i]]);
          }
        });
      }
      if ("exit" in strategy_results) {
        strategy_results.exit.time.forEach((t, i) => {
          if (t >= tp && t <= tq) {
            combined_results.push([t, 0, strategy_results.exit.price[i]]);
          }
        });
      }

      return combined_results;
    },
    calcEntryResultsForChart(strategy_results, tp, tq) {
        var combined_results = [];
        if ("entry" in strategy_results) {
          strategy_results.entry.time.forEach((t, i) => {
            if (t >= tp && t <= tq) {
              combined_results.push([t, 1, strategy_results.entry.price[i]]);
            }
          });
        }  
        return combined_results;
    },
    calcExitResultsForChart(strategy_results, tp, tq) {
        var combined_results = [];
        if ("exit" in strategy_results) {
          strategy_results.exit.time.forEach((t, i) => {
            if (t >= tp && t <= tq) {
              combined_results.push([t, 0, strategy_results.exit.price[i]]);
            }
          });
        }
  
        return combined_results;
    },
    ohlcv_computed_base(chart_state) {
      if (chart_state.klines === null) {
        return { ohlcv: [] };
      }

      let ohlcv = [];
      let rsi14 = [];
      let macd = [];
      let macd_lower = 0.0;
      let macd_upper = 0.0;
      let ema_overlays = [];
      let bb_overlay = null;
      let strategy_results_overlay = [];
      let safe_limiter_overlay = null;

      ohlcv = chart_state.klines[this.selectedInterval];
      let data = ohlcv;
      const closeData = [];
      data.forEach((d) => closeData.push(d[4]));

      if (this.ema_show) {
        for (let i = 0; i < this.ema_periods.length; i++) {
          let period = this.ema_periods[i];
          let ema = this.calcEMAForChart(data, closeData, period);
          ema_overlays.push({
            name: "EMA " + period,
            type: "EMA",
            data: ema,
            settings: {
              lineWidth: 0.75,
              color: this.ema_colors[i],
            },
          });
        }
      }
      bb_overlay = {
        name: "BB " + this.bb_params[0] + "," + this.bb_params[1],
        type: "Channel",
        data: this.calcBBForChart(
          data,
          closeData,
          this.bb_params[0],
          this.bb_params[1]
        ),
        settings: {
          lineWidth: 0.6,
          color: "#2cc6c9ab",
          showMid: false,
          backColor: "#2cc6c90a",
        },
      };
      rsi14 = this.calcRSIForChart(data, closeData, this.rsi_period);
      [macd, macd_lower, macd_upper] = this.calcMACDForChart(
        data,
        closeData,
        this.macd_periods[0],
        this.macd_periods[1],
        this.macd_periods[2]
      );
      console.log(macd_lower, macd_upper);

      let strategy_results = chart_state.strategyResults;
      if (strategy_results !== null && chart_state.activeStrategy !== null) {
        let shapeType = chart_state.activeStrategy.type === "st_order" ? "ShapeSquare" : "ShapeCircle";
        strategy_results_overlay.push({
            name: chart_state.activeStrategy.temp_name,
            type: shapeType,
            data: this.calcEntryResultsForChart(
            strategy_results,
            data[0][0],
            data[data.length - 1][0]
            ),
            settings: {
            "z-index": 1,
            buyColor: "#00ff00",
            sellColor: "#FFFF00",
            markerSize: this.isMobile() ? 2.5 : 3,
            },
        });
        strategy_results_overlay.push({
            name: chart_state.activeStrategy.temp_name,
            type: shapeType,
            data: this.calcExitResultsForChart(
                strategy_results,
                data[0][0],
                data[data.length - 1][0]
            ),
            settings: {
                "z-index": 1,
                legend: false,
                buyColor: "#00ff00",
                sellColor: "#FFFF00",
                markerSize: this.isMobile() ? 2.5 : 3,
            },
        });
      }

      let safe_limiter = chart_state.safeLimiter;
      if (safe_limiter !== null) {
        safe_limiter_overlay = {
          name: "Safeguard",
          type: "Spline",
          data: safe_limiter,
          settings: {
            "z-index": 1,
            lineWidth: 2.0,
            color: "#FF8C00",
          },
        };

        strategy_results_overlay.push(safe_limiter_overlay);
      }
      console.log(safe_limiter_overlay);
      //console.log(ohlcv)
      //return { 'ohlcv': ohlcv }
      return new DataCube({
        chart: {
          type: "Candles",
          data: ohlcv,
          tf: this.tf[this.selectedInterval],
          settings: {
            upColor: "#00ff00",
            downColor: "#ff0000",
            wickColor: "#000000",
            borderVisible: false,
            borderColor: "#000000",
            wickVisible: true,
            borderUpColor: "#00ff00",
            borderDownColor: "#ff00",
          },
        },
        onchart: [...ema_overlays, bb_overlay, ...strategy_results_overlay],
        offchart: [
          {
            name: "RSI " + this.rsi_period,
            type: "RSI",
            data: rsi14,
            settings: {
              upper: this.rsi_bound[1],
              lower: this.rsi_bound[0],
              backColor: "#9b9ba316",
              bandColor: "#666",
            },
          },
          {
            name:
              "MACD " +
              this.macd_periods[0] +
              "," +
              this.macd_periods[1] +
              "," +
              this.macd_periods[2],
            type: "MACD",
            data: macd,
            settings: {
              upper: macd_upper * 1.1,
              lower: macd_lower * 1.1,
              display: true,
            },
          },
        ],
      });
    },
    updateIndicatorParams(strategyParams) {
      this.ema_periods = strategyParams.ema_periods;
      this.ema_show = strategyParams.ema_show;
      this.bb_params = strategyParams.bb_params;
      this.rsi_bound = strategyParams.rsi_bound;
      this.rsi_period = strategyParams.rsi_period;
      this.macd_periods = strategyParams.macd_periods;
    },
  },
  watch: {
    fullScreen() {
      this.handleResize();
    },
    async ohlcv_computed() {
      this.resetkey += 1;
    },
    selectedIntervalInStore(val) {
      this.selectedInterval = val;
    },
    activeStrategy(strategy) {
      let strategyTemps = this.$store.state.appinfo.entryStrategyTemp;
      let strategyParams = getAggregatedStrategyParams(strategyTemps, strategy);
      this.updateIndicatorParams(strategyParams);
    },
    strategyTemps(strategyTemps) {
      let strategyParams = getAggregatedStrategyParams(strategyTemps, this.activeStrategy);
      this.updateIndicatorParams(strategyParams);
    }
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);

    //window.dc = this.$refs.tradingVue.dc;
    window.chart = this.$refs.tradingVue;
    this.handleResize();

    if (this.symbolInQuery !== undefined) {
      this.selectedSymbol = this.symbolInQuery;
      this.$store.commit("setSelectedSymbol", this.symbolInQuery);
    }
    if (this.intervalInQuery !== undefined) {
        this.selectedInterval = this.intervalInQuery;
        this.$store.commit("setSelectedInterval", this.intervalInQuery);
    }
    
    this.$store.dispatch("user/updateUserInfo", {user: this.$store.state.user.user});

    // Set a default strategy (order moniotr) to show on chart when user is not logged in,
    // but clear it when user is logged in.
    let user = this.$store.state.user
    if (user.user === null) {
        let strategy = {
            temp_id: "RSI_Inflection",
            temp_name: "RSI_Inflection",
            temp_type: "st_order",
            type: "st_order",
            side: 'buy',
            default: true
        }
        await this.$store.dispatch('chart/setActiveStrategy', strategy);
    } else {
      let strategy = this.$store.state.chart.activeStrategy
      if ('default' in strategy) {
        await this.$store.dispatch('chart/setActiveStrategy', null);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};

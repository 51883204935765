<template>
  <div id="app" class="app-container"> 
    <HeaderBlock v-if="!fullScreen"/>
    <div class="body-container">
      <router-view />
    </div>
    <FooterBlock v-if="!fullScreen"/>  
  </div>
</template> 

<script>
import HeaderBlock from './components/Header.vue'
import FooterBlock from './components/Footer.vue'

export default {
  name: 'App', 
  components: {
    HeaderBlock,
    FooterBlock,
  },
  computed: {
    fullScreen: function () {
      return this.$store.state.appinfo.fullScreenMode
    }
  },
  methods: {
  },
  async created() {
    await this.$store.dispatch('appinfo/getEntryStrategyTemp');
    await this.$store.dispatch('appinfo/getOrderStrategyTemp');
  },

}
</script>

<style lang="postcss">
@import './assets/css/index.css';
.app-container {
  width: 100%;
  max-width: 100%;
  /*background-color: #ECEFF1;*/
}
.body-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: calc(100vh - 100px);
  /* height: calc(100vh - 100px); */

  /*background-color: #f4f7ff; */
}

</style>


import { render, staticRenderFns } from "./IconDrag.vue?vue&type=template&id=dd7ddb50&"
import script from "./IconDrag.vue?vue&type=script&lang=ts&"
export * from "./IconDrag.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
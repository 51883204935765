<template>
  <g>
    <path d="M70 9.99994H30C27.3478 9.99994 24.8043 11.0535 22.9289 12.9289C21.0536 14.8042 20 17.3478 20 19.9999V99.9999C20 102.652 21.0536 105.196 22.9289 107.071C24.8043 108.946 27.3478 110 30 110H90C92.6522 110 95.1957 108.946 97.0711 107.071C98.9464 105.196 100 102.652 100 99.9999V39.9999L70 9.99994Z"
      fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.25" d="M70 9.99994H30C27.3478 9.99994 24.8043 11.0535 22.9289 12.9289C21.0536 14.8042 20 17.3478 20 19.9999V99.9999C20 102.652 21.0536 105.196 22.9289 107.071C24.8043 108.946 27.3478 110 30 110H90C92.6522 110 95.1957 108.946 97.0711 107.071C98.9464 105.196 100 102.652 100 99.9999V39.9999L70 9.99994Z"
      fill="#DEE2EE" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M70 9.99994V39.9999H100" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M80 65.0001H40" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M80 84.9999H40" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50 45H45H40" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFileDocument'
})
</script>

<template>
    <div class="panel">
        <panel-header :is-expanded="isExpanded" title="Market News" @toggleExpand="toggleExpand" />
        <Collapsible :is-expanded="isExpanded">
            <main>
                <list-news ref="recentNewsList" :newses="recentNewses" @clickNews="clickNews"/>
                <div class="divider" v-if="recentNewses.length > 0" />
                <div class="flex flex-row mb-3 mx-2">
                    <div class="label">All</div>
                    <button-switch class="mx-2" v-model="newsType"/>
                    <div class="label sell-label">Specific</div>
                </div>
                
                <button class="btn btn-success me-3" :disabled="isButtonDisabled" @click.prevent.stop="registerNews" type="submit">Listen</button>
                <Alert v-model="alert" />
            </main>
        </Collapsible>
    </div>
</template>

  
<script>
import Alert from '@/components/Alert'
import ButtonSwitch from '@/components/ButtonSwitch.vue'
import Collapsible from '@/components/Collapsible'
import PanelHeader from '@/components/panel/PanelHeader'
import ListNews from '@/components/panel/ListNews'
import axios from 'axios'

export default {
    name: 'MarketNews',
    components: {
        ButtonSwitch,
        Collapsible,
        ListNews,
        PanelHeader,
        Alert
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            recentNewses: [],
            newsType: false,
            alert: null
        };
    },
    computed: {
        chartSelectedSymbol() {
            return this.$store.state.chart.selectedSymbol
        },
        isButtonDisabled() {
            const isTokenExpired = this.$store.state.user.isTokenExpired;
            return isTokenExpired
        }
    },
    methods: {
        toggleExpand() {
            this.$emit('toggleExpand')
        },
        async clickNews(index, news) {
            console.log('clickNews', index)
            console.log('clickNews', news)
        },
    },
    async created() {
        let currentDate = new Date()
        let yesterdayDate = new Date(currentDate)
        yesterdayDate.setDate(yesterdayDate.getDate() - 1)

        const currentDateStr = currentDate.toISOString().slice(0, 10)
        const yesterdayDateStr = yesterdayDate.toISOString().slice(0, 10)      

        var newses = []
        await axios.get('/news/', {
            params: {
                date: yesterdayDateStr
            }
        }).then((response) => {
            const data = response.data
            if (data !== null && data.length > 0) {
                newses = data
            } 
        }).catch((error) => {
            console.log(error)
        })

        await axios.get('/news/', {
            params: {
                date: currentDateStr
            }
        }).then((response) => {
            const data = response.data
            if (data !== null && data.length > 0) {
                newses = newses.concat(data)
            }
        }).catch((error) => {
            console.log(error)
        })

        newses.reverse()

        this.recentNewses = newses.filter(news => news.sentiment >= 7 || news.sentiment <= 3)
    },
    watch: {
        chartSelectedSymbol: {
            handler: function () {
            },
            immediate: true
        },
    }
};
</script>

<style lang="postcss" scoped>
.panel {
    @apply w-full;
    border: 1px solid #E0E2E7;
    border-radius: 4px;
    padding: 12px;
    transition: .4s;

    button[type="submit"] {
        width: 100%;
    }

    .divider {
        @apply border-b border-gray-100;
        margin: 4px 0 20px 0;
    }

    .sell-label {
        margin-left: -16px;
    }
}
</style>  
<template>
<div class="list-news">
    <div
        class="news"
        v-for="(news, index) in newses"
        :key="index"
        :class="{ 'clicked': clickedIndex === index}"
    >
        <div class="flex flex-row w-full">
            <span class="text-gray-900 pl-1 text-left w-1/6">{{formatTimestamp(news.timestamp)}}</span>
            <span class="w-5/6">
                <a :href="news.link" target="_blank" class="text-gray-900 pl-1 news-title">{{news.title}}</a>
            </span>
            <span class="w-8 text-right">
                {{ news.sentiment }}
            </span>
        </div>
        <hr class="w-full divider" />
        
    </div>
</div>
</template>

<script>
export default {
    name: 'ListNews',
    components: {
    },
    props: {
        newses: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            clickedIndex: -1,
            clickedNews: null,
        };
    },
    computed: {
        isMobile() {
            return this.$store.state.appinfo.isMobile
        },
        selectedSymbolCurrentPrice() {
            return this.$store.state.chart.currentPrice
        }
    },
    methods: {
        formatTimestamp(timestamp) {
            const date = new Date(timestamp*1000);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${hours}:${minutes}`;
        },
        resetClickedIndex() {
            this.clickedIndex = -1
            this.clickedNews = null
        },
        clickNews(index, news) {
            if (index == this.clickedIndex) {
                this.clickedIndex = -1
                this.clickedNews = null
            } else {
                this.clickedIndex = index
                this.clickedNews = news
            }

        },
        getClickedNews() {
            return this.clickedNews
        },
        async sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        }
    }
}


</script>

<style lang='postcss' scoped>
.list-news {
    border-top: solid 1px #E0E2E7;
    margin-bottom: 5px;

    .news {
     @apply p-1 md:p-2;
      display: grid;
      grid-column-gap: 1px;
      align-items: left;
    
      -webkit-touch-callout: none; /* Disable iOS touch callout */
      -webkit-user-select: none; /* Disable text selection on iOS */
    }

    .news.news-title {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1; /* Allow the title to take up remaining space */
    }

    .divider {
        @apply border-gray-100;
        margin: 4px 0 0px 0;
    }
    
    @media (min-width: 768px) {
        .news:hover {
            .close-icon {
                @apply visible;
            }
            background: #F4F5F7;
        } 
    }

    .news.clicked {
        background: #ccc;
    }

  }
</style>
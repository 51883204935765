<template>
  <g>
    <path d="M10.667 12L14.667 8L10.667 4" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.33301 4L1.33301 8L5.33301 12" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconCode'
})
</script>

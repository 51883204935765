import { render, staticRenderFns } from "./IconCheckmark3.vue?vue&type=template&id=c9919ca0&"
import script from "./IconCheckmark3.vue?vue&type=script&lang=ts&"
export * from "./IconCheckmark3.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <g fill="transparent">
    <rect x="2" y="5" width="12" height="6" fill="currentColor" stroke="transparent"/>
    <path d="M13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667L14 3.33333C14 2.97971 13.8595 2.64057 13.6095 2.39052C13.3594 2.14048 13.0203 2 12.6667 2L3.33333 2C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333L2 12.6667C2 13.0203 2.14047 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14L12.6667 14C13.0203 14 13.3594 13.8595 13.6095 13.6095Z" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconStriped'
})
</script>

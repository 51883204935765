<template>
  <g fill="none">
    <path
      d="M11 1L4.125 7.875L1 4.75"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconCheckmark'
})
</script>

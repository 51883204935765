import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Index.vue'
//import store from '../store'
//import {getAuth} from 'firebase/auth'

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/strategy/:strategyType/:strategyId',
        name: 'FullScreenHome',
        component: () => import( '../views/home/FullScreenHome'),
        props: true,
      },
      {
        path: '/backtest',
        name: 'Backtest',
        component: () => import( '../views/home/BacktestHome'),
      },
      {
        path: '/signup',
        name: 'SignUp',
        component: () => import( '../views/public/SignUp'),
        meta: {
          noAuth: true,
          skipAuth: true
        }
      },
      {
        path: '/signup/success',
        name: 'SignUpSuccess',
        component: () => import('../views/landing/SignUpSuccess'),
        meta: {
          noAuth: true,
          skipAuth: true
        }
      },
      {
        path: '/signin',
        name: 'SignIn',
        component: () => import('../views/public/SignIn.vue'),
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/select-plan',
        name: 'SelectPlan',
        component: () => import('../views/public/SelectPlan.vue'),
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/checkout/success',
        name: 'UpgradeSuccess',
        component: () => import('../views/landing/UpgradeSuccess'),
        meta: {
          noAuth: true,
          skipAuth: true
        }
      },
      {
        path: '/setup-payment',
        name: 'SetupPayment',
        component: () => import('../views/public/SetupPayment.vue'),
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/stripe-checkout',
        name: 'StripeCheckout',
        component: () => import('../views/public/StripCheckout.vue'),
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/setup-telegram',
        name: 'SetupTelegram',
        component: () => import('../views/landing/SetupTelegram.vue'),
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: function () { return import('../views/settings/Settings.vue'); },
        meta: {
            noAuth: true
        },
        children: [
          {
              path: '',
              redirect: { name: 'SettingsAccount' },
              name: 'Settings'
          },
          {
              path: 'account',
              name: 'SettingsAccount',
              component: function () { return import('../views/settings/Account.vue'); }
          },
          {
              path: 'account/strategies',
              name: 'SettingsStrategies',
              component: function () { return import('../views/settings/Strategies.vue'); }
          },
          {
              path: 'account/billing',
              name: 'SettingsBilling',
              component: function () { return import( '../views/settings/Billing.vue'); }
          },
          {
              path: 'account/notifications',
              name: 'SettingsNotifications',
              component: function () { return import('../views/settings/Notifications.vue'); }
          }
      ]
      },
      {
        path: '/confirm-email/:token/:id',
        name: 'ConfirmEmail',
        component: function () { return import('../views/landing/ConfirmEmail.vue'); },
        meta: {
            noAuth: true
        }
      },
      {
        path: '/auth/google/callback',
        name: 'GoogleCallback',
        component: function () { return import('../views/landing/GoogleCallback.vue'); },
        meta: {
            noAuth: true
        }
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: function () { return import('../views/public/ForgotPassword.vue'); },
        meta: {
            noAuth: true
        }
      },
      {
        path: '/password-reset/:token',
        name: 'PasswordReset',
        component: function () { return import('../views/public/SetPassword.vue'); },
        meta: {
            noAuth: true
        }
      },
      {
        path: '/terms-and-policies',
        name: 'TermsAndPolicies',
        component: function () { return import('../views/landing/TermsAndPolicies.vue'); },
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/contact-us',
        name: 'ContactUs',
        component: function () { return import('../views/public/ContactUs.vue'); },
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/articles',
        name: 'Articles',
        component: function () { return import('../views/articles/Articles.vue'); },
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/videos',
        name: 'Articles',
        component: function () { return import('../views/articles/Videos.vue'); },
        meta: {
            noAuth: true,
            skipAuth: true
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/error-page/404')
      },
      
  ]
});

/*
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = store.state.user.user

  if (requiresAuth && !currentUser) next({ path: '/signin', query: { redirect: to.fullPath } })
  else if (!requiresAuth) next('/')
  else next()
})
*/
  
export default router;


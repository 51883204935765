<template>
<div class="list-strategy">
    <div
        class="strategy"
        v-for="(strategy, index) in strategies"
        :key="index"
        :class="{ 'clicked': clickedIndex === index, 'warn': getStrategyStatus(strategy) === 1, 'expired': getStrategyStatus(strategy) === 2}"
        @click.prevent.stop="clickStrategy(index, strategy)"
    >
        <div class="flex flex-row w-full">
            <avatar class="avatar" :size="20" :username="strategy.name"></avatar>
            <span class="text-gray-900 pl-2 self-center flex-grow">{{strategy.name}}</span>
            <div v-if="clickedIndex === index && showPriceChange(strategy)" class="text-gray-900 self-center flex">
                <span :class="{'gain':priceChange.change==='gain', 'loss':priceChange.change==='loss'}">
                    {{priceChange.text}}
                </span>
            </div>
            <span class='close-icon' @click.prevent.stop="deleteStrategy(index, strategy)">
                <legacy-icon name="close" size=".9em" viewbox="32" title="Remove"/>
            </span>
        </div>
        
    </div>
</div>
</template>

<script>
import Avatar from 'vue-avatar'
import {INTERVAL_MAP, LENGTH_KLINE, SAFELIMITER_SAFERANGE, ORDER_STRAEGY_VALID_LENGTH} from '@/utils/constants.js'

export default {
    name: 'ListStrategy',
    components: {
        Avatar
    },
    props: {
        strategies: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            clickedIndex: -1,
            clickedStrategy: null,
            priceChange: {
                text: '0.00%',
                change: 'na'
            }
        };
    },
    computed: {
        isMobile() {
            return this.$store.state.appinfo.isMobile
        },
        selectedSymbolCurrentPrice() {
            return this.$store.state.chart.currentPrice
        }
    },
    methods: {
        resetClickedIndex() {
            this.clickedIndex = -1
            this.clickedStrategy = null
        },
        clickStrategy(index, strategy) {
            if (index == this.clickedIndex) {
                this.clickedIndex = -1
                this.clickedStrategy = null
            } else {
                this.clickedIndex = index
                this.clickedStrategy = strategy
            }

            this.$emit('clickStrategy', this.clickedIndex, strategy)
            this.priceChange = this.getPriceChange(strategy)
        },
        getClickedStrategy() {
            return this.clickedStrategy
        },
        deleteStrategy(index, strategy) {
            this.clickedIndex = -1
            this.$emit('deleteStrategy', index, strategy)
        },
        getStrategyStatus(strategy) {
            // 0: OK, 1: Warn, 2: Obsolete
            if (strategy.created_at == undefined) {
                return 0
            } 
            if (strategy.type === 'st_entry') {
                return 0
            } 
            
            if (strategy.type === 'st_order') {
                let diff = Math.floor(Date.now()) - strategy.created_at
                if (diff < INTERVAL_MAP[strategy.interval] * (ORDER_STRAEGY_VALID_LENGTH-3) * 1000) {
                    return 0
                } else if (diff < INTERVAL_MAP[strategy.interval] * ORDER_STRAEGY_VALID_LENGTH * 1000) {
                    return 1
                } else {
                    return 2
                }
            } else if (strategy.type === 'openpos_safeguard') {
                let diff = Math.floor(Date.now()) - strategy.openpos.time
                let nInterval = LENGTH_KLINE - SAFELIMITER_SAFERANGE
                if (diff < INTERVAL_MAP[strategy.interval] * (nInterval-3) * 1000) {
                    return 0
                } else if (diff < INTERVAL_MAP[strategy.interval] * nInterval * 1000) {
                    return 1
                } else {
                    return 2
                }
            } else {
                return 0
            }
        },
        showPriceChange(strategy) {
            if (strategy.type === 'openpos_safeguard') {
                return true
            } else {
                return false
            }
        },
        getPriceChange(strategy) {
            if (strategy !== null && strategy.type === 'openpos_safeguard' &&
                this.$store.state.chart.selectedSymbol === strategy.symbol) {
                let currentPrice = this.$store.state.chart.currentPrice
                if (currentPrice !== null) {
                    let priceChangeP = ((currentPrice - strategy.openpos.price)/strategy.openpos.price)
                    if (priceChangeP>=0){
                        return {
                            text: '+' + (priceChangeP*100).toFixed(2) + '%',
                            change: 'gain',
                        }
                    }else{
                        return {
                            text: (priceChangeP*100).toFixed(2) + '%',
                            change: 'loss'
                        }
                    }
                } 
            } 
            
            return {
                text: '0.00%',
                change: 'na'
            }
        },
        async sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        }
    },
    watch: {
        selectedSymbolCurrentPrice() {
            this.priceChange = this.getPriceChange(this.clickedStrategy)
        }
    }

}


</script>

<style lang='postcss' scoped>
.list-strategy {
    border-top: solid 1px #E0E2E7;
    margin-bottom: 5px;

    .members-count {
      font-size: 0.875rem;
      font-weight: bold;
      margin-bottom: 8px;
      margin-top: 24px;
    }

    .strategy {
     @apply px-2 py-1 md:py-2;
      display: grid;
      grid-column-gap: 8px;
      align-items: center;
    
      -webkit-touch-callout: none; /* Disable iOS touch callout */
      -webkit-user-select: none; /* Disable text selection on iOS */
    }

    .strategy.clicked {
        background: #ccc;
    }

    .strategy.warn {
        background-color: rgba(theme('colors.yellow.default'), 0.3);
    }

    .strategy.expired {
        background-color: rgba(theme('colors.red.default'), 0.3);
    }

    @media (max-width: 768px) {
        .strategy.clicked {
            .close-icon {
                @apply visible;
            }
            background: #ccc;
        }
    }

    @media (min-width: 768px) {
        .strategy:hover {
            .close-icon {
                @apply visible;
            }
            background: #F4F5F7;
        } 
    }

    .strategy .gain {
        @apply px-1 mx-1;
        font-weight: bold;
        border: solid 1px theme('colors.green.default');
        border-radius: 2px;
        background-color: theme('colors.green.default');
    }

    .strategy .loss {
        @apply px-1 mx-1;
        font-weight: bold;
        border: solid 1px theme('colors.red.default');
        border-radius: 2px;
        background-color: theme('colors.red.default');
    }
    .avatar {
        margin-top: 2px;
      aspect-ratio: 1;
    }
    .separator {
        border: none;
        height: 1px;
        background-color: #ccc;
        margin: 10px 0;
    }
    .btn-section {
        margin-top: 10px;
        border-top: solid 1px #E0E2E7;
    }

    .close-icon {
      @apply flex rounded-full self-center;
      cursor: pointer;
      height: 25px;
      width: 25px;
      aspect-ratio: 1;
      align-items: center;
      justify-content: center;
      color: #2C2B35;
      cursor: grab;
      font-size: 20px;
      visibility: hidden;
      color: #a7b2cf;

      &:hover {
        background: #E0E2E7;
      }
      .stroke-current{
        stroke: none;
      }
    }
  }
</style>
<template>
  <g>
    <path d="M12.4804 13.2001H3.52039C3.22335 13.2001 2.93847 13.0821 2.72843 12.872C2.51839 12.662 2.40039 12.3772 2.40039 12.0801V4.24012C2.40039 3.94308 2.51839 3.65821 2.72843 3.44816C2.93847 3.23812 3.22335 3.12012 3.52039 3.12012H6.32039L7.44039 4.80012H12.4804C12.7774 4.80012 13.0623 4.91812 13.2723 5.12816C13.4824 5.3382 13.6004 5.62308 13.6004 5.92012V7.50012" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.78786 8.64816C3.91251 8.14958 4.36048 7.7998 4.87442 7.7998H13.7655C14.4941 7.7998 15.0287 8.48456 14.852 9.1914L13.812 12.3515C13.6874 12.85 13.2394 13.1998 12.7255 13.1998H3.83442C3.10578 13.1998 2.57114 12.5151 2.74786 11.8082L3.78786 8.64816Z" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFolderOpen'
})
</script>

<template>
  <g>
    <path d="M5.57315 13.4805H6.1264L3.5199 10.874V11.4273C3.52224 12.2058 3.24289 12.9589 2.7334 13.5475L3.45315 14.2675C4.04166 13.7579 4.79466 13.4784 5.57315 13.4805Z" fill="currentFill"/>
    <path d="M1.33838 14.9999H2.65288L3.08488 14.6057L2.38913 13.9102L1.33838 14.9999Z" fill="currentFill"/>
    <path d="M14.6525 4.54632C14.7799 4.40339 14.8479 4.21712 14.8424 4.0257C14.8369 3.83429 14.7584 3.65222 14.623 3.51682L13.4832 2.37707C13.3479 2.24161 13.1658 2.16307 12.9744 2.15754C12.783 2.15201 12.5967 2.21991 12.4537 2.34732L3.59399 10.2408L6.75924 13.4063L14.6525 4.54632Z" fill="currentFill"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconPen'
})
</script>

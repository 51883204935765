<template>
  <g>
    <path
      opacity="0.15"
      d="M17.9199 3.20001H8.9599C8.28095 3.20001 7.6298 3.46973 7.14971 3.94982C6.66962 4.42991 6.3999 5.08106 6.3999 5.76001V26.24C6.3999 26.919 6.66962 27.5701 7.14971 28.0502C7.6298 28.5303 8.28095 28.8 8.9599 28.8H24.3199C24.9989 28.8 25.65 28.5303 26.1301 28.0502C26.6102 27.5701 26.8799 26.919 26.8799 26.24V12.16L17.9199 3.20001Z"
    />
    <path
      d="M17.9199 3.20001H8.9599C8.28095 3.20001 7.6298 3.46973 7.14971 3.94982C6.66962 4.42991 6.3999 5.08106 6.3999 5.76001V26.24C6.3999 26.919 6.66962 27.5701 7.14971 28.0502C7.6298 28.5303 8.28095 28.8 8.9599 28.8H24.3199C24.9989 28.8 25.65 28.5303 26.1301 28.0502C26.6102 27.5701 26.8799 26.919 26.8799 26.24V12.16M17.9199 3.20001L26.8799 12.16M17.9199 3.20001L17.9199 12.16L26.8799 12.16"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFile'
})
</script>

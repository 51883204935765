<template>
  <path d="M9 2.25C5.2725 2.25 2.25 5.2725 2.25 9C2.25 12.7275 5.2725 15.75 9 15.75C9.6225 15.75 10.125 15.2475 10.125 14.625C10.125 14.3325 10.0125 14.07 9.8325 13.8675C9.66 13.6725 9.5475 13.41 9.5475 13.125C9.5475 12.5025 10.05 12 10.6725 12H12C14.07 12 15.75 10.32 15.75 8.25C15.75 4.935 12.7275 2.25 9 2.25ZM4.875 9C4.2525 9 3.75 8.4975 3.75 7.875C3.75 7.2525 4.2525 6.75 4.875 6.75C5.4975 6.75 6 7.2525 6 7.875C6 8.4975 5.4975 9 4.875 9ZM7.125 6C6.5025 6 6 5.4975 6 4.875C6 4.2525 6.5025 3.75 7.125 3.75C7.7475 3.75 8.25 4.2525 8.25 4.875C8.25 5.4975 7.7475 6 7.125 6ZM10.875 6C10.2525 6 9.75 5.4975 9.75 4.875C9.75 4.2525 10.2525 3.75 10.875 3.75C11.4975 3.75 12 4.2525 12 4.875C12 5.4975 11.4975 6 10.875 6ZM13.125 9C12.5025 9 12 8.4975 12 7.875C12 7.2525 12.5025 6.75 13.125 6.75C13.7475 6.75 14.25 7.2525 14.25 7.875C14.25 8.4975 13.7475 9 13.125 9Z"
    fill="currentFill"
    stroke="transparent"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconColor'
})
</script>

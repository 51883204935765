<template>
    <div class="panel">
        <panel-header :is-expanded="isExpanded" title="Monitoring for Order" @toggleExpand="toggleExpand" />
        <Collapsible :is-expanded="isExpanded">
            <main>
                <list-strategy ref="orderStrategyList" :strategies="registeredStrategies" @clickStrategy="clickStrategy" @deleteStrategy="deleteStrategy" />
                <div class="divider" v-if="registeredStrategies.length > 0" />
                <div class="flex flex-row mb-3 mx-2">
                    <div class="label">Buy</div>
                    <button-switch class="mx-2" v-model="tradeSide"/>
                    <div class="label sell-label">Sell</div>
                </div>
                
                <select class="input input-role w-full mb-3" v-model="selectedOption" @change="handleSelection">
                    <option :value="undefined" disabled>Select a strategy</option>
                    <option v-for = "strategy, index in strategyOptions" :key="index" :value="index">{{strategy.temp_name}}</option>
                </select>
                <button class="btn btn-success me-3" :disabled="isButtonDisabled" @click.prevent.stop="registerStrategy" type="submit">Add</button>
                <Alert v-model="alert" />
            </main>
        </Collapsible>
    </div>
</template>

  
<script>
import Alert from '@/components/Alert'
import ButtonSwitch from '@/components/ButtonSwitch.vue'
import Collapsible from '@/components/Collapsible'
import PanelHeader from '@/components/panel/PanelHeader'
import ListStrategy from '@/components/panel/ListStrategy'
import {setShowUpgrade} from '@/utils/app.js'

export default {
    name: 'OrderMonitor',
    components: {
        //Avatar,
        //AddStrategy,
        ButtonSwitch,
        Collapsible,
        ListStrategy,
        PanelHeader,
        Alert
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tradeSide: false,
            selectedOption: undefined,
            doNotResetIndex: false,
            alert: null
        };
    },
    computed: {
        strategyOptions() {
            return this.getStrategyOptions()
        },
        registeredStrategies() {
            return this.$store.state.user.registeredOrderStrategies
        },
        chartSelectedSymbol() {
            return this.$store.state.chart.selectedSymbol
        },
        chartSelectedInterval() {
            return this.$store.state.chart.selectedInterval
        },
        isButtonDisabled() {
            const isTokenExpired = this.$store.state.user.isTokenExpired;
            return this.selectedOption === undefined || this.chartSelectedSymbol === null || this.chartSelectedInterval === null || isTokenExpired
        }
    },
    methods: {
        getStrategyOptions(){
            let strategyTemp_ = this.$store.state.appinfo.orderStrategyTemp;
            let selectedSymbol = this.$store.state.chart.selectedSymbol;
            let selectedInterval = this.$store.state.chart.selectedInterval;

            if (strategyTemp_ === null || selectedSymbol === null || selectedInterval === null) {
                return []
            }
            let strategyOptions = []

            // Temporary adapter, need to be removed once the backend is ready
            let strategyTemp = {}
            for (let i=0; i<strategyTemp_.length; ++i) {
                let temp = strategyTemp_[i]
                strategyTemp[temp] = {'name' : temp}
            }
            
            let user = this.$store.state.user
            let isPro = user && user.userAccountType === 'pro'

            for (const id in strategyTemp) {
                // Show KF_Inflection only for Pro users
                if (strategyTemp[id].name === 'KF_Inflection' && !isPro) {
                    continue;
                }
                strategyOptions.push({
                    temp_id: id,
                    temp_name: strategyTemp[id].name,
                    temp_type: 'st_order',
                    type: 'st_order'
                });
            }
            return strategyOptions
        },
        async handleSelection() {
            if (this.selectedOption === undefined) {
                return;
            }
            this.$refs.orderStrategyList.resetClickedIndex()
            let strategyTemps = this.getStrategyOptions()
            let strategy = strategyTemps[this.selectedOption]
            strategy.side = this.tradeSide ? 'sell' : 'buy'

            await this.$store.dispatch('chart/setActiveStrategy', strategy);
        },
        toggleExpand() {
            this.$emit('toggleExpand')
        },
        async clickStrategy(index, strategy) {
            console.log('clickStrategy', index)
            this.selectedOption = undefined

            if (index === -1) {
                strategy = null
                await this.$store.dispatch('chart/setActiveStrategy', strategy);
                return
            }

            if (strategy.symbol !== this.chartSelectedSymbol) {
                await this.$store.dispatch('summary/updateSummary', strategy.symbol);
            }
            if (strategy.symbol !== this.chartSelectedSymbol) {
                this.doNotResetIndex = true
                await this.$store.dispatch('chart/updateChartUponSymbolChange', strategy.symbol);
            }
            if (strategy.interval !== this.chartSelectedInterval) {
                this.doNotResetIndex = true
                this.$store.commit('chart/updateSelectedInterval', strategy.interval);
            }
            
            await this.$store.dispatch('chart/setActiveStrategy', strategy);
        },
        async deleteStrategy(index, strategy) {
            console.log('deleteStrategy', index)
            try {
                await this.$store.dispatch('user/deleteOrderStrategy', strategy)
            } catch (error) {
                console.log(error)
                this.alert = {
                    type: 'danger',
                    message: 'Session maybe expired. Please refresh the page.',
                }
            }
            await this.$store.dispatch('chart/setActiveStrategy', null);
        },
        async registerStrategy() {
            this.alert = null

            let user = this.$store.state.user
            if (user.user===null) {
                this.alert = {
                    type: 'danger',
                    message: 'Please sign in first',
                    action: 'Login',
                }
                
                this.$store.commit('appinfo/setFullScreenMode', false);
                return
            }

            if (user.userAccountType === 'basic') {
                this.alert = {
                    type: 'danger',
                    message: 'Please upgrade to Plus first',
                    action: 'Upgrade',
                }
                setShowUpgrade();
                return
            }

            if (user.userAccountType === 'plus') {
                if (this.registeredStrategies.length >= 6) {
                    this.alert = {
                        type: 'danger',
                        message: 'You can only add up to 6 monitors',
                    }
                    return
                }
            }

            let selectedSymbol = this.$store.state.chart.selectedSymbol;
            let selectedInterval = this.$store.state.chart.selectedInterval;
            let strategyTemps = this.getStrategyOptions()
            let side = this.tradeSide ? 'sell' : 'buy';

            let newStrategy = {
                id: selectedSymbol+'_'+selectedInterval+'_'+strategyTemps[this.selectedOption].temp_id,
                name: selectedSymbol+'-'+selectedInterval+'-'+strategyTemps[this.selectedOption].temp_name + '-' + side,
                symbol: selectedSymbol,
                interval: selectedInterval,
                temp_id: strategyTemps[this.selectedOption].temp_id,
                temp_name: strategyTemps[this.selectedOption].temp_name,
                side: side,
                status: 'Active',
                type: 'st_order',
                created_at: Math.floor(Date.now())
            }

            // Check if the strategy is already registered
            if (this.isStrategyRegistered(newStrategy)) {
                this.alert = {
                    type: 'danger',
                    message: 'Strategy already added'
                }
                return
            }

            try {
                await this.$store.dispatch('user/registerOrderStrategy', newStrategy)
                this.alert = {
                    type: 'success',
                    message: 'Successfully added'
                }
            } catch (error) {
                this.alert = {
                    type: 'danger',
                    message: error.message
                }
                return
            }
        },
        resetListStatus() {
            this.selectedOption = undefined
            if (this.$refs.orderStrategyList !== undefined){
                this.$refs.orderStrategyList.resetClickedIndex()
            }
        },
        isStrategyRegistered(strategy) {
            for (let i=0; i<this.registeredStrategies.length; ++i) {
                if (this.registeredStrategies[i].id === strategy.id) {
                    return true
                }
            }
            return false
        }
    },
    async created() {
    },
    watch: {
        chartSelectedSymbol: {
            handler: function () {
                if (this.doNotResetIndex === false) {
                    this.resetListStatus()
                } else {
                    this.doNotResetIndex = false;
                }
            },
            immediate: true
        },
        chartSelectedInterval: {
            handler: function () {
                if (this.doNotResetIndex === false) {
                    this.resetListStatus()
                } else {
                    this.doNotResetIndex = false;
                }
            },
            immediate: true
        },
        tradeSide: {
            handler: async function () {
                await this.handleSelection()
            },
            immediate: true
        },
    }
};
</script>

<style lang="postcss" scoped>
.panel {
    @apply w-full;
    border: 1px solid #E0E2E7;
    border-radius: 4px;
    padding: 12px;
    transition: .4s;

    button[type="submit"] {
        width: 100%;
    }

    &.has-slot button[type="submit"] {
        width: 82px;
        float: right;
        padding-left: 0px;
        padding-right: 0px;
    }

    .input.input-role {
        position: relative;
        padding: 0 8px 0 8px;
        appearance: none;

        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.03647 6.5547C4.59343 5.89014 5.06982 5 5.86852 5H10.1315C10.9302 5 11.4066 5.89015 10.9635 6.5547L8.83205 9.75192C8.43623 10.3457 7.56377 10.3457 7.16795 9.75192L5.03647 6.5547Z' fill='%23444754'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: calc(100% - 5px) center;
    }

    .input::-webkit-outer-spin-button,
    .input::-webkit-inner-spin-button {
        display: none;
    }

    .divider {
        @apply border-b border-gray-100;
        margin: 4px 0 20px 0;
    }

    .sell-label {
        margin-left: -16px;
    }
}
</style>  
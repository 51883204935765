<template>
  <g transform="translate(5, 5)">
    <path d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33331 5.83337H5.83331V13.3334H8.33331V5.83337Z" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1667 5.83337H11.6667V10H14.1667V5.83337Z" stroke="currentStroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconKanban'
})
</script>

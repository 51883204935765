<template>
  <g>
    <path d="M8.1246 0H42.6415L60.937 18.2244V60.9375C60.937 63.182 59.1171 65 56.8746 65H8.1246C5.88203 65 4.06213 63.182 4.06213 60.9375V4.06246C4.06213 1.81801 5.88224 0 8.1246 0Z" fill="#E2574C"/>
    <path d="M60.8786 18.2812H46.7187C44.4762 18.2812 42.6562 16.4613 42.6562 14.2187V0.0405884L60.8786 18.2812Z" fill="#B53629"/>
    <path d="M45.6987 30.7998C46.3793 30.7998 46.7124 30.2067 46.7124 29.6319C46.7124 29.0367 46.365 28.4619 45.6987 28.4619H41.8231C41.0654 28.4619 40.643 29.0894 40.643 29.7822V39.3066C40.643 40.1557 41.1264 40.627 41.7805 40.627C42.4305 40.627 42.916 40.1557 42.916 39.3066V36.6925H45.2601C45.9873 36.6925 46.3509 36.0973 46.3509 35.5063C46.3509 34.9274 45.9873 34.3524 45.2601 34.3524H42.916V30.7998H45.6987ZM32.5992 28.4619H29.7635C28.9937 28.4619 28.4472 28.99 28.4472 29.774V39.3148C28.4472 40.2877 29.2333 40.5925 29.7959 40.5925H32.7718C36.2939 40.5925 38.6197 38.2749 38.6197 34.6977C38.6178 30.9156 36.4282 28.4619 32.5992 28.4619ZM32.7354 38.2404H31.0067V30.8141H32.5647C34.9231 30.8141 35.9488 32.3965 35.9488 34.58C35.9488 36.6236 34.9412 38.2404 32.7354 38.2404ZM22.3475 28.4619H19.5384C18.7441 28.4619 18.3013 28.9858 18.3013 29.7822V39.3066C18.3013 40.1557 18.809 40.627 19.4915 40.627C20.1739 40.627 20.6817 40.1557 20.6817 39.3066V36.5258H22.4428C24.6162 36.5258 26.4098 34.9861 26.4098 32.51C26.41 30.0869 24.6794 28.4619 22.3475 28.4619ZM22.3009 34.2917H20.6819V30.6983H22.3009C23.3003 30.6983 23.936 31.4783 23.936 32.4959C23.9339 33.5117 23.3003 34.2917 22.3009 34.2917Z" fill="white"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconFilePdf'
})
</script>

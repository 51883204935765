<template>
  <div class="field" :class="{
    'is-inline': inline,
    'is-bordered': border,
    'is-disabled': disabled,
    'has-addon': $slots.addon
  }">
    <label v-if="label" :for="name" class="label">
      {{ label }}
    </label>

    <div class="control" :class="{
      'has-icon-left': hasIconLeft,
      'has-icon-right': hasIconRight,
      'justify-start': align === 'left',
      'justify-end': align === 'right',
      'justify-center': align === 'center'
    }">
      <slot></slot>
    </div>

    <div v-if="$slots.addon" class="control-addon">
      <slot name="addon"></slot>
    </div>

    <slot name="feedback"></slot>
  </div>
</template>

<script>
export default {
  name: 'FieldComponent',
  props: {
    label: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: undefined
    },
    border: {
      type: Boolean,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: undefined
    },
    align: {
      type: String,
      default: undefined
    },
    hasIconLeft: {
      type: Boolean,
      default: undefined
    },
    hasIconRight: {
      type: Boolean,
      default: undefined
    }
  }
};
</script>

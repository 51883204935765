<template>
  <g>
    <path d="M7.5 5L10 7.5L7.5 10" stroke="#6D7384" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 2V5.5C2 6.03043 2.21071 6.53914 2.58579 6.91421C2.96086 7.28929 3.46957 7.5 4 7.5H10" stroke="#6D7384" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconReply'
})
</script>

<template>
  <g>
    <path d="M7.44978 14.8105L11.0732 1.28758L10 1L6.37653 14.523L7.44978 14.8105Z" fill="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconItalic'
})
</script>

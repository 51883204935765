<template>
  <header class="header">
    <h1 class="title items-center">
      <router-link class="flex" to="/" title="TTM">
        <img src="@/assets/logo1.svg" alt="logo" class="logo h-8 mr-1 pt-1" />
        <span class="text-2xl font-semibold">TTM</span>
      </router-link>
    </h1>
    <button @click="isCollapsed = !isCollapsed">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon"></span>
    </button>
    <div :class="{ 'hidden': isCollapsed, 'flex': !isCollapsed }">
      <div v-if="isLoggedIn" class="actions">
        <!--div class="action-group">
          <router-link to="/setup-telegram" v-bind:title="$t('Setup Telegram')" exact>{{ $t('Setup Telegram')
          }}</router-link>
        </div -->
        <div v-if="showUpgrade" class="action-group action-button">
          <router-link to="/select-plan" v-bind:title="$t('Upgrade')" exact>{{ $t('Upgrade') }}</router-link>
        </div>
        <div class="action-group">
          <user-account></user-account>
        </div>
      </div>
      <ul v-else class="actions">
        <li class="action-group">
          <router-link class="nav-link" to="/signin" v-bind:title="$t('signin')" exact>{{ $t('Sign In') }}</router-link>
        </li>
        <li class="action-group action-button">
          <router-link class="nav-link" to="/signup" v-bind:title="$t('signup')" exact>{{ $t('Sign Up') }}</router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
//import {useStore} from vuex
import { getAuth, signOut } from "firebase/auth";
import { mapGetters, mapActions } from 'vuex'
import UserAccount from '@/components/UserAccount'

export default {
  name: 'HeaderBlock',
  components: {
    UserAccount
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapActions('user', ['logOut']),
    isLoggedIn: function () {
      return this.isAuthenticated;
    },
    showUpgrade: function () {
      return this.$store.state.user.user !== null && this.$store.state.user.userAccountType === 'basic';
    },
  },
  methods: {
    onDropdown(id) {
      this.show = this.show === id ? null : id;
    },
    onSelect(label) {
      this.show = null;
      if (label === 'Logout') {
        this.logout();
      }
    },
    async logout() {
      //await this.$store.dispatch('logout');
      try {
        const auth = getAuth()
        await signOut(auth)
        await this.$store.dispatch('user/logOut');
      } catch (error) {
        // An error happened.
        console.log(error);
        alert(error.message);
      }

      this.$router.push('/signin')
    },
  },
  data() {
    return {
      show: null,
      isCollapsed: false,
      userinfo_items: [
        {
          label: 'Profile',
        },
        {
          label: 'Logout',
        }
      ],
    };
  },
};
</script>

<style lang="postcss" scoped>
.header {
  @apply flex flex-row items-center;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: theme("colors.gray.900");

  background-color: #ECEFF1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.title {
  @apply flex flex-row items-center ml-6 md:ml-12;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
}

.actions {
  @apply flex flex-row items-center;
  @apply mr-2 md:mr-4;
}

.action {
  @apply flex flex-row items-center;
  @apply mr-5;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  transition: all 300ms;
}

.action-group {
  @apply px-1 mx-1 md:px-2 md:mx-2;

  &:hover {
    @apply text-primary-default;
  }
}

.action-button {
  background-color: theme("colors.success.default");
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.action-group+.action-group {
  border-left: 1px solid #dee2ee;
}

.action--search {
  &.action__active {
    width: 304px;
    box-shadow: 0 0 2px 2px #8cd5ff;
    border-radius: 4px;
    background: white;
    color: #444754;
  }

  input {
    width: 100%;
    outline: none;
  }

  .action--body {
    display: flex;
    flex-flow: row;
    position: relative;
    width: 100%;
    z-index: 110;
  }

  .action--search--close {
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #F4F5F7;
    outline: none;
    font-size: 12px;
    stroke-width: 2px;
  }
}

.view--kanban {
  flex: 1;
  overflow-x: auto;
  padding-left: 72px;
}

.view--list {
  flex: 1;
  padding-left: 52px;
  padding-bottom: 52px;
}
</style>

<style lang='postcss' scoped>
.nav-item {
  margin-right: 1rem;
}

.dropdown-menu {
  right: 18px;
  transform: translateY(1em);
}

.dropdown-toggle.opened::after {
  transform: rotate(180deg);
}

.logo {
  margin-top: -8px;
  width: 40px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain the aspect ratio */
}
</style>

<template>
  <svg>
    <path
      d="M22.4001 12.8L16.0001 19.2L9.6001 12.8"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconDown'
})
</script>

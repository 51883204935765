<template>
  <div class="icon icon--mono" :title="iconName">
    <component
      :is="iconName"
      width='1em'
      height='1em'
    />
  </div>
</template>

<script lang="ts">
  import { kebabCase } from 'lodash'
  import manifest from './manifest'

  export default {
    name: 'MonoIcon',
    components: manifest.mono,
    props: {
      name: {
        type: String,
        required: true
      }
    },
    computed: {
      iconName() {
        return kebabCase(`${this.name}-icon`);
      }
    }
  }
</script>

<template>
  <g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.74216 15C4.26671 15 3.04932 12.8499 3.00023 11.1613H4.4827C4.61032 12.4867 5.71972 13.6648 7.9287 13.6648C9.8726 13.6648 11.1489 12.7027 11.1489 11.1613C11.1489 9.95372 10.4028 9.29593 8.82211 8.88359L6.50514 8.26508C6.30761 8.21265 6.11146 8.15129 5.91937 8.08011H1.54C1.24192 8.08011 1 7.83819 1 7.54011C1 7.24203 1.24192 7.00011 1.54 7.00011H4.22695C3.72976 6.45645 3.41257 5.73782 3.41257 4.79944C3.41257 2.34502 5.59209 1 7.95815 1C10.2064 1 12.4448 2.18794 12.4939 4.73072H11.0213C10.9035 3.41515 9.91187 2.3352 7.9287 2.3352C6.17133 2.3352 4.90486 3.18934 4.90486 4.70126C4.90486 5.75175 5.543 6.47826 6.88802 6.8317L7.50553 7.00011H14.86C15.1581 7.00011 15.4 7.24203 15.4 7.54011C15.4 7.83819 15.1581 8.08011 14.86 8.08011H10.8367C11.9707 8.68262 12.651 9.58337 12.651 11.0631C12.651 13.655 10.4617 15 7.74216 15Z" fill="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconStrike'
})
</script>

<template>
  <path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"
  fill="transparent"/>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconCheckmark3'
})
</script>

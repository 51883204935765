<template>
    <div >
        <div class="svg-container" @mousedown.prevent.stop="enableFullScreen" @touchstart.prevent.stop="enableFullScreen">
            <svg width="32" height="32" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg">
                <path id="Path_114" data-name="Path 114" d="M-14.146,10.146a.5.5,0,0,1,0,.708L-18.293,15H-16.5a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.191-.038.506.506,0,0,1-.271-.271A.5.5,0,0,1-20,15.5v-3a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v1.793l4.146-4.147A.5.5,0,0,1-14.146,10.146ZM-18.293,1H-16.5A.5.5,0,0,0-16,.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.191.038.506.506,0,0,0-.271.271A.5.5,0,0,0-20,.5v3a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V1.707l4.146,4.147A.5.5,0,0,0-14.5,6a.5.5,0,0,0,.354-.146.5.5,0,0,0,0-.708ZM-4.038.309A.506.506,0,0,0-4.309.038.5.5,0,0,0-4.5,0h-3A.5.5,0,0,0-8,.5a.5.5,0,0,0,.5.5h1.793L-9.854,5.146a.5.5,0,0,0,0,.708A.5.5,0,0,0-9.5,6a.5.5,0,0,0,.354-.146L-5,1.707V3.5a.5.5,0,0,0,.5.5A.5.5,0,0,0-4,3.5V.5A.5.5,0,0,0-4.038.309ZM-4.5,12a.5.5,0,0,0-.5.5v1.793l-4.146-4.147a.5.5,0,0,0-.708,0,.5.5,0,0,0,0,.708L-5.707,15H-7.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.191-.038.506.506,0,0,0,.271-.271A.5.5,0,0,0-4,15.5v-3A.5.5,0,0,0-4.5,12Z" transform="translate(20)"/>           </svg>
        </div>
    </div>

</template>

<script>

export default {
    name: 'FullScreenExpandButton',
    props: {
        color: {
            type: String,
            default: '#555'
        }
    },
    data() {
        return {
        };
    },
    methods: {
        async enableFullScreen() {
            this.$emit('enableFullScreen');
        },
    }
}

</script>

<style lang="postcss" scoped>
    .svg-container {
        position: relative;  
        z-index: 1000;
        svg {
            g {
                fill: rgba(theme('colors.success.default'), 0.5);
            }
            path {
                fill: rgba(theme('colors.success.default'), 0.5);
            }
            polyline {
                stroke: rgba(theme('colors.success.default'), 0.5);
            }
        }
    }
    .svg-container:hover svg {

        g {
            fill: rgba(theme('colors.success.default'));
            stroke: rgba(theme('colors.success.default'));
        }
        path {
            fill: rgba(theme('colors.success.default'));
            stroke: rgba(theme('colors.success.default'));
        }
        polyline {
            fill: rgba(theme('colors.success.default'));
            stroke: rgba(theme('colors.success.default'));
        }
    }

</style>
<template>
  <g>
    <path d="M3 15H8.97949C11.7077 15 13.8513 13.6154 13.8513 11.041C13.8513 8.94872 12.4051 7.78974 10.7231 7.6359V7.50256C12.0359 7.20513 13.1538 6.23077 13.1538 4.4359C13.1538 2.26154 11.4513 1 8.95897 1H3V15ZM5.94359 3.17436H8.25128C9.42051 3.17436 10.241 3.77949 10.241 4.94872C10.241 6.12821 9.41026 6.77436 7.70769 6.77436H5.94359V3.17436ZM5.94359 8.78462H8.41538C9.93333 8.78462 10.8359 9.52308 10.8359 10.8359C10.8359 12.2821 9.70769 12.8154 7.92308 12.8154H5.94359V8.78462Z" fill="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconBold'
})
</script>

<template>
  <g>
    <path d="M0.833252 3.33334V8.33334H5.83325" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.92492 12.5C3.46525 14.0337 4.48936 15.3502 5.84297 16.2512C7.19657 17.1522 8.80632 17.5888 10.4297 17.4954C12.053 17.402 13.6021 16.7835 14.8434 15.7331C16.0846 14.6828 16.951 13.2575 17.3118 11.672C17.6726 10.0865 17.5083 8.42664 16.8438 6.94259C16.1792 5.45854 15.0504 4.23067 13.6273 3.44398C12.2042 2.65729 10.564 2.35439 8.95382 2.58094C7.34363 2.80748 5.85068 3.55119 4.69992 4.70001L0.833252 8.33334" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconHistory'
})
</script>

<template>
  <g>
    <path d="M11.9836 9.93042C11.8207 9.93042 11.6578 9.86808 11.5337 9.74399C11.2848 9.49522 11.2848 9.09299 11.5337 8.84423L12.8191 7.55879C13.4052 6.97841 13.7272 6.20332 13.7272 5.37417C13.7272 3.66938 12.3399 2.28153 10.6345 2.28153C9.80595 2.28153 9.03086 2.60347 8.45188 3.18769L7.16446 4.475C6.91569 4.72388 6.51347 4.72388 6.2647 4.475C6.01593 4.22623 6.01593 3.824 6.2647 3.57524L7.55014 2.28922C8.36845 1.46449 9.46431 1.00879 10.6345 1.00879C13.0418 1.00879 14.9999 2.96689 14.9999 5.37417C14.9999 6.54566 14.5442 7.64082 13.7163 8.46111L12.4329 9.74399C12.3088 9.86808 12.1465 9.93042 11.9836 9.93042Z" fill="currentFill" stroke="transparent"/>
    <path d="M5.36539 15.0106C2.9581 15.0106 1 13.0525 1 10.6453C1 9.47377 1.45571 8.37861 2.28357 7.55832L3.56703 6.27544C3.81592 6.02668 4.21802 6.02668 4.46691 6.27544C4.71567 6.52421 4.71567 6.92644 4.46691 7.1752L3.18147 8.46064C2.59468 9.04102 2.27274 9.81611 2.27274 10.6453C2.27274 12.35 3.66001 13.7379 5.36539 13.7379C6.19395 13.7379 6.96904 13.416 7.54802 12.8317L8.83544 11.5444C9.08421 11.2955 9.48643 11.2955 9.7352 11.5444C9.98397 11.7932 9.98397 12.1954 9.7352 12.4442L8.44976 13.7303C7.63145 14.5549 6.5357 15.0106 5.36539 15.0106Z" fill="currentFill" stroke="transparent"/>
    <path d="M9.34934 7.29728C9.18645 7.29728 9.02356 7.23494 8.89947 7.11085C8.6507 6.86208 8.6507 6.45986 8.89947 6.21109L10.0958 5.01479C10.3446 4.7659 10.7468 4.7659 10.9956 5.01479C11.2444 5.26356 11.2444 5.66578 10.9956 5.91455L9.79922 7.11085C9.67513 7.23494 9.51224 7.29728 9.34934 7.29728Z" fill="currentFill" stroke="transparent"/>
    <path d="M5.4549 11.1918C5.29201 11.1918 5.129 11.1295 5.00502 11.0054C4.75614 10.7565 4.75614 10.3544 5.00502 10.1055L6.20131 8.90923C6.45008 8.66046 6.8523 8.66046 7.10107 8.90923C7.34995 9.158 7.34995 9.56022 7.10107 9.80899L5.90478 11.0054C5.78068 11.1295 5.61779 11.1918 5.4549 11.1918Z" fill="currentFill" stroke="transparent"/>
    <path d="M14.3634 15.0087C14.2005 15.0087 14.0376 14.9463 13.9135 14.8222L1.18666 2.09521C0.937779 1.84644 0.937779 1.44422 1.18666 1.19545C1.43543 0.946568 1.83754 0.946568 2.08642 1.19545L14.8134 13.9224C15.0622 14.1712 15.0622 14.5733 14.8134 14.8222C14.6893 14.9463 14.5263 15.0087 14.3634 15.0087Z" fill="currentFill" stroke="transparent"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUnlink'
})
</script>

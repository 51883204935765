<template>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 4C8.32843 4 9 3.32843 9 2.5C9 1.67157 8.32843 1 7.5 1C6.67157 1 6 1.67157 6 2.5C6 3.32843 6.67157 4 7.5 4ZM12.5 4C13.3284 4 14 3.32843 14 2.5C14 1.67157 13.3284 1 12.5 1C11.6716 1 11 1.67157 11 2.5C11 3.32843 11.6716 4 12.5 4ZM9 7.5C9 8.32843 8.32843 9 7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5ZM12.5 9C13.3284 9 14 8.32843 14 7.5C14 6.67157 13.3284 6 12.5 6C11.6716 6 11 6.67157 11 7.5C11 8.32843 11.6716 9 12.5 9ZM9 12.5C9 13.3284 8.32843 14 7.5 14C6.67157 14 6 13.3284 6 12.5C6 11.6716 6.67157 11 7.5 11C8.32843 11 9 11.6716 9 12.5ZM12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14ZM9 17.5C9 18.3284 8.32843 19 7.5 19C6.67157 19 6 18.3284 6 17.5C6 16.6716 6.67157 16 7.5 16C8.32843 16 9 16.6716 9 17.5ZM12.5 19C13.3284 19 14 18.3284 14 17.5C14 16.6716 13.3284 16 12.5 16C11.6716 16 11 16.6716 11 17.5C11 18.3284 11.6716 19 12.5 19Z" fill="#AAB1C5"/>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconDrag'
})
</script>

<template>
  <g>
    <path d="M19.5 16.8611C19.5 17.2295 19.342 17.5827 19.0607 17.8432C18.7794 18.1037 18.3978 18.25 18 18.25H6C5.60218 18.25 5.22064 18.1037 4.93934 17.8432C4.65804 17.5827 4.5 17.2295 4.5 16.8611V7.13889C4.5 6.77053 4.65804 6.41726 4.93934 6.1568C5.22064 5.89633 5.60218 5.75 6 5.75H9.75L11.25 7.83333H18C18.3978 7.83333 18.7794 7.97966 19.0607 8.24013C19.342 8.5006 19.5 8.85387 19.5 9.22222V16.8611Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMenuFolder'
})
</script>

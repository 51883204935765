<template>
  <g>
    <path d="M11.95 4H7.6C7.17565 4 6.76869 4.16857 6.46863 4.46863C6.16857 4.76869 6 5.17565 6 5.6V18.4C6 18.8243 6.16857 19.2313 6.46863 19.5314C6.76869 19.8314 7.17565 20 7.6 20H17.2C17.6243 20 18.0313 19.8314 18.3314 19.5314C18.6314 19.2313 18.8 18.8243 18.8 18.4V10.85L11.95 4Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9502 4V10.85H17.5502" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMenuDocument'
})
</script>

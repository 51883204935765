<template>
  <g>
    <path d="M8 3.3335V12.6668" stroke="#AAB1C5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.33301 8H12.6663" stroke="#AAB1C5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconPlus3'
})
</script>

<template>
  <g stroke="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6433 4.5269L1.9695 24.0154C1.72881 24.4322 1.60146 24.9048 1.60011 25.3861C1.59876 25.8674 1.72346 26.3407 1.96181 26.7588C2.20016 27.177 2.54385 27.5254 2.95868 27.7695C3.37351 28.0136 3.84502 28.1448 4.32631 28.1501H27.6739C28.1552 28.1448 28.6267 28.0136 29.0415 27.7695C29.4563 27.5254 29.8 27.177 30.0384 26.7588C30.2767 26.3407 30.4014 25.8674 30.4001 25.3861C30.3987 24.9048 30.2714 24.4322 30.0307 24.0154L18.3569 4.5269C18.1112 4.12184 17.7653 3.78694 17.3524 3.55452C16.9396 3.3221 16.4739 3.2 16.0001 3.2C15.5263 3.2 15.0606 3.3221 14.6478 3.55452C14.2349 3.78694 13.889 4.12184 13.6433 4.5269ZM16 10.8611C16.4142 10.8611 16.75 11.1969 16.75 11.6111V17.1241C16.75 17.5383 16.4142 17.8741 16 17.8741C15.5858 17.8741 15.25 17.5383 15.25 17.1241V11.6111C15.25 11.1969 15.5858 10.8611 16 10.8611ZM16 21.6371C15.4477 21.6371 15 22.0848 15 22.6371C15 23.1894 15.4477 23.6371 16 23.6371H16.0138C16.5661 23.6371 17.0138 23.1894 17.0138 22.6371C17.0138 22.0848 16.5661 21.6371 16.0138 21.6371H16Z"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconWarning'
})
</script>

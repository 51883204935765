<template>
  <path d="M12.6351 3.2487C12.8091 3.07474 13.0156 2.93675 13.2429 2.8426C13.4702 2.74845 13.7138 2.7 13.9598 2.7C14.2058 2.7 14.4494 2.74845 14.6767 2.8426C14.904 2.93675 15.1105 3.07474 15.2845 3.2487C15.4584 3.42266 15.5964 3.62918 15.6906 3.85647C15.7847 4.08376 15.8332 4.32736 15.8332 4.57338C15.8332 4.81939 15.7847 5.063 15.6906 5.29029C15.5964 5.51758 15.4584 5.7241 15.2845 5.89806L6.34288 14.8397L2.70001 15.8332L3.69352 12.1903L12.6351 3.2487Z" stroke="currentStroke" fill="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconEdit2'
})
</script>

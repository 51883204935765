<template>
  <g style="stroke-width: 0">
    <path d="M14.5335 13.1338H1.4668V15.0005H14.5335V13.1338Z" fill="strokeCurrent"/>
    <path d="M4.79541 11.6562L5.92822 8.33594H10.0142L11.147 11.6562H12.397L8.56885 1H7.42822L3.6001 11.6562H4.79541ZM7.99072 2.4375L9.68604 7.375H6.26416L7.95947 2.4375H7.99072Z" fill="strokeCurrent"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconTextColor'
})
</script>

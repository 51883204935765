<template>
  <g>
    <rect x="7" y="4" width="7" height="7" fill="currentFill"/>
    <line x1="2" y1="1.5" x2="14" y2="1.5" stroke="currentStroke"/>
    <line x1="2" y1="13.5" x2="14" y2="13.5" stroke="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconImageRight'
})
</script>

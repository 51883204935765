<template>
  <g>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.464 12.085a.488.488 0 01-.326-.124l-1.528.764a.487.487 0 01-.563-.09l-.343-.343-.612.611a.492.492 0 01-.346.143H1.98a.488.488 0 01-.346-.833l1.994-1.999-.344-.343a.488.488 0 01-.091-.564l.764-1.529a.49.49 0 01.042-.693l6.45-5.644a1.795 1.795 0 012.446.081l1.5 1.5c.665.664.7 1.738.082 2.445l-5.644 6.451a.49.49 0 01-.351.167h-.017zm-4.146-1.18L3.156 12.07h1.389l.468-.468-.695-.696zm1.738.356l-.006-.006-.006-.005-1.821-1.821.46-.922 2.727 2.726-.922.46-.432-.432zm2.384-.378L5.034 7.477l6.058-5.301a.816.816 0 011.111.037l1.5 1.5c.302.302.318.79.037 1.112l-5.3 6.058zM1.5 14.96h12.335a.5.5 0 000-1H1.5a.5.5 0 000 1z"
          fill="strokeCurrent" stroke-width="0"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconHighlight'
})
</script>

<template>
  <div :class="[collapsibleClass, { expanded: isExpanded }]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CollapsibleBlock',
  props: {
    isExpanded: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    collapsibleClass() {
      return 'collapsible';
    }
  }
}
</script>

<style lang="postcss" scoped>
.collapsible {
  overflow: hidden;
  height: 0;
}

.collapsible.expanded {
  height: auto;
}
</style>

<template>
  <g>
    <path
      d="M22.2222 3.20001L27.2 7.85456M27.2 7.85456L22.2222 12.5091M27.2 7.85456L9.77758 7.85457C8.45739 7.85457 7.19128 8.34496 6.25776 9.21785C5.32425 10.0908 4.7998 11.2747 4.7998 12.5091V14.8364M9.77758 28.8L4.7998 24.1455M4.7998 24.1455L9.77758 19.4909M4.7998 24.1455L22.222 24.1455C23.5422 24.1455 24.8083 23.6551 25.7418 22.7822C26.6754 21.9093 27.1998 20.7254 27.1998 19.4909V17.1636"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRandom'
})
</script>

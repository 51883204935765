<template>
  <g>
    <path d="M13 1H3C1.89543 1 1 1.89543 1 3V8V13C1 14.1046 1.89543 15 3 15H13.5C14.3284 15 15 14.3284 15 13.5V4V3C15 1.89543 14.1046 1 13 1Z" fill="#FFE0E0"/>
    <path d="M3.91699 8H12.0837" stroke="#D64141" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>

</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRemoveRed'
})
</script>

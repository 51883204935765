<template>
  <g>
    <path d="M18 18.0001V16.1177C18 15.1193 17.5786 14.1617 16.8284 13.4557C16.0783 12.7497 15.0609 12.353 14 12.353H6C4.93913 12.353 3.92172 12.7497 3.17157 13.4557C2.42143 14.1617 2 15.1193 2 16.1177V18.0001" stroke="#444754" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.75 9.52941C11.8211 9.52941 13.5 7.8439 13.5 5.76471C13.5 3.68552 11.8211 2 9.75 2C7.67893 2 6 3.68552 6 5.76471C6 7.8439 7.67893 9.52941 9.75 9.52941Z" stroke="#444754" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUser2'
})
</script>

<template>
  <g>
    <path d="M13.2133 1.33301H2.78659C1.98393 1.33301 1.33325 1.98369 1.33325 2.78634V13.213C1.33325 14.0157 1.98393 14.6663 2.78659 14.6663H13.2133C14.0159 14.6663 14.6666 14.0157 14.6666 13.213V2.78634C14.6666 1.98369 14.0159 1.33301 13.2133 1.33301Z" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.66675 1.33301V14.6663" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3333 1.33301V14.6663" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.33325 8H14.6666" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.33325 4.66699H4.66659" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.33325 11.333H4.66659" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3333 11.333H14.6666" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3333 4.66699H14.6666" stroke="currentFill" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMedia'
})
</script>


/*
function getStrategyTempById(strategyTemps, strategyId) {
    if (strategyTemps === null || strategyId === null) {
        return null;
    }
    for (let i = 0; i < strategyTemps.length; i++) {
        if (strategyTemps[i].id === strategyId) {
            return strategyTemps[i];
        }
    }
    return null;
}
*/

export function getAggregatedStrategyParams(strategyTemps, strategy) {
    var result = {
        ema_periods: [7, 25, 99],
        ema_show: true,
        bb_params: [7, 2],
        rsi_bound: [30, 70],
        rsi_period: 14,
        macd_periods: [12, 26, 9],
    }

    if (strategyTemps === null || strategy === null) {
        return result;
    }

    if (!(strategy.temp_id in strategyTemps)) {
        return result;
    }

    let strategyTemp = strategyTemps[strategy.temp_id];

    if (strategyTemp.template_id === 'EMA_Cross_Basic' || 
        strategyTemp.template_id === 'EMA_Inflect_Basic') {
        let config = strategyTemp.config;
        result.ema_periods = [config.EMA1, config.EMA2, config.EMA3];
    } else if (strategyTemp.template_id === 'RSI_Bound_Basic') {
        let config = strategyTemp.config;
        result.rsi_bound = [config.rsi_lower_bound, config.rsi_upper_bound];
        result.rsi_period = config.window_size;
    } else if (strategyTemp.template_id === 'BB_Filtered_RSI') {
        let config = strategyTemp.config;
        result.rsi_bound = [config.rsi_lower_bound, config.rsi_upper_bound];
        result.rsi_period = config.window_size;
        result.bb_params = [config.filter_window_size, config.num_of_std];
    } else if (strategyTemp.template_id === 'RSI_Filtered_RSI') {
        let config = strategyTemp.config;
        result.rsi_bound = [config.rsi_lower_bound, config.rsi_upper_bound];
        result.rsi_period = config.window_size;
    }

    return result;
}

export function getAggregatedStrategyParamsBT(strategy) {
    var result = {
        ema_periods: [7, 25, 99],
        ema_show: true,
        bb_params: [7, 2],
        rsi_bound: [30, 70],
        rsi_period: 14,
        macd_periods: [12, 26, 9],
    }

    if (strategy === null) {
        return result;
    }

    let strategyTemp = strategy;

    if (strategyTemp.temp_name === 'EMA_Cross_Basic' || 
        strategyTemp.temp_name === 'EMA_Inflect_Basic') {
        let config = strategyTemp.config;
        result.ema_periods = [config.EMA1, config.EMA2, config.EMA3];
    } else if (strategyTemp.temp_name === 'RSI_Bound_Basic') {
        let config = strategyTemp.config;
        result.rsi_bound = [config.rsi_lower_bound, config.rsi_upper_bound];
        result.rsi_period = config.window_size;
    } else if (strategyTemp.temp_name === 'BB_Filtered_RSI') {
        let config = strategyTemp.config;
        result.rsi_bound = [config.rsi_lower_bound, config.rsi_upper_bound];
        result.rsi_period = config.window_size;
        result.bb_params = [config.filter_window_size, config.num_of_std];
    } else if (strategyTemp.temp_name === 'RSI_Filtered_RSI') {
        let config = strategyTemp.config;
        result.rsi_bound = [config.rsi_lower_bound, config.rsi_upper_bound];
        result.rsi_period = config.window_size;
    }

    return result;
}
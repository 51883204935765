<template>
  <g>
    <path d="M8 14L12 10L8 6" stroke="currentStroke" fill="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconRight2'
})
</script>

<template>
  <g>
    <path d="M15.333 17L20.333 12L15.333 7" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.66699 7L3.66699 12L8.66699 17" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconMenuFolder'
})
</script>


import axios from 'axios';

var AppInfoModule = {
  namespaced: true,
  state: function () {
    return {
      isMobile: false,
      fullScreenMode: false,
      showUpgrade: false,
      strategies: null,
      entryStrategyTemp: null,
      orderStrategyTemp: null,
      openpos: {
        ref_options: ['EMA7', 'EMA25']
      }
    }
  },

  getters: {
  },

  actions: {
    async getEntryStrategyTemp({commit}) {
      let url = '/strategy/entry';
      axios.get(url)
      .then( response => {
        let data = response.data;
        commit('setEntryStrategyTemp', data);
      }) 
      .catch (error => {
        console.log(error);
        throw error;
      })
    },
    async getOrderStrategyTemp({commit}) {
      let url = '/strategy/order';
      axios.get(url)
      .then( response => {
        let data = response.data;
        commit('setOrderStrategyTemp', data);
      }) 
      .catch (error => {
        console.log(error);
        throw error;
      })
    },
    async getStrategies({commit}) {
      let url = '/strategy/all';
      axios.get(url)
      .then( response => {
        let data = response.data;
        commit('setStrategy', data);
      }) 
      .catch (error => {
        console.log(error);
        throw error;
      })
    }
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setFullScreenMode(state, mode) {
      state.fullScreenMode = mode;
    },
    setShowUpgrade(state, showUpgrade) {
      state.showUpgrade = showUpgrade;
    },
    setStrategy(state, strategies) {
      state.strategies = strategies;
    },
    setEntryStrategyTemp(state, strategies) {
      state.entryStrategyTemp = strategies;
    },
    setOrderStrategyTemp(state, strategies) {
      state.orderStrategyTemp = strategies;
    }
  }
};

export default AppInfoModule;
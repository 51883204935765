<template>
  <g>
    <path
      d='M15.9994 2.66602L20.1194 11.0127L29.3326 12.3593L22.666 18.8527L24.2394 28.026L15.9994 23.6926L7.75936 28.026L9.33268 18.8527L2.66602 12.3593L11.8794 11.0127L15.9994 2.66602Z'
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </g>
</template>

<script lang='ts'>
import Vue from 'vue'

export default Vue.extend({
  name: 'IconStar'
})
</script>

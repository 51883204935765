<template>
  <g>
    <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 6H14" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 14V6" stroke="currentStroke" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLayout'
})
</script>

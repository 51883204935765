<template>
    <div class="summary">
        <div class="symbol">
            <symbol-selector @symbolChanged="symbolChanged" />
        </div>
        <ul class="summary-list">
            <li v-if="!isMobileAndFullScreenMode">
                <div class="card-body">
                    <h6 class="card-title">Price</h6>
                    <p class="text-primary">{{ infoSummary.current_price }}</p>
                </div>
            </li>
            <li>
                <div class="card-body">
                    <h6 class="card-title">Mkt Cap</h6>
                    <p class="text-primary">{{ infoSummary.market_cap }}</p>
                </div>
            </li>
            <li>
                <div class="card-body">
                    <h6 class="card-title">Rank</h6>
                    <p class="text-primary">{{ infoSummary.market_cap_rank }}</p>
                </div>
            </li>
            <li>
                <div class="card-body">
                    <h6 class="card-title">Down ATH</h6>
                    <p class="text-primary">{{ infoSummary.ath_change_percentage }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>
  
<script>
import numeral from 'numeral';
import SymbolSelector from './SymbolSelector';


export default {
    name: 'InfoSummary',
    components: {
        SymbolSelector
    },
    data() {
        return {
        };
    },
    computed: {
        infoSummary() {
            let summary = this.$store.state.summary.data;
            let priceFromKlines = this.$store.state.chart.currentPrice;
            if (summary === null) {
                return {
                    current_price: priceFromKlines !== null?  priceFromKlines : 0,
                    market_cap: 0,
                    market_cap_rank: 0,
                    ath_change_percentage: 0
                }
            } else {
                return {
                    current_price: priceFromKlines !== null?  priceFromKlines : summary.current_price,
                    market_cap: numeral(summary.market_cap).format('0.00a'),
                    market_cap_rank: summary.market_cap_rank,
                    ath_change_percentage: numeral(summary.ath_change_percentage/100).format('0.00%')
                }
            }
        },
        isMobileAndFullScreenMode() {
            return this.$store.state.appinfo.isMobile && this.$store.state.appinfo.fullScreenMode
        }
    },
    methods: {
        async symbolChanged(symbol) {
            this.$emit('symbolChanged', symbol);
        }
    },
};
</script>

<style lang='postcss' scoped>
.summary {
    @apply flex flex-row;
    margin-top: 5px;

    .symbol {
        width: auto;
        margin-right: 0px;
    }

    .summary-list {
        @apply flex flex-row;
        text-align: left;
        font-weight: 500;
        display: flex;
        list-style: none;

        li {
            width: auto;
            text-align: left;

            .card-body {
                padding-left: 10px;
                padding-right: 10px;
                margin: 0;
                font-size: 1.2rem;
                font-weight: 500;
                color: #020202;
                text-decoration: none;
                outline: none;
                border-right: 1px solid #e1e0e0;

                .card-title {
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #020202;
                    text-decoration: none;
                    white-space: nowrap;
                    outline: none;
                }

                .text-primary {
                    color: #020202;
                }
            }
        }
    }
}
</style>
  
<template>
  <label class="switch">
    <input type="checkbox" :checked="payload" :disabled="disabled" @change="updatePayload">
    <span class="slider" />
  </label>
</template>

<script>
export default {
  name: 'ButtonSwitch',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    payload: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    updatePayload(event) {
      this.payload = event.target.checked;
    }
  }
};
</script>


<style lang="postcss" scoped>
.switch {
  @apply relative inline-block;

  width: 62px;
  height: 18px;

  & input {
    @apply opacity-0 w-0 h-0;
  }
}

.slider {
  @apply absolute top-0 left-0 right-0 bottom-0;
  @apply cursor-pointer bg-gray-100;

  border-radius: 34px;
  transition: .4s;

  &::before {
    @apply absolute bg-white-default;

    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;

    left: 1px;
    bottom: 1px;

    transition: .4s;
  }
}

input:checked+.slider {
  @apply bg-success-default;
}

input:focus+.slider {
  box-shadow: 0 0 0 1px theme('colors.gray.100');
}

input:checked+.slider:before {
  transform: translateX(22px);
}
</style>

<template>
  <g>
    <path
      d="M19.2 22.4L12.8 16L19.2 9.60001"
      fill="none"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconLeft'
})
</script>


import axios from 'axios';

var UserModule = {
  namespaced: true,
  state: function () {
    return {
      user: null,
      isTokenExpired: false,
      userAccountType: 'basic',
      subscriptionInfo: null,
      telegramInfo: null,
      verificationCode: null,
      registeredEntryStrategies: [],
      registeredOrderStrategies: [],
      registeredOpenposSafeGuards: []
    }
  },

  getters: {
    isAuthenticated: state => !!state.user,
    stateUser: state => state.user,
  },

  actions: {
    async updateUserInfo({commit}, {user}) {
      if (user === null) {
        return;
      }
      await axios.get('/user/userinfo', {
        params: {
          email: user.email,
          uid: user.uid,
          token: user.accessToken,
        }
      })
      .then((response) => {
        let user_info = response.data;
        if (user_info === null) {
          commit('setTelegramInfo', null);
          commit('setUserAccountType', 'basic');
          commit('setSubscriptionInfo', null);
          commit('setVerificationCode', null);
          return;
        }
        if ("st_entry" in user_info) {
          commit('setRegisteredEntryStrategies', user_info.st_entry);
        }
        if ("st_order" in user_info) {
          commit('setRegisteredOrderStrategies', user_info.st_order);
        }
        if ("openpos_safeguard" in user_info) {
          commit('setRegisteredOpenposSafeGuards', user_info.openpos_safeguard);
        }
        if ("verification_code" in user_info) {
          commit('setVerificationCode', user_info.verification_code);
        }
        if ("telegram" in user_info) {
          commit('setTelegramInfo', user_info.telegram);
        } else {
          commit('setTelegramInfo', null);
        }
        if ("subscription" in user_info) {
          commit('setUserAccountType', user_info.subscription.type);
          commit('setSubscriptionInfo', user_info.subscription);
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      console.log('old user', user);
    },
    async logIn({state, commit, dispatch}, {user, isNewUser}) {
      // await axios.post('login', user);
      console.log(user);

      try {
        await dispatch('updateUserInfo', {user: user});
      } catch (error) {
        console.log(error);
      }

      // Send user information to server and create a new user if it is a new user
      if (isNewUser && state.verificationCode === null) { 
        const randomNumber = Math.floor(Math.random() * 900) + 100;
        let verificationCode = user.email.substring(0, 3) + user.uid.substring(0, 3) + randomNumber.toString();
        let user_info = {
          email: user.email,
          uid: user.uid,
          verification_code: verificationCode,
          subscription: {
            type: state.userAccountType,
            status: 'active',
          }
        }
        await axios.post('/user/register', user_info).then((response) => {
          commit('setVerificationCode', user_info.verification_code);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      }

      await commit('setUser', user);

      if (state.subscriptionInfo !== null && state.subscriptionInfo.status === 'active' &&
          state.subscriptionInfo.type === 'basic') {
        await dispatch('updateSubscription'); // To fix an issue on mobile page after upgrading to plus
      }
    },
    async logOut({commit}) {
      let user = null;
      commit('setUser', user);
      commit('setUserAccountType', 'basic');
      commit('setSubscriptionInfo', null);
      commit('setTelegramInfo', null);
      commit('setVerificationCode', null);
    },
    async updateSubscription({state, dispatch}) {
      // Update subscription information to server
      if (state.user === null) {
        return;
      }

      await axios.post('/user/update-subscription', {
        uid: state.user.uid,
        token: state.user.accessToken,
      }).then((response) => {
        console.log(response.data);
      }
      ).catch((error) => {
        console.log(error);
        throw error;
      });

      await dispatch('updateUserInfo', {user: state.user});
    },
    async cancelSubscription({state, dispatch}) {
      // Update subscription information to server
      if (state.user === null) {
        return;
      }

      await axios.post('/user/cancel-subscription', {
        uid: state.user.uid,
        token: state.user.accessToken,
      }).then((response) => {
        console.log(response.data);
      }
      ).catch((error) => {
        console.log(error);
        throw error;
      });

      await dispatch('updateUserInfo', {user: state.user});
    },
    async updateRegisteredStrategiesInDB({state}, {strategies, update_type}) {
      // Update entry strategy information to server
      let user_info = {
        uid: state.user.uid,
        token: state.user.accessToken,
        strategy_list: strategies,
        type: update_type,
      }
      await axios.post('/user/updatestrategy', user_info).then((response) => {
        console.log(response);
        return response;
      }
      ).catch((error) => {
        console.log(error);
        throw error;
      });
    },
    async registerEntryStrategy({commit, dispatch, state}, strategy) {
      commit('addRegisteredEntryStrategy', strategy);
      try {
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredEntryStrategies, 
          update_type: 'st_entry'});
      } catch (error) {
        console.log(error);
        commit('deleteRegisteredEntryStrategy', strategy); 
        throw error;
      }    
    },
    async deleteEntryStrategy({commit, dispatch, state}, strategy) {
      commit('deleteRegisteredEntryStrategy', strategy); 
      try {
        // Update entry strategy information to server
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredEntryStrategies, 
          update_type: 'st_entry'});
      } catch (error) {
        console.log(error);
        throw error;
      }
       
    },
    async registerOrderStrategy({commit, dispatch, state}, strategy) {
      commit('addRegisteredOrderStrategy', strategy);
      try {
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredOrderStrategies, 
          update_type: 'st_order'});
      } catch (error) {
        console.log(error);
        commit('deleteRegisteredOrderStrategy', strategy);
        throw error;
      }

    },
    async deleteOrderStrategy({commit, dispatch, state}, strategy) {
      commit('deleteRegisteredOrderStrategy', strategy);
      strategy.status = 'Terminated';
      await axios.post('/user/orderstrategy/close', {
        uid: state.user.uid,
        token: state.user.accessToken,
        order_strategy: strategy
      }).then((response) => {
        console.log(response);
      }
      ).catch((error) => {
        console.log(error);
        throw error;
      });

      // Update entry strategy information to server
      try {
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredOrderStrategies, 
          update_type: 'st_order'});
      } catch (error) {
        console.log(error);
        throw error;
      }

    },
    async registerOpenposSafeGuard({commit, dispatch, state}, openPosSafeGuard) {
      commit('addRegisteredOpenposSafeGuard', openPosSafeGuard);
      try {
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredOpenposSafeGuards, 
          update_type: 'openpos_safeguard'});
      } catch (error) {
        console.log(error);
        commit('deleteRegisteredOpenposSafeGuard', openPosSafeGuard);
        throw error;
      }
      
    },
    async updateOpenposSafeGuard({commit, dispatch, state}, openPosSafeGuard) {
      commit('updateRegisteredOpenposSafeGuard', openPosSafeGuard);
      try {
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredOpenposSafeGuards, 
          update_type: 'openpos_safeguard'});
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async deleteOpenposSafeGuard({commit, dispatch, state}, openPosSafeGuard) {
      commit('deleteRegisteredOpenposSafeGuard', openPosSafeGuard);
      await axios.post('/user/openpos/close', {
        uid: state.user.uid,
        token: state.user.accessToken,
        openpos_safeguard: openPosSafeGuard
      }).then((response) => {
        console.log(response);
      }
      ).catch((error) => {
        console.log(error);
        throw error;
      });

      try {
        // Update entry strategy information to server
        await dispatch('updateRegisteredStrategiesInDB', {strategies: state.registeredOpenposSafeGuards, 
          update_type: 'openpos_safeguard'});
      } catch (error) {
        console.log(error);
        throw error;
      }      
    },
    async sendTestMsg({state}, msg) {
      await axios.post('/user/telegram', {
        uid: state.user.uid,
        token: state.user.accessToken,
        message: msg
      }).then((response) => {
        console.log(response);
      }
      ).catch((error) => {
        console.log(error);
      });
    },
    async updateConnectedToTelegram({dispatch, state}) {
      if (state.user == null) {
        return;
      }
      if (state.telegramInfo !== null) {
        return;
      }
      
      await dispatch('updateUserInfo', {user: state.user});
    },
    async updateReceiveTelegram({commit, state}, receiveStatus) {
      if (state.user == null) {
        return;
      }
      await axios.post('/user/telegram-receive', {
        uid: state.user.uid,
        token: state.user.accessToken,
        receive_telegram: receiveStatus
      }).then((response) => {
        console.log(response);
      }
      ).catch((error) => {
        console.log(error);
      });
      
      commit('updateTelegramReceiveStatus', receiveStatus);
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setIsTokenExpired(state, isTokenExpired) {
      state.isTokenExpired = isTokenExpired;
    },
    setVerificationCode(state, code) {
      state.verificationCode = code;
    },
    setTelegramInfo(state, telegramInfo) {
      state.telegramInfo = telegramInfo;
    },
    setUserAccountType(state, userAccountType) {
      state.userAccountType = userAccountType;
    },
    setSubscriptionInfo(state, subscriptionInfo) {
      state.subscriptionInfo = subscriptionInfo;
    },
    updateTelegramReceiveStatus(state, receiveStatus) {
      if (state.telegramInfo == null) {
        return;
      }
      state.telegramInfo.receive_status = receiveStatus;
    },
    setRegisteredEntryStrategies(state, strategies) {
      strategies.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      state.registeredEntryStrategies = strategies;
    },
    addRegisteredEntryStrategy(state, strategy) {
      if (state.registeredEntryStrategies === null) {
        state.registeredEntryStrategies = [];
      }
      state.registeredEntryStrategies.push(strategy);
      state.registeredEntryStrategies.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    deleteRegisteredEntryStrategy(state, strategy) {
      for (let i = 0; i < state.registeredEntryStrategies.length; i++) {
        if (state.registeredEntryStrategies[i].id === strategy.id) {
          state.registeredEntryStrategies.splice(i, 1);
          break;  
        }
      }
    },
    setRegisteredOrderStrategies(state, strategies) {
      strategies.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      state.registeredOrderStrategies = strategies;
    },
    addRegisteredOrderStrategy(state, strategy) {
      if (state.registeredOrderStrategies === null) {
        state.registeredOrderStrategies = [];
      }
      state.registeredOrderStrategies.push(strategy);
      state.registeredOrderStrategies.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    deleteRegisteredOrderStrategy(state, strategy) {
      for (let i = 0; i < state.registeredOrderStrategies.length; i++) {
        if (state.registeredOrderStrategies[i].id === strategy.id) {
          state.registeredOrderStrategies.splice(i, 1);
          break;  
        }
      }
    },
    setRegisteredOpenposSafeGuards(state, openposSafeGuards) {
      openposSafeGuards.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      state.registeredOpenposSafeGuards = openposSafeGuards;
    },
    addRegisteredOpenposSafeGuard(state, openposSafeGuard) {
      if (state.registeredOpenposSafeGuards === null) {
        state.registeredOpenposSafeGuards = [];
      }
      state.registeredOpenposSafeGuards.push(openposSafeGuard);
      state.registeredOpenposSafeGuards.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
      
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    updateRegisteredOpenposSafeGuard(state, openposSafeGuard) {
      for (let i = 0; i < state.registeredOpenposSafeGuards.length; i++) {
        if (state.registeredOpenposSafeGuards[i].id === openposSafeGuard.id) {
          state.registeredOpenposSafeGuards[i].openpos.factor = openposSafeGuard.openpos.factor;
          state.registeredOpenposSafeGuards[i].openpos.reference = openposSafeGuard.openpos.reference;
          state.registeredOpenposSafeGuards[i].openpos.tolerance = openposSafeGuard.openpos.tolerance;
          break;  
        }
      }
    },
    deleteRegisteredOpenposSafeGuard(state, openposSafeGuard) {
      for (let i = 0; i < state.registeredOpenposSafeGuards.length; i++) {
        if (state.registeredOpenposSafeGuards[i].id === openposSafeGuard.id) {
          state.registeredOpenposSafeGuards.splice(i, 1);
          break;  
        }
      }
    }
  }
};

export default UserModule;
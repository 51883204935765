<template>
  <g style="stroke-width: 0">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 2H2.5C2.224 2 2 2.224 2 2.5C2 2.776 2.224 3 2.5 3H13.5C13.776 3 14 2.776 14 2.5C14 2.224 13.776 2 13.5 2ZM13.5 13H2.5C2.224 13 2 13.224 2 13.5C2 13.776 2.224 14 2.5 14H13.5C13.776 14 14 13.776 14 13.5C14 13.224 13.776 13 13.5 13ZM12 5.25C12 5.526 11.776 5.75 11.5 5.75H4.5C4.224 5.75 4 5.526 4 5.25C4 4.974 4.224 4.75 4.5 4.75H11.5C11.776 4.75 12 4.974 12 5.25ZM13.5 7.5H2.5C2.224 7.5 2 7.724 2 8C2 8.276 2.224 8.5 2.5 8.5H13.5C13.776 8.5 14 8.276 14 8C14 7.724 13.776 7.5 13.5 7.5ZM12 10.75C12 11.026 11.776 11.25 11.5 11.25H4.5C4.224 11.25 4 11.026 4 10.75C4 10.474 4.224 10.25 4.5 10.25H11.5C11.776 10.25 12 10.474 12 10.75Z" fill="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconAlignCenter'
})
</script>

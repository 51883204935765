<template>
  <div class="user-account" v-click-outside="closeMenu">
    <button class="user-account-avatar" @click="toggleMenu">
      <avatar :size="32" :username=user.email></avatar>
    </button>

    <transition name="menu">
      <div v-if="menuVisible" class="user-account-options">

        <button class="user-account-create" @click.prevent.stop="openUserAccount">
          <legacy-icon name="user" class="icon" size="1.5em"></legacy-icon>
          <span>Profile</span>
        </button>

        <div class="divider mx-4"></div>

        <button v-if="isProUser" class="user-account-create" @click.prevent.stop="openBacktest">
          <legacy-icon name="table" size="16px" viewbox="16" class="icon"></legacy-icon>
          <span>Backtest</span>
        </button>

        <div class="divider mx-4"></div>

        <button class="user-account-logout" @click="signout">
          <legacy-icon name="logout" size="16px" viewbox="16" class="icon"></legacy-icon>
          <span>Logout</span>
        </button>
      </div>
    </transition>

  </div>
</template>
  
<script>
import Avatar from 'vue-avatar'
import vClickOutside from 'v-click-outside'

import { getAuth, signOut } from "firebase/auth";
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'user-account',
  components: {
    Avatar,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
  },
  data() {
    return {
      menuVisible: false,
      modalVisible: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapActions('user', ['logOut']),
    user() {
      return this.$store.state.user.user || {};
    },
    isProUser() {
      return this.$store.state.user.subscriptionInfo.type === 'pro';
    },
  },
  watch: {
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    closeMenu(event) {
      console.log('closeMenu', event);
      this.menuVisible = false;
    },
    openUserAccount() {
      this.$router.push('/settings')
      this.menuVisible = false;
    },
    openBacktest() {
      this.$router.push('/backtest')
      this.menuVisible = false;
    },
    async signout() {
      this.logout();
      this.$store.dispatch('auth/signout');
      this.menuVisible = false;
    },
    async logout() {
      //await this.$store.dispatch('logout');
      try {
        const auth = getAuth()
        await signOut(auth)
        await this.$store.dispatch('user/logOut');
      } catch (error) {
        // An error happened.
        console.log(error);
        alert(error.message);
      }

      this.$router.push('/signin')
    }
  }
};
</script>
  
<style lang="postcss" scoped>
.user-account {
  @apply flex flex-1;
  @apply relative;

  .user-account-avatar {
    @apply flex flex-row items-center;
    background: transparent;
    box-shadow: none;
    @apply truncate;

  }

  .user-account-options {
    @apply absolute z-10;
    @apply py-2;
    @apply bg-white-default;

    @apply rounded;
    top: 100%;
    right: 10%;
    width: 150px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);

    .user-account-options-lists {
      @apply px-2 pb-2;
      overflow-y: auto;
      max-height: 70vh;
    }
  }

  .user-account-option {
    @apply flex flex-row justify-between items-center;

    @apply pb-3 pr-2 pl-4 pt-2;

    @apply cursor-pointer;

    .user-account-option-icon {
      svg {
        display: none;
      }
    }

    &.is-active {
      .user-account-option-icon {
        background-color: #444754;
        border-color: #444754;

        svg {
          display: inherit;
        }
      }
    }

    &:hover {
      background: #F6F6F6;
      border-radius: 3px;
    }
  }

  .user-account-option-content {
    @apply flex flex-row flex-1 items-center;

    @apply truncate;
  }

  .user-account-option-logo {
    @apply flex flex-none;
  }

  .user-account-option-label {
    @apply flex flex-col;

    @apply ml-2;

    @apply truncate;

    font-size: 16px;
    line-height: 19px;

    .members-count {
      @apply mt-1;

      font-size: 12px;
      line-height: 14px;
      color: #444754;
    }
  }

  .user-account-option-icon {
    @apply flex justify-center items-center;

    @apply border rounded-full border-gray-100 bg-white-default;
    width: 20px;
    height: 20px;
  }

  .user-account-create {
    @apply flex flex-row items-center;

    @apply w-full px-4 py-2 my-2;

    @apply outline-none;

    color: #2C2B35;

    &:hover {
      @apply text-primary-default;

      background: inherit;

      .icon {
        @apply text-primary-default;
      }
    }

    .icon {
      @apply text-gray-400 mr-2;
    }
  }

  .user-account-logout {
    @apply flex flex-row items-center;

    @apply w-full px-4 py-2 my-2;

    @apply outline-none;

    color: #2C2B35;

    &:hover {
      @apply text-primary-default;

      background: inherit;

      .icon {
        @apply text-primary-default;
      }
    }

    .icon {
      @apply text-gray-400 mr-2;
    }
  }

  .user-account-email {
    @apply inline-block truncate;

    max-width: calc(100% - 60px);
  }

  .space-logo {
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 24px;
  }

  .divider {
    @apply border-b border-gray-100;
  }

  button {
    @apply flex items-center;

    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background: #F8F9FD;
    }

    .SelectSpace-header {
      @apply flex items-center;

      padding-left: 8px;
      transition: 300ms;

      .title {
        margin-left: 8px;
      }
    }

  }
}
</style>
  
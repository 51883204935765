<template>
  <g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.24048 1H5.48098V8.51893C5.48098 10.3923 6.6624 11.6075 8.5358 11.6075C10.4092 11.6075 11.5906 10.3923 11.5906 8.51893V1H12.8311V8.67926C12.8311 11.0674 11.2362 12.7552 8.5358 12.7552C5.84384 12.7552 4.24048 11.0674 4.24048 8.67926V1ZM1.54014 15H14.8636C15.1617 15 15.4037 14.758 15.4037 14.4599C15.4037 14.1617 15.1617 13.9197 14.8636 13.9197H1.54014C1.24198 13.9197 1 14.1617 1 14.4599C1 14.758 1.24198 15 1.54014 15Z" fill="currentStroke"/>
  </g>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'IconUnderline'
})
</script>
